import { useMediaQuery } from "@chakra-ui/media-query";
import { Box, Button, Input, Text, Textarea } from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import useRequest from "../../../hooks/useRequest";
import { useAppSelector } from "../../../redux/hooks";
import {
  selectBlockchain,
  selectCurrentAccount,
} from "../../../redux/slices/auth.slice";

const Reviews = () => {
  const currentAccount = useAppSelector(selectCurrentAccount);
  const blockchain = useAppSelector(selectBlockchain);
  const [review, setReview] = useState("");
  const { loading, makeRequest } = useRequest();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await makeRequest("review", {
      review,
      address: currentAccount,
      blockchain,
    });
    setReview("");
  };

  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");

  return (
    <Box>
      <Box>
        <Text
          color="#0c0c0c"
          textAlign="left"
          fontSize={isNotSmallerScreen ? "7xl" : "3xl"}
          fontWeight="700"
          marginTop="50px"
          marginBottom="50px"
        >
          What do you think about us?
        </Text>
      </Box>

      <Box>
        <Box mb={6}>
          <Text mb={6} fontSize="18px" fontWeight="600" color="#0c0c0c">
            Drop a review
          </Text>
          <hr />
        </Box>
        <form onSubmit={handleSubmit}>
          <Input
            type="text"
            variant="flushed"
            placeholder="Eth Address"
            name="address"
            value={currentAccount}
            required
            disabled
          />

          <Textarea
            placeholder="Enter your review"
            variant="flushed"
            name="review"
            value={review}
            onChange={(e) => setReview(e.target.value)}
            mt={6}
            rows={5}
            required
          />

          <Button
            bg="linear-gradient(#479DD4, #479DD4)"
            w="100%"
            type="submit"
            borderRadius="10px"
            fontWeight="500"
            mt="20px"
            rightIcon={<AiOutlineArrowRight size={20} />}
            transition="0.5s"
            padding="10px"
            _hover={{
              background: "linear-gradient(to right, #479DD4 70%,#FC53D7)",
            }}
          >
            {loading ? "loading" : "Submit"}
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default Reviews;
