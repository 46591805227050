import { useMediaQuery } from "@chakra-ui/media-query";
import { Box, Container, Grid, Image, Stack, Text } from "@chakra-ui/react";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
import { Link } from "react-router-dom";
import logo from "../../design-system/Assets/logo.jpeg";

const Footer = () => {
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");

  return (
    <Box h={"auto"} bg="#0c0c0c" paddingBottom="20px">
      <Container maxW="container.xl">
        <Box paddingTop={isNotSmallerScreen ? "100px" : "20px"}>
          <Grid
            templateColumns={
              isNotSmallerScreen ? "repeat(4,1fr)" : "repeat(1,1fr)"
            }
            gap={6}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent={"flex-start"}
            >
              <Image
                src={logo}
                alt={logo}
                h={isNotSmallerScreen ? "200px" : "150px"}
                w={isNotSmallerScreen ? "200px" : "150px"}
                objectFit="center"
              />
              <Text size="lg" color={"white"}>
                ABSOLUTELY GUARANTEED
              </Text>
              <Text size="md" color={"white"}>
                Not satisfied? We’ll make it right.
              </Text>

              <Stack direction="row" mt={4} justifyContent="space-around">
                <a
                  href="https://twitter.com/NFTPrintPro"
                  target={"_blank"}
                  rel="noreferrer noopener"
                >
                  <BsTwitter color="#A0A3BD" size={25} />
                </a>
                <a
                  href="https://www.instagram.com/nftprintpro/"
                  target={"_blank"}
                  rel="noreferrer noopener"
                >
                  <BsFacebook color="#A0A3BD" size={25} />
                </a>{" "}
                <a
                  href="https://www.instagram.com/nftprintpro/"
                  target={"_blank"}
                  rel="noreferrer noopener"
                >
                  <BsInstagram color="#A0A3BD" size={25} />
                </a>
              </Stack>
            </Box>

            <Box>
              <Text fontSize="2xl" color="#fff" fontWeight="600">
                Contact
              </Text>

              <a
                href="mailto:contact@nftprintpro.com"
                style={{ textDecoration: "none" }}
              >
                <Text fontSize="md" color="#555555" fontWeight="500" mt={4}>
                  support@nftprintpro.com
                </Text>
              </a>

              <Text fontSize="md" color="#555555" fontWeight="500" mt={4}>
                Do you have a problem with your delivery? Contact us:
                founder@nftprintpro.com
              </Text>

              <Text fontSize="md" color="#555555" fontWeight="600" mt={4}>
                We've partnered with various printing shops to bring you the
                best prints the Web3 space can provide.
              </Text>
            </Box>

            <Box>
              <Text fontSize="2xl" color="#fff" fontWeight="600">
                Information
              </Text>
              <a
                target={"_blank"}
                rel="noreferrer nooponer"
                href="https://www.canva.com/design/DAFbQPya0kI/7Hi0jlGcmOKOQWjpbZCJyQ/view"
                style={{ textDecoration: "none" }}
              >
                <Text fontSize="md" color="#555555" fontWeight="500" mt={4}>
                  Product Descriptions
                </Text>
              </a>
              <Link to="/terms-of-service" style={{ textDecoration: "none" }}>
                <Text fontSize="md" color="#555555" fontWeight="500" mt={4}>
                  Terms of Service
                </Text>
              </Link>

              <Link to="/privacy-policy" style={{ textDecoration: "none" }}>
                <Text fontSize="md" color="#555555" fontWeight="500" mt={4}>
                  Privacy Policy
                </Text>
              </Link>

              <Link to="/refund-policy" style={{ textDecoration: "none" }}>
                <Text fontSize="md" color="#555555" fontWeight="500" mt={4}>
                  Refund Policy
                </Text>
              </Link>

              <a
                target={"_blank"}
                rel="noreferrer noopener"
                href=" https://oxfeokiovld.typeform.com/to/JZlHgygU"
                style={{ textDecoration: "none" }}
              >
                <Text fontSize="md" color="#555555" fontWeight="500" mt={4}>
                  For Suppliers
                </Text>
              </a>
            </Box>

            <Box>
              <Text fontSize="2xl" color="#fff" fontWeight="600">
                Menu
              </Text>

              <Link to="/about" style={{ textDecoration: "none" }}>
                <Text fontSize="md" color="#555555" fontWeight="500" mt={4}>
                  About
                </Text>
              </Link>

              <Link to="/learn" style={{ textDecoration: "none" }}>
                <Text fontSize="md" color="#555555" fontWeight="500" mt={4}>
                  Learn
                </Text>
              </Link>

              <Link to="/contact" style={{ textDecoration: "none" }}>
                <Text fontSize="md" color="#555555" fontWeight="500" mt={4}>
                  Contact
                </Text>
              </Link>

              <Link to="/how-to-print-nfts" style={{ textDecoration: "none" }}>
                <Text fontSize="md" color="#555555" fontWeight="500" mt={4}>
                  How To Print NFTs
                </Text>
              </Link>
            </Box>
          </Grid>
        </Box>

        <Box mt={9}>
          <hr />
          <Text fontSize="md" textAlign="center" color="#fff" mt={5}>
            © {new Date().getFullYear()} NFT Print Pro.
          </Text>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
