import { Box, Button, Grid, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BsFillBrushFill } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import EmptyState from "../EmptyState";
import { Art } from "./style";

const MyArts = ({ nfts = [], isNotSmallerScreen }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [nftsToDisplay, setNftsToDisplay] = useState(nfts);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const artsPerPage = 6;
  const pageVisited = pageNumber * artsPerPage;
  const strToStamp = (str) => Date.parse(str) / 1000;

  const handleInputChange = ({ target: { value } }) => {
    setSearch(value);
    const validNFTs = [];
    nfts.forEach((nft) => {
      if (nft.name.toLowerCase().includes(value)) validNFTs.push(nft);
    });
    setNftsToDisplay(validNFTs);
  };

  const displayNfts = nftsToDisplay
    ?.sort((a, b) => strToStamp(b.updated_at) - strToStamp(a.updated_at))
    ?.slice(pageVisited, pageVisited + artsPerPage)
    ?.map((data, index) => {
      return (
        <Box
          maxW="sm"
          bg="#F5F5F5"
          key={index}
          boxShadow="lg"
          borderRadius="5px"
          overflow="hidden"
        >
          {data.internal ? (
            <video autoPlay loop src={data?.meta?.tokenUri?.gateway}>
              Internal
            </video>
          ) : (
            <Box
              style={{ aspectRatio: "1/1" }}
              background={`url(${data?.meta?.tokenUri?.gateway})`}
              backgroundPosition="center"
              backgroundSize="cover"
            ></Box>
          )}

          <Box p={3} textAlign="center">
            <Text
              fontSize={isNotSmallerScreen ? "sm" : "12px"}
              fontWeight="700"
              lineHeight="tight"
              textTransform="capitalize"
              color="#0c0c0c"
            >
              {data?.name}
            </Text>
            {data?.author && (
              <Text
                fontSize={isNotSmallerScreen ? "12px" : "8px"}
                m={isNotSmallerScreen ? "10px 0px" : "5px 0px"}
              >
                By{" "}
                <Text as="b" textTransform="uppercase">
                  {data?.author}
                </Text>
              </Text>
            )}
            <Button
              variant="outline"
              colorScheme="linkedin"
              w={isNotSmallerScreen ? "50%" : "100%"}
              fontSize="12px"
              _hover={{
                transform: "scale(0.95)",
                cursor: "pointer",
                transition: "0.5s ease-in-out",
              }}
              disabled={data?.name === "NFTPRINTPRO NPC"}
              style={{ transition: "all 0.5s ease-in-out" }}
              onClick={() => {
                navigate("/print/", {
                  state: {
                    data,
                  },
                });
              }}
            >
              Print
            </Button>
          </Box>
        </Box>
      );
    });

  const pageCount = Math.ceil(nfts.length / artsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    window.scrollTo(0, 0);
  };
  return (
    // <Box w="100%" minW="60vw" minH="60vh" padding="5px">
    <Art>
      <header>
        <div className="input">
          <AiOutlineSearch color="#6E7191" size={20} />
          <Input
            placeholder="Search"
            variant="filled"
            padding="25px 10px"
            outline="none"
            background="white"
            onChange={handleInputChange}
          />
        </div>
        <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
          <Link to="/mint" style={{ textDecoration: "none" }}>
            <Text
              borderRadius={20}
              padding="10px"
              display={"flex"}
              gap={3}
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              Mint
              <BsFillBrushFill />
            </Text>
          </Link>
        </Box>
      </header>
      <Box paddingBottom="50px" flexGrow={1} mt={5}>
        {nftsToDisplay.length ? (
          <Box>
            <Grid
              templateColumns={isNotSmallerScreen ? "repeat(3,1fr)" : "1fr"}
              gap={6}
              mb={10}
            >
              {displayNfts}
            </Grid>
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={
                isNotSmallerScreen ? "paginationButtons" : "paginationButtons-m"
              }
              previousLinkClassName={"previousButton"}
              nextLinkClassName={"nextButton"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />{" "}
          </Box>
        ) : (
          <EmptyState text={`No art found for ${search}`} />
        )}
      </Box>
    </Art>
    // </Box>
  );
};

export default MyArts;
