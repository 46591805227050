import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { device } from "../utils/media";

const Title = styled.h1`
    text-align:${(props) => (props.align ? props.align : "left")};
    color: ${(props) => (props.color ? props.color : "#000")};
    font-weight: ${(props) => (props.weight ? props.weight : "400")}
    margin: 0;
    font-size: ${(props) => (props.font ? props.font : "40px")};

    @media ${device.lg} {
      font-size: 50px
    }
`;

const Body = styled.h4`
  text-align: ${(props) => (props.align ? props.align : "left")};
  color: ${(props) => (props.color ? props.color : "#000")};
  font-weight: ${(props) => (props.weight ? props.weight : "400")};
  margin: 0;
  font-size: ${(props) => (props.font ? props.font : "18px")};

  @media ${device.lg} {
    font-size: 20px;
  }
`;

const Para = styled.p`
  text-align: ${(props) => (props.align ? props.align : "left")};
  color: ${(props) => (props.color ? props.color : "#000")};
  font-weight: ${(props) => (props.weight ? props.weight : "300")};
  margin: 0;
  font-size: ${(props) => (props.font ? props.font : "12px")};
  line-height: 30px;

  @media ${device.lg} {
    font-size: 14px;
  }
`;

export const Text = ({ children, size, onClick, ...props }) => {
  // eslint-disable-next-line default-case
  switch (size) {
    case "lg":
      return (
        <Title {...props} onClick={onClick}>
          {children}
        </Title>
      );
    case "md":
      return (
        <Body {...props} onClick={onClick}>
          {children}{" "}
        </Body>
      );
    case "sm":
      return (
        <Para {...props} onClick={onClick}>
          {children}
        </Para>
      );
  }
};

Text.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Text.defaultProps = {
  size: "sm",
};

export default Text;
