import {
  Box,
  Button,
  Fade,
  Flex,
  Grid,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import useNumber from "../../hooks/useNumber";
import coin from "./assets/coins.png";

const PurchaseCreditModal = ({ data, custom, setCustom, buyCredits }) => {
  const { modals, setModals } = data;
  const { credits, cost, discount } = custom;
  const { formatNormalNumbers } = useNumber();
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");

  return (
    <Fade in={modals.purchase}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100vh"
        zIndex={10}
        gap={5}
        bg="rgba(0,0,0,0.2)"
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={isNotSmallerScreen ? "45%" : "80%"}
          bg="#fff"
          p="10px"
          borderRadius="3px"
          _hover={{
            border: "1px solid #ddd",
          }}
        >
          <Flex width="100%" justifyContent="flex-end">
            <Flex
              bg="#424242"
              color="#fff"
              width="25px"
              height="25px"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              cursor="pointer"
              transition="0.3s"
              _hover={{
                transform: "scale(0.99)",
              }}
              onClick={() => {
                setModals({ purchase: false });
              }}
            >
              <AiOutlineClose size={15} />
            </Flex>
          </Flex>
          <Flex
            width="80%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize="24px" fontWeight="500">
              Purchase Credits
            </Text>
            <Text fontSize="16px">Confirm your Purchase details</Text>
            <Grid
              gridTemplateColumns="1fr 1fr"
              placeItems="center"
              m="10px 0px"
            >
              <Image p="10px" src={coin} border="1px solid #CACACA" />
              <Box p="10px" fontWeight="700" fontSize="20px" textAlign="center">
                <Text>
                  {credits && credits}
                  <Text color="#5C5C5C" fontWeight="400">
                    Credits
                  </Text>
                </Text>
                {discount ? (
                  <Text>
                    {discount}%{" "}
                    <Text color="#5C5C5C" fontWeight="400">
                      Discount
                    </Text>
                  </Text>
                ) : null}
                <Text>${cost ? formatNormalNumbers(cost) : 0}</Text>
              </Box>
            </Grid>

            <Button
              colorScheme="linkedin"
              m="10px 0px"
              p="10px 20px"
              onClick={() => buyCredits(credits, cost)}
            >
              Purchase Credits
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Fade>
  );
};

export default PurchaseCreditModal;
