import { useMediaQuery } from "@chakra-ui/media-query";
import { Box, Container, Flex, Text, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Layout from "../../design-system/Layout/Layout";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getCreditBalanceAsync,
  selectCreditBalance,
  selectCurrentAccount,
  selectUser,
} from "../../redux/slices/auth.slice";
import Mixed from "./components/Mixed";

const Mint = () => {
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 580px)");
  const currentAccount = useAppSelector(selectCurrentAccount);
  const creditBalance = useAppSelector(selectCreditBalance);
  const user = useAppSelector(selectUser);
  const chakraToast = useToast();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCreditBalanceAsync(user._id));
  }, [dispatch, user._id]);

  return (
    <Layout>
      <Container maxW="container.xl" minH="100vh" h={"auto"}>
        <Flex flexDirection="column" alignItems="center" pt="30px">
          <Text
            fontSize="32px"
            lineHeight="48px"
            fontWeight="400"
            p="5px"
            borderBottom="2px solid #C2C2C2"
            mb="15px"
          >
            Mint
          </Text>

          <Box width={isNotSmallerScreen ? "100%" : "90%"}>
            <Mixed
              isNotSmallerScreen={isNotSmallerScreen}
              currentAccount={currentAccount}
              user={user}
              chakraToast={chakraToast}
              creditBalance={creditBalance}
            />
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};

export default Mint;
