import { useMediaQuery } from "@chakra-ui/media-query";
import { Container, Flex, Grid, Text, useToast } from "@chakra-ui/react";
import swal from "@sweetalert/with-react";
import React, { useEffect, useState } from "react";
import API from "../../api.js";
import Layout from "../../design-system/Layout/Layout";
import useForm from "../../hooks/useForm";
import { useAppDispatch, useAppSelector } from "../../redux/hooks.js";
import {
  getCreditBalanceAsync,
  selectCreditBalance,
  selectCurrentAccount,
  selectToken,
  selectUser,
} from "../../redux/slices/auth.slice.js";
import { setWithExpiry } from "../../utils/store";
import CreditItems from "./CreditItems";
import GiftCreditModal from "./GiftCreditModal";
import Overview from "./Overview";
import PurchaseCreditModal from "./PurchaseCreditModal";
import RedeemCreditModal from "./RedeemCreditModal";

const creditOptions = [
  {
    credits: 50,
    price: 250,
    buyCount: 15,
    discount: 0,
    iconUrls: {
      1: "./assets/user1.png",
      2: "./assets/user2.png",
      3: "./assets/user3.png",
    },
  },
  {
    credits: 250,
    price: 1187.5,
    discount: 5,
    buyCount: 1,
    iconUrls: {
      1: "./assets/user1.png",
    },
  },
  {
    credits: 1000,
    price: 4500,
    discount: 10,
    buyCount: 15,
    iconUrls: {
      1: "./assets/user1.png",
      2: "./assets/user2.png",
      3: "./assets/user3.png",
    },
  },
  {
    custom: true,
  },
];
const Credits = () => {
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 720px)");
  const currentAccount = useAppSelector(selectCurrentAccount);
  const user = useAppSelector(selectUser);
  const chakraToast = useToast();
  const { isValidEmail } = useForm();
  const dispatch = useAppDispatch();
  const balance = useAppSelector(selectCreditBalance);
  const token = useAppSelector(selectToken)
  const [modals, setModals] = useState({
    gift: false,
    redeem: false,
    purchase: false,
    loadingRedeem: false,
    loadingGift: false,
  });
  const [useAddress, setUseAddress] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch(getCreditBalanceAsync(user._id));
    }
  }, [user, dispatch]);

  const warn = (text) =>
    chakraToast({
      position: "top",
      description: text,
      status: "warning",
      duration: 2000,
      isClosable: true,
    });

  const connectWalletNotice = () =>
    !chakraToast.isActive("C_W_N") &&
    chakraToast({
      id: "C_W_N",
      position: "top",
      description: "Connect your Wallet",
      status: "error",
      duration: 2000,
      isClosable: true,
    });

  const [custom, setCustom] = useState({
    cost: "",
    credits: "",
    discount: "",
  });

  const redeemCredits = async (redeem_code) => {
    if (!currentAccount) return connectWalletNotice();
    setModals({ ...modals, loadingRedeem: true });
    try {
      await API.post(
        "/credit/redeem",
        {
          redeem_code,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setModals({ ...modals, loadingRedeem: false, redeem: false });
      dispatch(getCreditBalanceAsync());
      chakraToast({
        position: "top",
        description: `Redeemed Credits`,
        status: "success",
        duration: "3000",
      });
    } catch (err) {
      if (
        err.message === "Request failed with status code 409" ||
        err.message === "Request failed with status code 404"
      )
        swal("An error occured", "Invalid Redeem code", "error");
      else {
        swal("An error occured", err.message, "error");
      }
      setModals({ ...modals, loadingRedeem: false });
    }
  };

  const buyCredits = async (amount, cost) => {
    if (!currentAccount) return connectWalletNotice();
    const cart = [
      {
        id: `CRE${amount}_${Date.now()}`,
        type: "credit",
        credits: amount,
        name: `${amount}Credits`,
        cartQuantity: 1,
        price: cost,
        image:
          "https://gateway.pinata.cloud/ipfs/QmRgp5idseDhq5969JEijMnPLH9H4VSi3f9SAQEXG2cnJk",
      },
    ];
    // setModals({ ...modals, purchase: false });
    setUseAddress(useAddress);
    setWithExpiry("cartItems", cart);
    setWithExpiry("checkAmount", cost);
    window.location.href = "/checkout";
  };

  return (
    <Layout>
      <Container maxW="container.xl" minH="100vh" h={"auto"} pb="50px">
        <GiftCreditModal
          data={{
            modals,
            setModals,
            connectWalletNotice,
            warn,
            isValidEmail,
          }}
        />
        <RedeemCreditModal data={{ modals, setModals, redeemCredits }} />
        <PurchaseCreditModal
          data={{ modals, setModals }}
          custom={custom}
          setCustom={setCustom}
          buyCredits={buyCredits}
        />
        <Flex flexDirection="column" alignItems="center" pt="30px">
          <Overview modals={modals} setModals={setModals} balance={balance} />

          <Text
            fontSize="32px"
            lineHeight="48px"
            fontWeight="400"
            p="3px 25px"
            borderBottom="2px solid #C2C2C2"
            mb="15px"
          >
            Credits
          </Text>
          <Grid
            width="100%"
            gridTemplateColumns={isNotSmallerScreen ? "1fr 1fr 1fr" : "1fr"}
            gap="20px"
            justifyContent="center"
            alignItems="stretch"
            mt="15px"
          >
            {creditOptions.map((item, index) => (
              <CreditItems
                key={index}
                details={item}
                setModals={setModals}
                setCustom={setCustom}
              />
            ))}
          </Grid>
        </Flex>
      </Container>
    </Layout>
  );
};

export default Credits;
