import { Box, Grid, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import EmptyState from "../EmptyState";
import { Art } from "./style";

const Collectibles = ({ nfts = [], isNotSmallerScreen }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [nftsToDisplay, setNftsToDisplay] = useState(nfts);
  const [search, setSearch] = useState();
  const usersPerPage = 6;
  const pageVisited = pageNumber * usersPerPage;

  const handleSearch = ({ target: { value } }) => {
    setSearch(value);
    const validNFTs = [];
    nfts.forEach((nft) => {
      if (nft.meta.name.toLowerCase().includes(value)) validNFTs.push(nft);
    });
    setNftsToDisplay(validNFTs);
  };

  const displayNfts = nftsToDisplay
    ?.slice(pageVisited, pageVisited + usersPerPage)
    ?.map((data) => {
      return (
        <Box
          maxW="sm"
          bg="#F5F5F5"
          key={data?.tokenId}
          boxShadow="lg"
          _hover={{
            transform: "scale(0.95)",
            cursor: "pointer",
            transition: "0.5s ease-in-out",
          }}
          style={{ transition: "all 0.5s ease-in-out" }}
        >
          <Link to={`/print/${data.id}`} style={{ textDecoration: "none" }}>
            <Box
              style={{ aspectRatio: "1/1" }}
              background={`url(${data?.meta?.content[0].url})`}
              backgroundPosition="center"
              backgroundSize="cover"
            ></Box>

            <Box p={3}>
              <Text
                fontSize="sm"
                fontWeight="semibold"
                lineHeight="tight"
                textTransform="capitalize"
                color="#0c0c0c"
              >
                {data?.meta?.name}
              </Text>
            </Box>
          </Link>
        </Box>
      );
    });

  const pageCount = Math.ceil(nfts.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    window.scrollTo(0, 0);
  };
  return (
    <Box w="100%" minW="60vw" minH="60vh" padding="5px">
      <Art>
        <header>
          <div className="input">
            <AiOutlineSearch color="#6E7191" size={20} />
            <Input
              placeholder="Search"
              variant="filled"
              padding="25px 10px"
              outline="none"
              background="white"
              onChange={handleSearch}
            />
          </div>
        </header>
        <Box paddingBottom="50px" flexGrow={1} mt={5}>
          {nftsToDisplay.length ? (
            <Grid
              templateColumns={
                isNotSmallerScreen ? "repeat(4,1fr)" : "repeat(2,1fr)"
              }
              gap={6}
            >
              {displayNfts}
            </Grid>
          ) : (
            <EmptyState text={`No collectible found with name ${search}`} />
          )}
        </Box>

        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={
            isNotSmallerScreen ? "paginationButtons" : "paginationButtons-m"
          }
          previousLinkClassName={"previousButton"}
          nextLinkClassName={"nextButton"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </Art>
    </Box>
  );
};

export default Collectibles;
