import { useMediaQuery } from "@chakra-ui/media-query";
import {
  Box,
  Button,
  Fade,
  Flex,
  FormControl,
  FormHelperText,
  Input,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import API from "../api";
import { Form } from "../design-system/Form/style";
import HandleError from "../design-system/utils/handleError";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  authLoginAsync,
  selectAddress,
  selectBlockchain,
} from "../redux/slices/auth.slice";

const AddWallet = (props) => {
  const { addWallet, setAddWallet } = props;
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const currentAccount = useAppSelector(selectAddress);
  const blockchain = useAppSelector(selectBlockchain);
  const dispatch = useAppDispatch();

  const resetOTPDetails = () => {
    setSentOtp(false);
    setEmail("");
    setToken("");
  };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    if (!email) return setError("Account Email is required");

    try {
      setLoading(true);

      const response = await API.post("/token/send", { email });
      const { success } = response.data;

      if (success) {
        setSentOtp(true);
      }
    } catch (err) {
      HandleError(err, navigate);
    }
    setLoading(false);
  };

  const handleSubmitToken = async (e) => {
    e.preventDefault();

    if (!email) return setError("Token is Required");
    try {
      setLoading(true);

      const response = await API.post("/token/verify", {
        token,
        email,
        address: currentAccount.toLowerCase(),
        blockchain,
      });
      const { success } = response.data;

      if (success) {
        dispatch(
          authLoginAsync({
            address: currentAccount.toLowerCase(),
            blockchain,
          })
        );
        navigate("/");
      }
    } catch (err) {
      HandleError(err, navigate);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (error) setTimeout(() => setError(null), 3000);
  }, [error]);
  return (
    <Fade in={addWallet}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100vh"
        zIndex={10}
        gap={5}
        bg="rgba(0,0,0,0.2)"
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={isNotSmallerScreen ? "45%" : "80%"}
          bg="#fff"
          p="10px"
          borderRadius="3px"
          _hover={{
            border: "1px solid #ddd",
          }}
        >
          <Flex width="100%" justifyContent="flex-end">
            <Flex
              bg="#424242"
              color="#fff"
              width="25px"
              height="25px"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              cursor="pointer"
              transition="0.3s"
              _hover={{
                transform: "scale(0.99)",
              }}
              onClick={() => setAddWallet(false)}
            >
              <AiOutlineClose size={15} />
            </Flex>
          </Flex>
          <Flex
            width="80%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize="24px" fontWeight="500">
              {sentOtp ? "Verify  OTP" : "Add Wallet"}
            </Text>
            {error && (
              <Text
                fontWeight="600"
                color="red"
                fontSize="12px"
                textAlign="center"
              >
                {error}
              </Text>
            )}
            <Form onSubmit={sentOtp ? handleSubmitToken : handleSubmitEmail}>
              {sentOtp && email ? (
                <FormControl>
                  <Text
                    fontSize="14px"
                    fontWeight="500"
                    mt={5}
                    mb={1}
                    textAlign="center"
                  >
                    An OTP Has Been Sent to{" "}
                    <i style={{ opacity: 0.9 }}>{email.toLowerCase()}</i>
                  </Text>
                  <Input
                    placeholder="Enter OTP"
                    variant="outline"
                    padding="25px 10px"
                    margin="10px 0px"
                    name="email"
                    value={token}
                    required={true}
                    onChange={(e) => {
                      setToken(e.target.value);
                    }}
                  />
                  <Box display={"flex"} justifyContent="space-between">
                    <FormHelperText onClick={resetOTPDetails}>
                      Wrong Email?
                    </FormHelperText>{" "}
                    {/* <Button color={"grey"} onClick={restOTPDetails}>
                      Change Email{" "}
                    </Button> */}
                  </Box>
                </FormControl>
              ) : (
                <>
                  <Text
                    fontSize="lg"
                    fontWeight="500"
                    mt={5}
                    mb={1}
                    textTransform="capitalize"
                  >
                    {blockchain} Address
                  </Text>
                  <Input
                    type="text"
                    name="address"
                    value={currentAccount}
                    disabled
                    required
                  />
                  <FormControl>
                    <Text
                      fontSize="lg"
                      fontWeight="500"
                      mt={5}
                      mb={1}
                      textTransform="capitalize"
                    >
                      Account email
                    </Text>
                    <Input
                      type="email"
                      placeholder="Enter Account Email"
                      variant="outline"
                      padding="25px 10px"
                      margin="10px 0px"
                      name="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </FormControl>
                </>
              )}
              <Button
                colorScheme="linkedin"
                m="10px 0px"
                p="10px 20px"
                type="submit"
                bg="#000000"
                borderRadius="0px"
                color="#fff"
                _hover={{ bg: "#123456" }}
                isLoading={loading}
                width="100%"
              >
                {sentOtp ? "Verify" : "Continue"}
              </Button>
            </Form>
          </Flex>
        </Flex>
      </Flex>
    </Fade>
  );
};

export default AddWallet;
