import { Box, Container, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../api";
import { Spinner, Text } from "../../design-system";
import Layout from "../../design-system/Layout/Layout";

const ViewPost = () => {
  const { id } = useParams();
  const [post, setPost] = useState("");
  const [loading, setLoading] = useState(false);

  const { image, title, subtitle, body } = post;

  const getPost = async (id) => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await api.get(`article/${id}`);
      setPost(data);

      setLoading(false);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getPost(id);
  }, [id]);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Layout>
          <Container maxW="container.lg" paddingBottom="100px" mt={10}>
            {post ? (
              <Box>
                <Image src={image} />
                <Box mt={5} mb={5}>
                  <Text size="lg">{title}</Text>
                  <Text size="md">{subtitle}</Text>
                </Box>

                <div dangerouslySetInnerHTML={{ __html: body }} />
              </Box>
            ) : (
              <Text>Loading...</Text>
            )}
          </Container>
        </Layout>
      )}
    </>
  );
};

export default ViewPost;
