import { useMediaQuery } from "@chakra-ui/media-query";
import {
  Box,
  Button,
  Container,
  Grid,
  Image,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import cs from "../../design-system/Assets/contact.avif";
import Layout from "../../design-system/Layout/Layout";
import useRequest from "../../hooks/useRequest";
import { useAppSelector } from "../../redux/hooks";
import {
  selectBlockchain,
  selectCurrentAccount,
} from "../../redux/slices/auth.slice";

const Contact = () => {
  const currentAccount = useAppSelector(selectCurrentAccount);
  const blockchain = useAppSelector(selectBlockchain);
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { message: apiMessage, loading, makeRequest } = useRequest();

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = {
      address: currentAccount,
      blockchain,
      name,
      email,
      mobile: phone,
      subject,
      message,
    };
    await makeRequest("contact", { ...data });
    if (apiMessage !== "Invalid data provided") {
      setName("");
      setEmail("");
      setPhone("");
      setSubject("");
      setMessage("");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Container maxW="container.xl" h={isNotSmallerScreen ? "100vh" : "auto"}>
        <Box>
          <Box marginTop="5%">
            <Text
              fontSize="40px"
              color="#0c0c0c"
              fontWeight="700"
              textAlign="left"
            >
              Let's Talk
            </Text>
          </Box>
          <hr />

          <Box mt={5} className="center">
            <Grid
              templateColumns={
                isNotSmallerScreen ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
              }
              gap={6}
            >
              <Box
                mt={10}
                boxShadow="lg"
                borderRadius={"20px"}
                minH="400px"
                padding={10}
              >
                <Box mb={6}>
                  <Text mb={1} fontSize="18px" fontWeight="600" color="#0c0c0c">
                    Chat with us
                  </Text>
                  <hr />
                </Box>
                <form onSubmit={handleSubmit}>
                  <Grid templateColumns="repeat(2,1fr)" gap={6}>
                    <Input
                      type="text"
                      variant="flushed"
                      placeholder="Enter your Name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <Input
                      type="email"
                      variant="flushed"
                      placeholder="Enter your Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <Input
                      type="text"
                      variant="flushed"
                      placeholder="Enter your Contact Number"
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                    <Input
                      type="text"
                      variant="flushed"
                      placeholder="Enter your subject"
                      name="subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                    />
                  </Grid>

                  <Textarea
                    placeholder="Enter your message"
                    variant="flushed"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    mt={6}
                    rows={5}
                    required
                  />

                  <Button
                    mt={6}
                    w="100%"
                    type="submit"
                    borderRadius="10px"
                    bg="#fecb4c"
                    fontWeight="500"
                    padding="5"
                    _hover={{
                      color: "#fff",
                    }}
                  >
                    {loading ? "loading" : "Submit"}
                  </Button>
                </form>
              </Box>

              <Box mt={10}>
                <Image
                  src={cs}
                  alt={cs}
                  display={isNotSmallerScreen ? "block" : "none"}
                  borderRadius={"20px"}
                  boxShadow="lg"
                />
              </Box>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default Contact;
