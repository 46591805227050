import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";

const Step = ({ imgSrc, id, title, body }) => {
  return (
    <Box mb={"5vh"}>
      <Text mt={3} fontSize="2xl" fontWeight="600">
        {id}. {title}
      </Text>
      <Text fontSize="lg" mt={3}>
        {body}
      </Text>
      <Image
        src={imgSrc}
        alt={imgSrc}
        h="30vh"
        objectFit="cover"
        mt={5}
        mb={5}
        w={id === "7" && "100%"}
      />
    </Box>
  );
};

export default Step;
