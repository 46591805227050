import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react";

const DefaultInput = ({
  isInvalid,
  label,
  helperText,
  errorMessage,
  ...otherProps
}) => {
  return (
    <FormControl isInvalid={isInvalid}>
      {label && <FormLabel>{label}</FormLabel>}
      <Input
        variant="outline"
        padding="25px 10px"
        margin="10px 0px"
        border={"0px"}
        {...(isInvalid && { border: "0px" })}
        {...otherProps}
      />
      {!isInvalid ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default DefaultInput;

// Example
// <DefaultInput
//   value={email}
//   isInvalid={emailError}
//   helperText={'Please verify email as this transaction cannot be reversed.'}
//   errorMessage={'Please enter a valid email.'}
//   onChange={handleChange}
//   type='password'
//   placeholder='Enter email'
//   name='email'
// />
