import { useMediaQuery } from "@chakra-ui/media-query";
import {
  Box,
  Container,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../design-system/Layout/Layout";
import { useAppSelector } from "../../redux/hooks";
import {
  selectBlockchain,
  selectCurrentAccount,
  selectUser,
} from "../../redux/slices/auth.slice";
import Account from "./components/Account";
import Ship from "./components/Address";

const Settings = () => {
  const navigate = useNavigate();
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 580px)");
  const currentAccount = useAppSelector(selectCurrentAccount);
  const blockchain = useAppSelector(selectBlockchain);
  const user = useAppSelector(selectUser);
  const [tab] = useState(["Account"]);
  useEffect(() => {
    if (!currentAccount) {
      navigate("/");
    }
  });

  return (
    <Layout>
      <Container maxW="container.xl" minH="100vh" h={"auto"}>
        <Flex flexDirection="column" alignItems="center" pt="30px">
          <Text
            fontSize="32px"
            lineHeight="48px"
            fontWeight="400"
            p="5px"
            borderBottom="2px solid #C2C2C2"
            mb="15px"
          >
            Settings
          </Text>
          <Box w="80%">
            <Tabs
              isFitted={isNotSmallerScreen ? false : true}
              variant="unstyled"
              mt="10px"
            >
              <TabList>
                {tab.map((item, index) => (
                  <Tab
                    key={index}
                    p="10px 0px"
                    color="#000000"
                    textAlign="left"
                    borderBottom="2px solid transparent"
                    fontSize="24px"
                    fontWeight="600"
                    mr="40px"
                    _selected={{
                      borderBottom: "2px solid #5865F2",
                      boxShadow: "none",
                    }}
                  >
                    <Text whiteSpace={!isNotSmallerScreen && "nowrap"}>
                      {item}
                    </Text>
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                <TabPanel p="10px 0px">
                  {
                    <Account
                      user={user}
                      currentAccount={currentAccount}
                      blockchain={blockchain}
                      isNotSmallerScreen={isNotSmallerScreen}
                    />
                  }
                  <br />
                  {<Ship isNotSmallerScreen={isNotSmallerScreen} />}
                </TabPanel>
                {/* <TabPanel p="10px 0px">Billings</TabPanel> */}
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};

export default Settings;
