import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";

export const authLoginAsync = createAsyncThunk(
  "auth/authLoginAsync",
  async (authData) => {
    try {
      const response = await api.post("/user/auth", authData);
      const {
        data: { data },
      } = response;
      return data;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else if (error.request) {
        throw new Error(error.request.message);
      } else if (error.message) {
        throw new Error(error.message);
      }
    }
  }
);

export const getCreditBalanceAsync = createAsyncThunk(
  "auth/getCreditBalanceAsync",
  async (userId, { getState }) => {
    const token = getState().auth.token;
    const response = await api.get(`/credit/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const {
      data: {
        data: { balance },
      },
    } = response;
    return balance;
  }
);

const initialState = {
  user: null,
  token: null,
  credit: null,
  cart: null,
  blockchain: null,
  address: null,
  isAuthenticated: false,
  isLoading: false,
  errorMessage: null,
  message: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => initialState,
    clearAuthMessage: (state) => {
      state.errorMessage = null;
      state.message = null;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(authLoginAsync.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(authLoginAsync.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.credit = action.payload.credit;
        state.cart = action.payload.cart;
        state.currentAccount = action.meta.arg.address;
        state.address = action.meta.arg.address;
        state.blockchain = action.meta.arg.blockchain;
        state.isAuthenticated = true;
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(authLoginAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.error.message;
        state.blockchain = action.meta.arg.blockchain;
        state.address = action.meta.arg.address;
      });

    build
      .addCase(getCreditBalanceAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCreditBalanceAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.credit.balance = action.payload;
      })
      .addCase(getCreditBalanceAsync.rejected, (state, action) => {
        state.errorMessage = action.payload.message;
        state.isLoading = false;
      });
  },
});

export const { logout, clearAuthMessage } = authSlice.actions;
export const selectToken = (state) => state.auth.token;
export const selectUser = (state) => state.auth.user;
export const selectCurrentAccount = (state) => state.auth.currentAccount;
export const selectAddress = (state) => state.auth.address;
export const selectBlockchain = (state) => state.auth.blockchain;
export const selectCreditBalance = (state) => state.auth.credit.balance;
export const selectAuthErrorMessage = (state) => state.auth.errorMessage;
export const selectAuthenticated = (state) => state.auth.isAuthenticated;
export const isLoading = (state) => state.auth.isLoading;

export default authSlice.reducer;
