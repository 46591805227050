import PropTypes from "prop-types";
import styled from "styled-components";

const BaseButton = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #fb4383;
  color: ${(props) => (props.color ? props.color : "#000")};
  font-size: 18px;
  transition: 0.5s ease;
  width: auto;

  &:hover {
    transform: scale(0.95);
  }
`;

const Filled = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(86deg, #53bafc -27.83%, #fb4383 89.38%);
  color: #fff;
  font-size: 18px;
  width: ${(props) => (props.width ? props.width : "auto")};
  transition: 0.5s ease;

  &:hover {
    transform: scale(0.95);
    cursor: pointer;
  }
`;

export const Button = ({ type, text, icon, onClick, ...props }) => {
  // eslint-disable-next-line default-case
  switch (type) {
    case "bordered":
      return (
        <BaseButton {...props} onClick={onClick}>
          {icon && (
            <img src={icon} alt={icon} style={{ marginRight: "10px" }} />
          )}{" "}
          {text}
        </BaseButton>
      );
    case "filled":
      return (
        <Filled {...props} onClick={onClick}>
          {text}
          {icon}
        </Filled>
      );
  }
};

Button.propTypes = {
  text: PropTypes.node.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

Button.defaultProps = {
  onClick: () => {},
  icon: "",
  type: "bordered",
};
