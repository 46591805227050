import React from "react";
import { Box, Container, Center, Button, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const ErrorPage = () => {
  return (
    <Box h="100vh">
      <Container maxW="container.xl">
        <Center>
          <script src="https://cdn.lordicon.com/xdjxvujz.js"></script>
          <lord-icon
            src="https://cdn.lordicon.com/vyukcgvf.json"
            trigger="loop"
            style={{ width: "250px", height: "250px" }}
          ></lord-icon>
        </Center>
        <Box>
          <Text textAlign="center" fontSize="7xl" fontWeight="bold">
            404
          </Text>
          <Text textAlign="center" fontSize="4xl" fontWeight="600">
            Oooops!
          </Text>
          <Text textAlign="center" fontSize="5xl" fontWeight="600">
            Page Not Found
          </Text>
          <Center>
            <Button as={Link} to="/">
              Back to home
            </Button>
          </Center>
        </Box>
      </Container>
    </Box>
  );
};
