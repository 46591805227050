import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Text,
} from "@chakra-ui/react";
import React from "react";

const Ship = ({ isNotSmallerScreen }) => {
  return (
    <Box
      m="10px 0px"
      w="100%"
      border="1px solid #E9E9E9"
      filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.13))"
      p="20px 15px"
    >
      <Text
        w="100%"
        fontSize="24px"
        fontWeight="400"
        borderBottom="2px solid #CACACA"
      >
        Your Shipping Details
      </Text>
      <Grid
        gridTemplateColumns={isNotSmallerScreen ? "1fr 1fr" : "1fr"}
        columnGap="10px"
        rowGap={isNotSmallerScreen ? "20px" : "10px"}
        mt="20px"
      >
        <FormControl isRequired>
          <FormLabel>Address</FormLabel>
          <Input type="text" placeholder="Enter your Address" />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Town/City</FormLabel>
          <Input type="text" placeholder="Enter your Town/City" />
        </FormControl>
        <FormControl>
          <FormLabel>State</FormLabel>
          <Input type="text" placeholder="Enter your State" />
        </FormControl>
        <FormControl>
          <FormLabel>Country</FormLabel>
          <Input type="text" placeholder="Enter your Country" />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Zip Code</FormLabel>
          <Input type="text" placeholder="Enter your PostCode" />
        </FormControl>
      </Grid>
      <Button
        variant="solid"
        colorScheme="linkedin"
        w={isNotSmallerScreen ? "25%" : "100%"}
        m="20px 0px"
      >
        Submit
      </Button>
    </Box>
  );
};
export default Ship;
