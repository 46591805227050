import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const MerchItem = ({ data }) => {
  const navigate = useNavigate();
  const { image, text, available } = data;
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderRadius="10px 10px 3px 3px"
      overflow="hidden"
      boxShadow="0px 1px 2px 0px #b1b1b1"
      cursor="pointer"
      transition="0.3s"
      _hover={
        available && {
          transform: "scale(0.95)",
        }
      }
      onClick={() => {
        if (available) {
          navigate("/merchs-checkout", {
            state: {
              data,
            },
          });
        }
      }}
    >
      {!available && (
        <Text
          position="absolute"
          background="#53bafc"
          p="10px"
          color="#fff"
          fontWeight="700"
          boxShadow="0px 0px 10px 0px #000"
        >
          Not available
        </Text>
      )}
      <Box opacity={available ? "1" : "0.5"} zIndex="-1">
        <Image src={image} alt="" w="100%" />
      </Box>
      <Text
        fontWeight="500"
        fontSize="20px"
        p="7px 0px"
        w="100%"
        textAlign="center"
      >
        {text}
      </Text>
    </Flex>
  );
};

export default MerchItem;
