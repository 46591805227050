import APISlice from ".";

const MintApiSlice = APISlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchUserMints: builder.query({
        query() {
          return "/mint/user";
        },
        transformResponse: (response) => {
          const {
            data: { data },
          } = response;
          return data;
        },
      }),
    };
  },
});

export const { useFetchUserMintsQuery } = MintApiSlice;
