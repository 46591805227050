import styled from "styled-components";

export const Container = styled.div`
  padding: 10px 170px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  .inner {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

  @media screen and (max-width: 960px) {
    padding: 10px 7px;
  }
`;

export const Line = styled.div`
  border-bottom: 1px solid #bababa;
  width: 100%;
`;

export const Box = styled.div`
  padding: 10px 170px;
  margin-bottom: 20%;

  @media screen and (max-width: 960px) {
    padding: 10px 7px;
    margin-bottom: 20%;
  }
`;

export const Editors = styled.div`
  width: 100%;
  border: 1px solid #767575;
  border-radius: 8px;
  height: 60vh;
  margin-top: 2%;

  .buttons {
    display: flex;
    padding: 10px;
  }

  button {
    background: none;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }

  .edit {
    padding: 1em;
  }
`;

export const TagsInput = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  max-width: 100%;
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
  margin: 15px 0;

  input {
    flex: 1;
    border: none;
    font-size: 14px;
    padding: 4px 0 0 0;
    min-width: 100px;
    width: auto;
    margin-top: 4px;
    &:focus {
      outline: transparent;
      border: none;
    }
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
  }

  .tag {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    background: #fecb4c;
  }

  .tag-title {
    margin-top: 3px;
  }

  .tag-close-icon {
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-left: 8px;
    color: #fff;

    border-radius: 50%;
    background: red;
    cursor: pointer;
  }
`;
