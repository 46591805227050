import React from "react";
import { Center } from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/media-query";

export const Spinner = () => {
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");

  return (
    <Center marginTop={isNotSmallerScreen ? "15%" : "65%"}>
      <lord-icon
        src="https://cdn.lordicon.com/ulhdumaq.json"
        trigger="loop"
        colors="primary:#121331,secondary:#08a88a"
        stroke="40"
        style={{ width: "250px", height: "250px" }}
      ></lord-icon>
    </Center>
  );
};
