import { useMediaQuery } from "@chakra-ui/media-query";
import { Box, Container, Grid, Image, Text } from "@chakra-ui/react";
import aboutI from "../../design-system/Assets/about.jpeg";
import aboutII from "../../design-system/Assets/aboutlogo.jpeg";

import Layout from "../../design-system/Layout/Layout";

const About = () => {
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");

  return (
    <Layout>
      <Box maxW="100%" bg="#0c0c0c">
        <Container maxW="container.xl" paddingTop="50px">
          <Text color="#fff" fontSize="4xl" fontWeight="600" textAlign="center">
            About
          </Text>
          <Box>
            <Image
              marginTop="50px"
              margin="auto"
              position="relative"
              width={200}
              src={aboutII}
              alt={aboutII}
              className="mission-image"
              // display={isNotSmallerScreen ? "block" : "none"}
            />
          </Box>
          <Grid
            templateColumns={
              isNotSmallerScreen ? "repeat(2,1fr)" : "repeat(1,1fr)"
            }
            gap={6}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignContent="center"
            >
              <Text
                fontSize="md"
                fontWeight="400"
                color="#fff"
                wordWrap="wrap"
                lineHeight="10"
                textAlign={isNotSmallerScreen ? "left" : "center"}
              >
                NFT Print Pro™ is the go-to professional printing service for
                the NFT + Web3 community.
                <br />
                We work with local printing shops and help them leverage their
                existing infrastructure to serve the NFT market.
                <br />
                Our print partners receive access to an enthusiastic network of
                potential customers, and in return, NFT collectors get access to
                physical prints and merchandise, which they can use to decorate
                their favorite spaces and build online “merch” businesses out of
                their favorite Web3 assets.
                <br />
                Our product is blockchain native and connects directly with your
                crypto wallet to access the NFTs needed for your prints. We
                print with durable, sustainable materials with a smooth finish
                and top-quality inks. We also ship worldwide for a flat-free
                using 100% recyclable packaging.
                <br />
                Mint, then print your NFTs today with NFT Print Pro™.
              </Text>
            </Box>
            <Box>
              <Image
                marginTop="50px"
                position="relative"
                src={aboutI}
                alt={aboutI}
                className="mission-image"
                // display={isNotSmallerScreen ? "block" : "none"}
              />
            </Box>
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export default About;
