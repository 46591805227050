// import { Back } from "./style";
import { useMediaQuery } from "@chakra-ui/media-query";
import { Box, Container, Grid, Text } from "@chakra-ui/react";
import { getCountries, getStates } from "country-state-picker";
import React, { useEffect, useState } from "react";
import { getWithExpiry } from "../../utils/store";
import AddressForm from "./AddressForm";

const Checkout = () => {
  const [coupon] = useState(getWithExpiry("coup"));
  const [totalAmount] = useState(getWithExpiry("checkAmount"));
  const [cart] = useState(getWithExpiry("cartItems"));
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [st, setSt] = useState([]);
  const fetchCountries = async () => {
    try {
      const country = await getCountries();
      setCountries(country);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCountry = (e) => {
    setCountry(e.target.value);
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (country) {
      const getState = async () => {
        const state = await getStates(country);
        setSt(state);
      };
      getState();
    }
  }, [country]);

  return (
    <Box>
      <Container maxW="container.xl">
        <Grid
          height="100%"
          overflowY="auto"
          padding={isNotSmallerScreen ? "20px" : "20px"}
        >
          <Grid
            w="100%"
            gridTemplateColumns={"auto 1fr"}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Text
              fontSize={isNotSmallerScreen ? "30px" : "20px"}
              fontWeight="600"
              color="#0c0c0c"
            >
              Checkout
            </Text>
          </Grid>
          <Box marginTop="20px" marginBottom="20px">
            <hr />
          </Box>

          <div>
            <AddressForm
              st={st}
              countries={countries}
              cart={cart}
              totalAmount={totalAmount}
              handleCountry={handleCountry}
              isNotSmallerScreen={isNotSmallerScreen}
              country={country}
              coupon={coupon}
            />
          </div>
        </Grid>
      </Container>
    </Box>
  );
};

export default Checkout;
