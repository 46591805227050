import { Box, Flex, Grid, Image, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const ConnectPopup = ({
  connectPopup,
  loading,
  setLoading,
  setConnectPopup,
  connectUsingPhantom,
  connectUsingMetaMask,
  connectUsingWalletConnect,
}) => {
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100vh"
      zIndex={10}
      gap={5}
      display={connectPopup ? "flex" : "none"}
      bg="rgba(0,0,0,0.2)"
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width={isNotSmallerScreen ? "45%" : "90%"}
        bg="#fff"
        p="10px"
        borderRadius="3px"
        _hover={{
          border: "1px solid #ddd",
        }}
      >
        <Flex width="100%" justifyContent="flex-end">
          <Flex
            bg="#424242"
            color="#fff"
            width="25px"
            height="25px"
            justifyContent="center"
            alignItems="center"
            borderRadius="50%"
            cursor="pointer"
            transition="0.3s"
            _hover={{
              transform: "scale(0.99)",
            }}
            onClick={() => setConnectPopup(false)}
          >
            <AiOutlineClose size={15} />
          </Flex>
        </Flex>
        <Grid
          width={isNotSmallerScreen ? "80%" : "100%"}
          gridTemplateColumns="1fr 1fr"
          placeItems="center"
        >
          <Box
            padding="20px"
            cursor="pointer"
            margin="0px 10px"
            transition="0.2s"
            borderRadius="5px"
            textAlign="center"
            _hover={{
              background: "#0000000f",
            }}
            onClick={async () => {
              setLoading(true);
              setConnectPopup(false);
              await connectUsingMetaMask();
              setLoading(false);
            }}
          >
            <Image src="./assets/metamask.png" />
            <Text fontWeight="600">MetaMask</Text>
          </Box>
          <Box
            padding="20px"
            cursor="pointer"
            margin="0px 10px"
            transition="0.2s"
            borderRadius="5px"
            textAlign="center"
            _hover={{
              background: "#0000000f",
            }}
            onClick={async () => {
              setLoading(true);
              setConnectPopup(false);
              await connectUsingWalletConnect();
              setLoading(false);
            }}
          >
            <Image src="./assets/walletconnect.png" />
            <Text fontWeight="600">WalletConnect</Text>
          </Box>
          <Box
            padding="20px"
            cursor="pointer"
            margin="0px 25%"
            transition="0.2s"
            borderRadius="5px"
            textAlign="center"
            gridColumn="1/3"

            _hover={{
              background: "#0000000f",
            }}

            onClick={async () => {
              setLoading(true);
              setConnectPopup(false);
              await connectUsingPhantom();
              setLoading(false);
            }}
          >
            <Image src="./assets/phantom.webp" />
            <Text fontWeight="600">Phantom</Text>
          </Box>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default ConnectPopup;
