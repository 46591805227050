import { Button, Grid, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import referBg from "../../../design-system/Assets/refer-bg.png";
import useRequest from "../../../hooks/useRequest";
import { useAppSelector } from "../../../redux/hooks";
import {
  selectBlockchain,
  selectCurrentAccount,
} from "../../../redux/slices/auth.slice";

const Refer = ({ isNotSmallerScreen, account }) => {
  const blockchain = useAppSelector(selectBlockchain);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const [address] = useState(account);
  const [email, setEmail] = useState("");
  const { loading, makeRequest } = useRequest();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await makeRequest("refer", { email, address, blockchain });
    setEmail("");
  };
  return (
    <Grid
      placeItems="center"
      w={isNotSmallerScreen ? "70%" : "90%"}
      p="15px"
      margin="20px auto"
      background={`url(${referBg})`}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="8px"
      color="#fff"
      textAlign="center"
      rowGap={!isNotSmallerScreen && "10px"}
    >
      <Text
        fontSize={isNotSmallerScreen ? "52px" : "40px"}
        fontWeight="600"
        w={isNotSmallerScreen ? "50%" : "80%"}
      >
        Refer a friend, get 10% off
      </Text>
      <Text fontSize="12px" fontWeight="500" w="50%">
        Simply refer a friend and get a 10% discount on your next print when
        they sign up through your link
      </Text>
      <form
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid
          m="15px 0px"
          w={isNotSmallerScreen ? "50%" : "80%"}
          gridTemplateColumns="80% 20%"
          border="2px solid #EBE7FF"
          borderRadius="8px"
          overflow="hidden"
        >
          <Input
            type="email"
            outline="none"
            border="none"
            placeholder="Email Address"
            borderRadius="0"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            disabled={currentAccount ? false : true}
            type="submit"
            borderRadius="0px"
            background="#53BAFC"
            _hover={{
              background: "#53BAFC",
            }}
          >
            {loading ? "..." : "Refer"}
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};

export default Refer;
