const printProducts = [
  {
    name: "Stickers",
    value: [
      {
        name: "Circle Stickers",
        sizes: {
          "1inch": [
            { price: "2.79 each", amount: "50" },
            { price: "1.69 each", amount: "100" },
            { price: "1.19 each", amount: "250" },
            { price: "0.69 each", amount: "500" },
            { price: "0.59 each", amount: "1000" },
          ],

          "3inch": [
            { price: "4.19 each", amount: "50" },
            { price: "2.69 each", amount: "100" },
            { price: "1.89 each", amount: "250" },
            { price: "1.39 each", amount: "500" },
            { price: "1.29 each", amount: "1000" },
          ],

          "5inch": [
            { price: "6.99 each", amount: "50" },
            { price: "4.19 each", amount: "100" },
            { price: "2.29 each", amount: "250" },
            { price: "2.09 each", amount: "500" },
            { price: "1.59 each", amount: "1000" },
          ],
        },
      },
      {
        name: "Clear Stickers",
        sizes: {
          "1inch": [
            { price: "3.49 each", amount: "50" },
            { price: "2.09 each", amount: "100" },
            { price: "1.39 each", amount: "250" },
            { price: "0.89 each", amount: "500" },
            { price: "0.69 each", amount: "1000" },
          ],

          "3inch": [
            { price: "5.29 each", amount: "50" },
            { price: "3.39 each", amount: "100" },
            { price: "2.29 each", amount: "250" },
            { price: "1.79 each", amount: "500" },
            { price: "1.59 each", amount: "1000" },
          ],

          "5inch": [
            { price: "8.79 each", amount: "50" },
            { price: "5.29 each", amount: "100" },
            { price: "2.79 each", amount: "250" },
            { price: "2.69 each", amount: "500" },
            { price: "1.99 each", amount: "1000" },
          ],
        },
      },
      {
        name: "Die Cut Stickers",
        sizes: {
          "1inch": [
            { price: "2.79 each", amount: "50" },
            { price: "1.69 each", amount: "100" },
            { price: "1.19 each", amount: "250" },
            { price: "0.69 each", amount: "500" },
            { price: "0.59 each", amount: "1000" },
          ],

          "3inch": [
            { price: "4.19 each", amount: "50" },
            { price: "2.69 each", amount: "100" },
            { price: "1.89 each", amount: "250" },
            { price: "1.39 each", amount: "500" },
            { price: "1.29 each", amount: "1000" },
          ],

          "5inch": [
            { price: "6.99 each", amount: "50" },
            { price: "4.19 each", amount: "100" },
            { price: "2.29 each", amount: "250" },
            { price: "2.09 each", amount: "500" },
            { price: "1.59 each", amount: "1000" },
          ],
        },
      },
      {
        name: "Holographic Stickers",
        sizes: {
          "1inch": [
            { price: "3.49 each", amount: "50" },
            { price: "2.19 each", amount: "100" },
            { price: "1.59 each", amount: "250" },
            { price: "0.99 each", amount: "500" },
            { price: "0.89 each", amount: "1000" },
          ],

          "3inch": [
            { price: "5.29 each", amount: "50" },
            { price: "3.49 each", amount: "100" },
            { price: "2.49 each", amount: "250" },
            { price: "1.99 each", amount: "500" },
            { price: "1.89 each", amount: "1000" },
          ],

          "5inch": [
            { price: "8.79 each", amount: "50" },
            { price: "5.49 each", amount: "100" },
            { price: "3.09 each", amount: "250" },
            { price: "2.99 each", amount: "500" },
            { price: "2.29 each", amount: "1000" },
          ],
        },
      },
      {
        name: "Rectangle Stickers",
        sizes: {
          "2inch": [
            { price: "3.29 each", amount: "50" },
            { price: "2.09 each", amount: "100" },
            { price: "1.59 each", amount: "250" },
            { price: "0.99 each", amount: "500" },
            { price: "0.89 each", amount: "1000" },
          ],

          "3inch": [
            { price: "4.19 each", amount: "50" },
            { price: "2.69 each", amount: "100" },
            { price: "1.89 each", amount: "250" },
            { price: "1.39 each", amount: "500" },
            { price: "1.29 each", amount: "1000" },
          ],

          "5inch": [
            { price: "6.99 each", amount: "50" },
            { price: "4.19 each", amount: "100" },
            { price: "2.29 each", amount: "250" },
            { price: "2.09 each", amount: "500" },
            { price: "1.59 each", amount: "1000" },
          ],
        },
      },
    ],
  },
  {
    name: "Lanyards",
    value: [
      {
        name: "Polyester Printed Lanyards",
        sizes: {
          "5/8inch": [
            { price: "6.19 each", amount: "50" },
            { price: "4.09 each", amount: "100" },
            { price: "2.19 each", amount: "250" },
            { price: "1.89 each", amount: "500" },
            { price: "1.59 each", amount: "1000" },
          ],

          "3/4inch": [
            { price: "6.44 each", amount: "50" },
            { price: "4.19 each", amount: "100" },
            { price: "2.29 each", amount: "250" },
            { price: "1.89 each", amount: "500" },
            { price: "1.69 each", amount: "1000" },
          ],

          "1inch": [
            { price: "6.79 each", amount: "50" },
            { price: "4.39 each", amount: "100" },
            { price: "2.39 each", amount: "250" },
            { price: "2.09 each", amount: "500" },
            { price: "1.69 each", amount: "1000" },
          ],
        },
      },
      {
        name: "Sublimated Lanyards",
        sizes: {
          "5/8inch": [
            { price: "5.89 each", amount: "50" },
            { price: "3.89 each", amount: "100" },
            { price: "2.09 each", amount: "250" },
            { price: "1.79 each", amount: "500" },
            { price: "1.49 each", amount: "1000" },
          ],

          "3/4inch": [
            { price: "6.19 each", amount: "50" },
            { price: "4.09 each", amount: "100" },
            { price: "2.19 each", amount: "250" },
            { price: "1.89 each", amount: "500" },
            { price: "1.59 each", amount: "1000" },
          ],

          "1inch": [
            { price: "6.44 each", amount: "50" },
            { price: "4.19 each", amount: "100" },
            { price: "2.29 each", amount: "250" },
            { price: "1.89 each", amount: "500" },
            { price: "1.69 each", amount: "1000" },
          ],
        },
      },
      {
        name: "Tubular Lanyards",
        sizes: {
          "5/8inch": [
            { price: "5.89 each", amount: "50" },
            { price: "3.89 each", amount: "100" },
            { price: "2.09 each", amount: "250" },
            { price: "1.79 each", amount: "500" },
            { price: "1.49 each", amount: "1000" },
          ],

          "3/4inch": [
            { price: "6.19 each", amount: "50" },
            { price: "4.09 each", amount: "100" },
            { price: "2.19 each", amount: "250" },
            { price: "1.89 each", amount: "500" },
            { price: "1.59 each", amount: "1000" },
          ],

          "1inch": [
            { price: "6.44 each", amount: "50" },
            { price: "4.19 each", amount: "100" },
            { price: "2.29 each", amount: "250" },
            { price: "1.89 each", amount: "500" },
            { price: "1.69 each", amount: "1000" },
          ],
        },
      },
      {
        name: "USA Made Dye Sublimated",
        sizes: {
          "1/2inch": [
            { price: "5.99 each", amount: "100" },
            { price: "4.19 each", amount: "250" },
            { price: "3.69 each", amount: "500" },
            { price: "2.89 each", amount: "1000" },
          ],

          "3/4inch": [
            { price: "6.19 each", amount: "100" },
            { price: "4.29 each", amount: "250" },
            { price: "3.79 each", amount: "500" },
            { price: "2.99 each", amount: "1000" },
          ],

          "1inch": [
            { price: "6.29 each", amount: "100" },
            { price: "4.49 each", amount: "250" },
            { price: "3.99 each", amount: "500" },
            { price: "3.09 each", amount: "1000" },
          ],
        },
      },
    ],
  },
  {
    name: "Key Chains",
    value: [
      {
        name: "Antique Die",
        sizes: {
          "1inch": [
            { price: "7.29 each", amount: "50" },
            { price: "5.19 each", amount: "100" },
            { price: "4.49 each", amount: "250" },
            { price: "3.49 each", amount: "500" },
            { price: "3.09 each", amount: "1000" },
          ],

          "1.5inch": [
            { price: "7.69 each", amount: "50" },
            { price: "5.59 each", amount: "100" },
            { price: "4.79 each", amount: "250" },
            { price: "4.19 each", amount: "500" },
            { price: "3.49 each", amount: "1000" },
          ],

          "2inch": [
            { price: "8.69 each", amount: "50" },
            { price: "6.59 each", amount: "100" },
            { price: "5.69 each", amount: "250" },
            { price: "5.19 each", amount: "500" },
            { price: "4.39 each", amount: "1000" },
          ],

          "2.5inch": [
            { price: "9.99 each", amount: "50" },
            { price: "7.89 each", amount: "100" },
            { price: "6.99 each", amount: "250" },
            { price: "6.49 each", amount: "500" },
            { price: "5.69 each", amount: "1000" },
          ],

          "3inch": [
            { price: "11.19 each", amount: "50" },
            { price: "9.09 each", amount: "100" },
            { price: "8.29 each", amount: "250" },
            { price: "7.69 each", amount: "500" },
            { price: "6.99 each", amount: "1000" },
          ],
        },
      },
      {
        name: "Photodome Printed",
        sizes: {
          "1inch": [
            { price: "7.29 each", amount: "50" },
            { price: "5.19 each", amount: "100" },
            { price: "4.49 each", amount: "250" },
            { price: "3.49 each", amount: "500" },
            { price: "3.09 each", amount: "1000" },
          ],

          "1.5inch": [
            { price: "7.69 each", amount: "50" },
            { price: "5.59 each", amount: "100" },
            { price: "4.79 each", amount: "250" },
            { price: "4.19 each", amount: "500" },
            { price: "3.49 each", amount: "1000" },
          ],

          "2inch": [
            { price: "8.69 each", amount: "50" },
            { price: "6.59 each", amount: "100" },
            { price: "5.69 each", amount: "250" },
            { price: "5.19 each", amount: "500" },
            { price: "4.39 each", amount: "1000" },
          ],

          "2.5inch": [
            { price: "9.99 each", amount: "50" },
            { price: "7.89 each", amount: "100" },
            { price: "6.99 each", amount: "250" },
            { price: "6.49 each", amount: "500" },
            { price: "5.69 each", amount: "1000" },
          ],

          "3inch": [
            { price: "11.19 each", amount: "50" },
            { price: "9.09 each", amount: "100" },
            { price: "8.29 each", amount: "250" },
            { price: "7.69 each", amount: "500" },
            { price: "6.99 each", amount: "1000" },
          ],
        },
      },
      {
        name: "Sand Blast Polish",
        sizes: {
          "1inch": [
            { price: "7.29 each", amount: "50" },
            { price: "5.19 each", amount: "100" },
            { price: "4.49 each", amount: "250" },
            { price: "3.49 each", amount: "500" },
            { price: "3.09 each", amount: "1000" },
          ],

          "1.5inch": [
            { price: "7.69 each", amount: "50" },
            { price: "5.59 each", amount: "100" },
            { price: "4.79 each", amount: "250" },
            { price: "4.19 each", amount: "500" },
            { price: "3.49 each", amount: "1000" },
          ],

          "2inch": [
            { price: "8.69 each", amount: "50" },
            { price: "6.59 each", amount: "100" },
            { price: "5.69 each", amount: "250" },
            { price: "5.19 each", amount: "500" },
            { price: "4.39 each", amount: "1000" },
          ],

          "2.5inch": [
            { price: "9.99 each", amount: "50" },
            { price: "7.89 each", amount: "100" },
            { price: "6.99 each", amount: "250" },
            { price: "6.49 each", amount: "500" },
            { price: "5.69 each", amount: "1000" },
          ],

          "3inch": [
            { price: "11.19 each", amount: "50" },
            { price: "9.09 each", amount: "100" },
            { price: "8.29 each", amount: "250" },
            { price: "7.69 each", amount: "500" },
            { price: "6.99 each", amount: "1000" },
          ],
        },
      },
      {
        name: "Hard Enamel",
        sizes: {
          "1inch": [
            { price: "7.59 each", amount: "50" },
            { price: "5.49 each", amount: "100" },
            { price: "4.79 each", amount: "250" },
            { price: "3.89 each", amount: "500" },
            { price: "3.39 each", amount: "1000" },
          ],

          "1.5inch": [
            { price: "8.09 each", amount: "50" },
            { price: "5.99 each", amount: "100" },
            { price: "5.09 each", amount: "250" },
            { price: "4.59 each", amount: "500" },
            { price: "3.79 each", amount: "1000" },
          ],

          "2inch": [
            { price: "9.09 each", amount: "50" },
            { price: "6.99 each", amount: "100" },
            { price: "6.09 each", amount: "250" },
            { price: "5.59 each", amount: "500" },
            { price: "4.79 each", amount: "1000" },
          ],

          "2.5inch": [
            { price: "10.29 each", amount: "50" },
            { price: "8.19 each", amount: "100" },
            { price: "7.39 each", amount: "250" },
            { price: "6.79 each", amount: "500" },
            { price: "6.09 each", amount: "1000" },
          ],

          "3inch": [
            { price: "11.59 each", amount: "50" },
            { price: "9.49 each", amount: "100" },
            { price: "8.59 each", amount: "250" },
            { price: "8.09 each", amount: "500" },
            { price: "7.29 each", amount: "1000" },
          ],
        },
      },
      {
        name: "Soft Enamel",
        sizes: {
          "1inch": [
            { price: "7.29 each", amount: "50" },
            { price: "5.19 each", amount: "100" },
            { price: "4.49 each", amount: "250" },
            { price: "3.49 each", amount: "500" },
            { price: "3.09 each", amount: "1000" },
          ],

          "1.5inch": [
            { price: "7.69 each", amount: "50" },
            { price: "5.59 each", amount: "100" },
            { price: "4.79 each", amount: "250" },
            { price: "4.19 each", amount: "500" },
            { price: "3.49 each", amount: "1000" },
          ],

          "2inch": [
            { price: "8.69 each", amount: "50" },
            { price: "6.59 each", amount: "100" },
            { price: "5.69 each", amount: "250" },
            { price: "5.19 each", amount: "500" },
            { price: "4.39 each", amount: "1000" },
          ],

          "2.5inch": [
            { price: "9.99 each", amount: "50" },
            { price: "7.89 each", amount: "100" },
            { price: "6.99 each", amount: "250" },
            { price: "6.49 each", amount: "500" },
            { price: "5.69 each", amount: "1000" },
          ],

          "3inch": [
            { price: "11.19 each", amount: "50" },
            { price: "9.09 each", amount: "100" },
            { price: "8.29 each", amount: "250" },
            { price: "7.69 each", amount: "500" },
            { price: "6.99 each", amount: "1000" },
          ],
        },
      },
      {
        name: "Custom PVC Key Chain",
        sizes: {
          "1inch": [
            { price: "7.29 each", amount: "50" },
            { price: "5.19 each", amount: "100" },
            { price: "4.49 each", amount: "250" },
            { price: "3.49 each", amount: "500" },
            { price: "3.09 each", amount: "1000" },
          ],

          "1.5inch": [
            { price: "7.69 each", amount: "50" },
            { price: "5.59 each", amount: "100" },
            { price: "4.79 each", amount: "250" },
            { price: "4.19 each", amount: "500" },
            { price: "3.49 each", amount: "1000" },
          ],

          "2inch": [
            { price: "8.69 each", amount: "50" },
            { price: "6.59 each", amount: "100" },
            { price: "5.69 each", amount: "250" },
            { price: "5.19 each", amount: "500" },
            { price: "4.39 each", amount: "1000" },
          ],

          "2.5inch": [
            { price: "9.99 each", amount: "50" },
            { price: "7.89 each", amount: "100" },
            { price: "6.99 each", amount: "250" },
            { price: "6.49 each", amount: "500" },
            { price: "5.69 each", amount: "1000" },
          ],

          "3inch": [
            { price: "11.19 each", amount: "50" },
            { price: "9.09 each", amount: "100" },
            { price: "8.29 each", amount: "250" },
            { price: "7.69 each", amount: "500" },
            { price: "6.99 each", amount: "1000" },
          ],
        },
      },
    ],
  },
  {
    name: "Medals",
    value: [
      {
        name: "Enamel",
        sizes: {
          "1.5inch": [
            { price: "11.89 each", amount: "50" },
            { price: "7.09 each", amount: "100" },
            { price: "6.39 each", amount: "250" },
            { price: "5.59 each", amount: "500" },
            { price: "4.89 each", amount: "1000" },
          ],

          "2inch": [
            { price: "13.29 each", amount: "50" },
            { price: "7.79 each", amount: "100" },
            { price: "7.09 each", amount: "250" },
            { price: "6.29 each", amount: "500" },
            { price: "5.59 each", amount: "1000" },
          ],

          "2.5inch": [
            { price: "13.99 each", amount: "50" },
            { price: "8.39 each", amount: "100" },
            { price: "7.69 each", amount: "250" },
            { price: "7.09 each", amount: "500" },
            { price: "6.39 each", amount: "1000" },
          ],

          "3inch": [
            { price: "14.69 each", amount: "50" },
            { price: "9.19 each", amount: "100" },
            { price: "8.39 each", amount: "250" },
            { price: "7.69 each", amount: "500" },
            { price: "7.09 each", amount: "1000" },
          ],
        },
      },
      {
        name: "Die Struck",
        sizes: {
          "1.5inch": [
            { price: "11.89 each", amount: "50" },
            { price: "7.09 each", amount: "100" },
            { price: "6.39 each", amount: "250" },
            { price: "5.59 each", amount: "500" },
            { price: "4.89 each", amount: "1000" },
          ],

          "2inch": [
            { price: "13.29 each", amount: "50" },
            { price: "7.79 each", amount: "100" },
            { price: "7.09 each", amount: "250" },
            { price: "6.29 each", amount: "500" },
            { price: "5.59 each", amount: "1000" },
          ],

          "2.5inch": [
            { price: "13.99 each", amount: "50" },
            { price: "8.39 each", amount: "100" },
            { price: "7.69 each", amount: "250" },
            { price: "7.09 each", amount: "500" },
            { price: "6.39 each", amount: "1000" },
          ],

          "3inch": [
            { price: "14.69 each", amount: "50" },
            { price: "9.19 each", amount: "100" },
            { price: "8.39 each", amount: "250" },
            { price: "7.69 each", amount: "500" },
            { price: "7.09 each", amount: "1000" },
          ],
        },
      },
      {
        name: "Rush Printed",
        sizes: {
          "2.5inch flower shaped": [
            { price: "13.99 each", amount: "50" },
            { price: "8.39 each", amount: "100" },
            { price: "7.69 each", amount: "250" },
            { price: "7.09 each", amount: "500" },
            { price: "6.39 each", amount: "1000" },
          ],

          "3inch circle shaped": [
            { price: "14.69 each", amount: "50" },
            { price: "9.19 each", amount: "100" },
            { price: "8.39 each", amount: "250" },
            { price: "7.69 each", amount: "500" },
            { price: "7.09 each", amount: "1000" },
          ],
        },
      },
    ],
  },
  {
    name: "Magnets",
    value: [
      {
        name: "$4x4 Magnets",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
    ],
  },
  {
    name: "Wall Arts",
    value: [
      {
        name: "Vinyl Prints",
        sizes: {
          "8x8": [
            { price: "50 each", amount: "1" },
            { price: "45 each", amount: "2" },
            { price: "40 each", amount: "3" },
          ],
          "16x16": [
            { price: "68 each", amount: "1" },
            { price: "66 each", amount: "2" },
            { price: "64 each", amount: "3" },
            { price: "62 each", amount: "4" },
            { price: "60 each", amount: "5" },
          ],
          "24x24": [
            { price: "120 each", amount: "1" },
            { price: "115 each", amount: "2" },
            { price: "110 each", amount: "3" },
            { price: "105 each", amount: "4" },
            { price: "100 each", amount: "5" },
          ],
        },
      },
    ],
  },
];
export default printProducts;


export const newProducts = [
  {
    name: "Bags",
    value: [
      {
        name: "Herschel Classic",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
      {
        name: "Computer Messenger Bag (Tranzip)",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
      {
        name: "Student Computer Backpack (JanSport)",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
    ],
  },
  {
    name: "Drawstring Bags",
    value: [
      {
        name: "Sports Pack",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
      {
        name: "Sports Pack With Front Zipper",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
    ],
  },
  {
    name: "Tote Bags",
    value: [
      {
        name: "Shopper Tote Bag",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
      {
        name: "Hudson Cotton Tote",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
    ],
  },
  {
    name: "Sunglasses",
    value: [
      {
        name: "Malibu Sunglasses",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
      {
        name: "Two-Tone Malibu Sunglasses",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
    ],
  },
  {
    name: "Hats",
    value: [
      {
        name: "Knit Beanie",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
      {
        name: "Elite Cap",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
      {
        name: "Cotton Twill Mesh Cap",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
      {
        name: "Cotton Twill Visor",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
    ],
  },
  {
    name: "Socks",
    value: [
      {
        name: "Athletic Crew",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
      {
        name: "Cotton Crew",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
      {
        name: "Wool Crew",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
    ],
  },
  {
    name: "Sweatshirts",
    value: [
      {
        name: "Standard Hoodie (Independent Trading Co. SS4500)",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
      {
        name: "Asgard Eco Knit Jacket",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
      {
        name: "LAVAR Eco Knit Hoodie",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
      {
        name: "Longsleeve Standard (Bella+Canvas 3901)",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
    ],
  },
  {
    name: "T-Shirts",
    value: [
      {
        name: "Hanes 100% Cotton",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
      {
        name: "Standard (Bella Canvas 3413)",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
      {
        name: "Gildan Dryblend",
        sizes: {
          "4x4": [
            { price: "9.69 each", amount: "3" },
            { price: "9.39 each", amount: "6" },
            { price: "9.09 each", amount: "9" },
            { price: "8.69 each", amount: "12" },
            { price: "8.39 each", amount: "15" },
          ],
        },
      },
    ],
  },
];