import { useMediaQuery } from "@chakra-ui/media-query";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Image,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { AiOutlineArrowLeft, AiOutlinePlus } from "react-icons/ai";
import { Link, useLocation, useParams } from "react-router-dom";
import data from "../../data";
import { Spinner } from "../../design-system";
import Layout from "../../design-system/Layout/Layout";
import { getWithExpiry, setWithExpiry } from "../../utils/store";
import { Print } from "./style";

const productsData = data.printProducts;

const PrintPage = () => {
  const { state } = useLocation();
  const params = useParams();

  const [products, setProducts] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productSizes, setProductSizes] = useState([]);
  const [productAmounts, setProductAmounts] = useState([]);

  const [nft, setNft] = useState({});
  const [minted, setMinted] = useState(false);
  const [art, setArt] = useState({});
  const [loading, setLoading] = useState(true);

  const [print, setPrint] = useState({
    product_type: "",
    size: "",
    price: 0,
    amount: 0,
    product: "",
  });

  const [cartItems, setCartItems] = useState([]);
  const chakraToast = useToast();

  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");

  useEffect(() => {
    setCartItems(getWithExpiry("cartItems") || []);
    setProducts(productsData.map((each) => each.name));
  }, []);

  const getNft = async (id) => {
    try {
      const res = await fetch(`https://api.rarible.org/v0.1/items/${id}`);
      const data = await res.json();
      setNft(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleProductSelect = ({ target: { value } }) => {
    const selectedProduct = productsData.find(
      (each) => each.name === value
    ).value;

    setProductTypes(selectedProduct.map((each) => each.name));
    setPrint({ ...print, product: value });
  };

  const handleTypeSelect = ({ target: { value } }) => {
    const selectedProduct = productsData.find(
      (each) => each.name === print.product
    ).value;
    const selectedType = selectedProduct.find((each) => (each.name = value));

    setProductSizes(Object.keys(selectedType.sizes));
    setPrint({ ...print, product_type: value });
  };

  const handleSizeSelect = ({ target: { value } }) => {
    const selectedType = productsData
      .find((each) => each.name === print.product)
      .value.find((each) => (each.name = value));

    setProductAmounts(selectedType.sizes[value]);
    setPrint({ ...print, size: value });
  };

  const handleAmountSelect = ({ target: { value } }) => {
    value = JSON.parse(value);
    const truePrice = Number(value.price.split(" ")[0] * value.amount).toFixed(
      2
    );
    setPrint({ ...print, amount: value.amount, price: truePrice });
  };

  const addToCart = () => {
    let tempProduct;
    let data = minted
      ? {
          id: art.id,
          image: art.meta.tokenUri.gateway,
          name: art.name,
          contract: art.id,
          price: print.price,
          size: print.size,
          type: print.type,
          amount: print.amount,
          product: print.product,
          product_type: print.product_type,
        }
      : {
          id: nft.id,
          image: nft.meta.content[0].url,
          name: nft.meta.name,
          contract: nft.id,
          price: print.price,
          size: print.size,
          type: print.type,
          amount: print.amount,
          product: print.product,
          product_type: print.product_type,
        };

    const itemIndex = cartItems.findIndex(
      (item) => data.id === item.id && item.size === data.size
    );

    if (itemIndex >= 0 && cartItems[itemIndex].size === data.size) {
      const cartItem = cartItems.find(
        (item) => item.name === data.name && item.size === data.size
      );
      const index = cartItems.indexOf(cartItem);

      cartItems[index].cartQuantity += 1;
      setWithExpiry("cartItems", [...cartItems]);
    } else {
      tempProduct = { ...data, cartQuantity: 1 };
      setCartItems((prevCartItems) => [...prevCartItems, tempProduct]);
      

      setWithExpiry("cartItems", [...cartItems, tempProduct]);
    }
    chakraToast({
      position: "top",
      description: `Added ${minted ? art.name : nft.meta.name} to cart`,
      status: "success",
      duration: "3000",
    });
    // toast(<CartToast text={minted ? art.name : nft.meta.name} />);
  };
  useEffect(() => {
    if (state?.data) {
      setMinted(true);
      setArt(state.data);
      setLoading(false);
    } else {
      setMinted(false);
      getNft(params.id);
    }
  }, [params, state]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Layout>
          <Print>
            <Container
              maxW="container.xl"
              marginTop={isNotSmallerScreen ? "5%" : "15%"}
              paddingBottom="20px"
            >
              <Toaster
                position={isNotSmallerScreen ? "top-center" : "bottom-center"}
              />
              <Flex direction="row" marginBottom="20px" color="#0c0c0c">
                <AiOutlineArrowLeft size={25} />
                <Link to="/profile" className="link">
                  Back
                </Link>
              </Flex>

              <Grid
                templateColumns={isNotSmallerScreen ? "" : "repeat(1, 1fr)"}
                // gridTemplateColumns="repeat(3, 1fr)"
                gap={isNotSmallerScreen ? 30 : 20}
                display={isNotSmallerScreen && "flex"}
              >
                <Box
                  display={"flex"}
                  h={"100%"}
                  width={isNotSmallerScreen && "70%"}
                >
                  <Box width={"70%"}>
                    {art.internal ? (
                      <Box
                        className="animate"
                        style={{
                          marginTop: "30px",
                          border: "10px solid #fff",
                        }}
                      >
                        <video
                          autoPlay
                          loop
                          height="auto"
                          src={art?.meta?.tokenUri?.gateway}
                        >
                          Internal
                        </video>
                      </Box>
                    ) : (
                      <Image
                        src={
                          minted
                            ? art.meta.tokenUri.gateway
                            : nft.meta.content[0].url
                        }
                        alt={minted ? art.name : nft.meta.content[0].url}
                        h="auto"
                        border={
                          isNotSmallerScreen
                            ? "20px solid #fff"
                            : "10px solid #fff"
                        }
                        borderRadius="10px"
                        boxShadow="lg"
                        className="animate"
                        objectFit="cover"
                        mt={10}
                      />
                    )}
                  </Box>
                  <Box width={"30%"} maxH={"50%"}>
                    {[0, 1, 2].map((image, i) => {
                      if (art.internal) {
                        return (
                          <video
                            key={i}
                            style={{
                              margin: "10px",
                              objectFit: "cover",
                              borderRadius: "10px",
                              height: "25%",
                            }}
                            src={art?.meta?.tokenUri?.gateway}
                          >
                            Internal
                          </video>
                        );
                      } else
                        return (
                          <Image
                            key={i}
                            _hover={{ cursor: "pointer" }}
                            src={
                              minted
                                ? art.meta.tokenUri.gateway
                                : nft.meta.content[0].url
                            }
                            alt={minted ? art.name : nft.meta.content[0].url}
                            border={
                              isNotSmallerScreen
                                ? "10px solid #fff"
                                : "5px solid #fff"
                            }
                            borderRadius="10px"
                            boxShadow="lg"
                            objectFit="cover"
                            mt={3}
                            h={"25%"}
                          />
                        );
                    })}
                  </Box>
                </Box>

                <Box width={isNotSmallerScreen && "30%"}>
                  <Text
                    fontSize="3xl"
                    fontWeight="700"
                    color="#0c0c0c"
                    style={{ textTransform: "uppercase" }}
                  >
                    {minted ? art.name : nft.meta.name}
                  </Text>

                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    marginTop="10px"
                    marginBottom="10px"
                    color="#0c0c0c"
                  >
                    Turn your NFT into a beautiful physical art frame. The
                    smooth texture intensifies the image's natural look and
                    feel.
                  </Text>

                  <Grid gap={"10px"}>
                    <Text
                      fontSize="18px"
                      fontWeight="500"
                      mt={1}
                      color="#0c0c0c"
                    >
                      Product
                    </Text>
                    <Select
                      placeholder="Select Product"
                      onChange={handleProductSelect}
                    >
                      {products.map((data, i) => (
                        <option
                          fontSize={isNotSmallerScreen ? "md" : "sm"}
                          value={data}
                          key={i}
                        >
                          {data}
                        </option>
                      ))}
                    </Select>
                    <Text
                      fontSize="18px"
                      fontWeight="500"
                      mt={1}
                      color="#0c0c0c"
                    >
                      Type
                    </Text>
                    <Select
                      placeholder="Select Type"
                      onChange={handleTypeSelect}
                      disabled={!print.product}
                    >
                      {productTypes.map((data, i) => (
                        <option
                          fontSize={isNotSmallerScreen ? "md" : "sm"}
                          value={data}
                          key={i}
                        >
                          {data}
                        </option>
                      ))}
                    </Select>
                    <Text
                      fontSize="18px"
                      fontWeight="500"
                      mt={1}
                      color="#0c0c0c"
                    >
                      Size
                    </Text>
                    <Select
                      placeholder="Select Size"
                      onChange={handleSizeSelect}
                      disabled={!print.product_type}
                    >
                      {productSizes.map((data, i) => (
                        <option
                          fontSize={isNotSmallerScreen ? "md" : "sm"}
                          value={data}
                          key={i}
                        >
                          {data}
                        </option>
                      ))}
                    </Select>
                    <Text
                      fontSize="18px"
                      fontWeight="500"
                      mt={1}
                      color="#0c0c0c"
                    >
                      Amount
                    </Text>
                    <Select
                      placeholder="Select Amount"
                      onChange={handleAmountSelect}
                      disabled={!print.size}
                    >
                      {productAmounts.map((data, i) => (
                        <option
                          fontSize={isNotSmallerScreen ? "md" : "sm"}
                          value={JSON.stringify(data)}
                          key={i}
                        >
                          {/* {data} {data.type} */}
                          {data.amount} | ${data.price}
                        </option>
                      ))}
                    </Select>
                  </Grid>
                  <Grid marginTop={"10"}></Grid>

                  <Button
                    rightIcon={<AiOutlinePlus color="#000" />}
                    onClick={addToCart}
                    marginTop="20px"
                    fontSize={isNotSmallerScreen ? "md" : "sm"}
                    w="100%"
                    // bg="#fecb4c"
                    borderRadius="md"
                    color="#000"
                    fontWeight="500"
                    border={"1px solid #53bafc"}
                    bg="none"
                    disabled={!print.amount && !print.price}
                    _hover={{
                      opacity: 0.6,
                      transition: "0.5s ease-in-out",
                      cursor: "pointer",
                    }}
                    style={{ transition: "all 0.5s ease-in-out" }}
                  >
                    ADD TO CART
                  </Button>
                </Box>
              </Grid>
            </Container>
          </Print>
        </Layout>
      )}
    </>
  );
};

export default PrintPage;
