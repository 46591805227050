import {
  Button,
  Fade,
  Flex,
  Input,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

const RedeemCreditModal = ({ data }) => {
  const { modals, setModals, redeemCredits } = data;
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");
  const [refId, setRefId] = useState("");

  return (
    <Fade in={modals.redeem}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100vh"
        zIndex={10}
        gap={5}
        bg="rgba(0,0,0,0.2)"
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={isNotSmallerScreen ? "45%" : "80%"}
          bg="#fff"
          p="10px"
          borderRadius="3px"
          _hover={{
            border: "1px solid #ddd",
          }}
        >
          <Flex width="100%" justifyContent="flex-end">
            <Flex
              bg="#424242"
              color="#fff"
              width="25px"
              height="25px"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              cursor="pointer"
              transition="0.3s"
              _hover={{
                transform: "scale(0.99)",
              }}
              onClick={() => setModals({ ...modals, redeem: false })}
            >
              <AiOutlineClose size={15} />
            </Flex>
          </Flex>
          <Flex
            width="80%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize="24px" fontWeight="500">
              Redeem Credits
            </Text>
            <Text fontSize="16px">Enter your credits reference ID</Text>
            <Input
              placeholder="Enter a Redeem code"
              variant="outline"
              padding="25px 10px"
              margin="10px 0px"
              onChange={(e) => setRefId(e.target.value)}
            />
            <Button
              colorScheme="linkedin"
              m="10px 0px"
              p="10px 20px"
              isLoading={modals.loadingRedeem}
              onClick={() => {
                if (!refId) return;
                redeemCredits(refId);
              }}
            >
              Redeem Credits
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Fade>
  );
};

export default RedeemCreditModal;
