import { Box, Container } from "@chakra-ui/react";
import { useEffect } from "react";
import Layout from "../../design-system/Layout/Layout";

const Privacy = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    // const js = document.getElementsByTagName(script)[0];
    if (document.getElementById("termly-jsssskdd")) return;
    // js.parentNode.insertBefore(script, js);
    script.id = "termly-jsssskdd";
    document
      .getElementById("a7eae8c3-712b-4689-8cbe-6cbc3092fc501")
      .appendChild(script);
  });
  return (
    <Layout>
      <Box minH={"70vh"} className="policy">
        <Container maxW={"container.xl"}>
          <div>
            <div
              name="termly-embed"
              data-id="a7eae8c3-712b-4689-8cbe-6cbc3092fc50"
              id="a7eae8c3-712b-4689-8cbe-6cbc3092fc501"
              data-type="iframe"
            ></div>
          </div>
        </Container>
      </Box>
    </Layout>
  );
};

export default Privacy;
