import swal from "@sweetalert/with-react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { authLoginAsync, logout } from "../redux/slices/auth.slice";

const usePhantomWallet = (HandleError) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getProvider = () => {
    if ("phantom" in window) {
      const provider = window.phantom?.solana;
      if (provider?.isPhantom) {
        provider.on("disconnect", () => {
          dispatch(logout());
        });
        return provider;
      }
    }
    return swal("Please install Phantom or Metamask wallet!");
  };

  const connectUsingPhantom = async () => {
    const provider = getProvider();
    let address;
    try {
      const resp = await provider.connect();
      address = resp.publicKey.toString();
      const blockchain = "solana";
      dispatch(authLoginAsync({ address: address.toLowerCase(), blockchain }));
    } catch (err) {
      HandleError(err, navigate);
    }
  };

  return {
    connectUsingPhantom,
  };
};

export default usePhantomWallet;
