import swal from "@sweetalert/with-react";
import data from "../data";
import { useAppDispatch } from "../redux/hooks";
import { authLoginAsync } from "../redux/slices/auth.slice";
const chainIds = data.chainIds;

const { REACT_APP_CHAIN_CODE } = process.env;

const useMetaMask = (HandleError) => {
  const dispatch = useAppDispatch();

  const connectUsingMetaMask = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      return swal("Please install metamask wallet!");
    }

    const chainId = await ethereum.request({ method: "eth_chainId" });
    if (String(chainId) !== REACT_APP_CHAIN_CODE) {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: REACT_APP_CHAIN_CODE }],
      });
    }

    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });
    const blockchain = chainIds[chainId.split("")[2]];

    const address = accounts[0];

    dispatch(authLoginAsync({ address: address.toLowerCase(), blockchain }));
  };

  return { connectUsingMetaMask };
};

export default useMetaMask;
