/* eslint-disable react-hooks/exhaustive-deps */
import { useMediaQuery } from "@chakra-ui/media-query";

import {
  Box,
  Container,
  Flex,
  IconButton,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import {
  BsCollectionFill,
  BsFillBrushFill,
  BsFillCheckSquareFill,
  BsTwitter,
} from "react-icons/bs";
import { FaDiscord } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import { MdFileCopy, MdPermMedia } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../design-system";
import Layout from "../../design-system/Layout/Layout";
import getContract from "../../helpers/contract";
import { useAppSelector } from "../../redux/hooks";
import { useFetchUserArticlesQuery } from "../../redux/queries/article";
import { useFetchUserMintsQuery } from "../../redux/queries/mint";
import { useFetchUserOrdersQuery } from "../../redux/queries/orders";
import {
  selectBlockchain,
  selectCurrentAccount,
  selectUser,
} from "../../redux/slices/auth.slice";
import Collectibles from "./Collectibles/Collectibles";
import MyArts from "./Collectibles/MyArts";
import EmptyState from "./EmptyState";
import History from "./History/History";
import Posts from "./Posts/Posts";
import { Header } from "./style";

const tab = [
  {
    name: "My Art",
    icon: <BsFillBrushFill size={20} />,
  },
  {
    name: "Collectibles",
    icon: <BsCollectionFill size={20} />,
  },
  {
    name: "Media",
    icon: <MdPermMedia size={20} />,
  },
  {
    name: "History",
    icon: <BsFillCheckSquareFill size={20} />,
  },
];

const Shop = () => {
  const navigate = useNavigate();
  const [nfts, setNfts] = useState([]);
  const [myArts, setMyArts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");
  const currentAccount = useAppSelector(selectCurrentAccount);
  const blockchain = useAppSelector(selectBlockchain);
  const user = useAppSelector(selectUser);

  const { data: userMints, isSuccess: isSuccessUserMints } =
    useFetchUserMintsQuery();
  const { data: userOrders, isSuccess: isSuccessUserOrders } =
    useFetchUserOrdersQuery();
  const { data: userArticles, isSuccess: isSuccessUserArticles } =
    useFetchUserArticlesQuery();
  const [posts, setPosts] = useState([]);
  const [prints, setPrints] = useState([]);

  const truncateString = (str, num = 5) => {
    if (str.length > num) {
      return str.slice(0, num) + "..." + str.slice(str.length - 3, str.length);
    } else {
      return str;
    }
  };

  const handleOrders = (orders) => {
    const prints = [];
    for (const order of orders) {
      for (const print of order.prints) {
        prints.push(print);
      }
    }
    return prints;
  };

  const getNfts = async () => {
    // Get NFTs
    try {
      await axios
        .get(
          `https://api.rarible.org/v0.1/items/byOwner/?owner=${blockchain.toUpperCase()}:${currentAccount}`
        )
        .then((response) => {
          setNfts(response.data.items);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }

    // Get My ARTs
    try {
      const contract = getContract();
      const minted = await contract.getMyMintedNFTs();

      const mintedNFTs = await Promise.all(
        minted.map(async (i) => {
          const tokenURI = await contract.tokenURI(i.tokenId);
          let meta;
          try {
            meta = await axios.get(`${tokenURI}`);
          } catch (error) {
            console.log({ error });
          }
          let item = {};
          if (meta) {
            meta = meta.data;

            item = {
              tokenId: i.tokenId.toNumber(),
              owner: i.owner,
              meta: { tokenUri: { gateway: meta.image } },
              image: meta.image,
              name: meta.name,
              description: meta.description,
            };
          }
          return item;
        })
      );

      // Get Mints
      if (isSuccessUserMints) {
        const all = [...userMints, ...mintedNFTs];
        setMyArts(all);
      }
      // Get Order History
      if (isSuccessUserOrders) {
        const prints = handleOrders(userOrders);
        setPrints(prints);
      }

      // Get Articles/Posts
      if (isSuccessUserArticles) {
        setPosts(userArticles);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCopy = () => {
    toast.success("Copied");
  };

  useEffect(() => {
    if (currentAccount) {
      getNfts();
    } else {
      navigate("/");
    }
  }, [currentAccount]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Layout auth={true}>
          <Toaster />
          <Header />
          <Container maxW="container.xl">
            <Flex
              justifyContent="center"
              flexDirection={!isNotSmallerScreen && "column"}
            >
              <Box w={isNotSmallerScreen ? "25%" : "100%"}>
                <Flex flexDirection="column" alignItems="center">
                  <Image
                    marginTop={isNotSmallerScreen ? "-70px" : "-50px"}
                    style={{
                      marginTop: "-70px",
                    }}
                    backgroundColor="#060606"
                    borderRadius="100%"
                    border="10px solid #fff"
                    src={`https://robohash.org/${currentAccount}`}
                    alt="Robo"
                    w={isNotSmallerScreen ? "150px" : "150px"}
                    h={isNotSmallerScreen ? "150px" : "150px"}
                    mt={5}
                  />

                  <Box>
                    <Text
                      fontSize="24px"
                      fontWeight="600"
                      textTransform="capitalize"
                      color="#000000"
                      textAlign="center"
                    >
                      {user.username}
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        color="#767575"
                        fontSize="14px"
                        value={currentAccount}
                      >
                        {truncateString(currentAccount)}
                      </Text>
                      <CopyToClipboard text={currentAccount}>
                        <IconButton
                          icon={<MdFileCopy color="#479DD4" size={18} />}
                          onClick={handleCopy}
                          bg="none"
                        />
                      </CopyToClipboard>
                    </div>
                  </Box>
                  <Flex w="40%" fontSize="20px" justifyContent="space-around">
                    {user.twitter && (
                      <a
                        target={"_blank"}
                        rel="noopener noreferrer"
                        href={`https://www.twitter.com/${user.twitter}`}
                      >
                        <BsTwitter color="#55ACEE" />
                      </a>
                    )}
                    {user.discord && (
                      <a
                        target={"_blank"}
                        rel="noopener noreferrer"
                        href={`https://www.twitter.com/${user.twitter}`}
                      >
                        <FaDiscord color="#5865F2" />
                      </a>
                    )}
                    {user.email && (
                      <a href={`mailto:${user.email}`}>
                        <HiMail color="#009FD8" />
                      </a>
                    )}
                  </Flex>
                </Flex>
              </Box>
              <Box w={isNotSmallerScreen ? "75%" : "100%"}>
                <Tabs isFitted variant="unstyled" mt="10px">
                  <TabList
                    overflowX={!isNotSmallerScreen && "scroll"}
                    p={!isNotSmallerScreen && "10px 0px"}
                  >
                    {tab.map((item, index) => (
                      <Tab
                        key={index}
                        color="#767575"
                        _selected={{
                          border: "none",
                          boxShadow: "none",
                          background: "#479DD4",
                          borderRadius: "8px",
                          color: "#fff",
                        }}
                      >
                        <Flex
                          display="flex"
                          alignItems="center"
                          w={!isNotSmallerScreen && "100%"}
                        >
                          {item.icon}
                          <Text
                            ml={2}
                            whiteSpace={!isNotSmallerScreen && "nowrap"}
                          >
                            {item.name}
                          </Text>
                        </Flex>
                      </Tab>
                    ))}
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      {myArts?.length <= 0 ? (
                        <EmptyState text="No minted Art found" />
                      ) : (
                        <MyArts
                          nfts={myArts}
                          isNotSmallerScreen={isNotSmallerScreen}
                        />
                      )}
                    </TabPanel>
                    <TabPanel>
                      {nfts?.length <= 0 ? (
                        <EmptyState text="No collectibles found in your Wallet" />
                      ) : (
                        <Collectibles
                          nfts={nfts}
                          isNotSmallerScreen={isNotSmallerScreen}
                        />
                      )}
                    </TabPanel>
                    <TabPanel>
                      {posts.length > 0 ? (
                        <Posts
                          posts={posts}
                          isNotSmallerScreen={isNotSmallerScreen}
                        />
                      ) : (
                        <EmptyState text="No Posts" url="post" />
                      )}
                    </TabPanel>
                    <TabPanel>
                      {prints.length > 0 ? (
                        <History
                          prints={prints}
                          isNotSmallerScreen={isNotSmallerScreen}
                        />
                      ) : (
                        <EmptyState text="No Prints" />
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </Flex>
          </Container>
        </Layout>
      )}
    </>
  );
};

export default Shop;
