import { Box, Container, Image, Text } from "@chakra-ui/react";
import checkoutImg from "../../design-system/Assets/how/checkoutcoin.png";
import connectImg from "../../design-system/Assets/how/connect.png";
import pickImg from "../../design-system/Assets/how/pick.png";
import proceedImg from "../../design-system/Assets/how/proceed.png";
import shopImg from "../../design-system/Assets/how/shop.png";
import sizeImg from "../../design-system/Assets/how/size.png";
import bg from "../../design-system/Assets/shop-bg.png";
import Layout from "../../design-system/Layout/Layout";
import Step from "./components/Step";

const steps = [
  {
    id: "1",
    title: "Connect Your Wallet",
    body: "Connect your wallet by clicking the connect button at the top right of the home page.",
    imgSrc: connectImg,
  },
  {
    id: "2",
    title: "Go To Profile",
    body: "Visit your profile by clicking your avatar, Then selecting profile.",
    imgSrc: shopImg,
  },
  {
    id: "3",
    title: "Pick NFT",
    body: "Select the NFT you'd like to have printed from your wallet's content.",
    imgSrc: pickImg,
  },
  {
    id: "4",
    title: "Select Product",
    body: "Select the print type you want from our selection",
    imgSrc: sizeImg,
  },
  {
    id: "5",
    title: "Add NFT To Cart",
    body: "Select the size you want to print and add it to your cart, then head to checkout, or add more NFTs to your cart before proceeding to checkout.",
    imgSrc: proceedImg,
  },
  {
    id: "6",
    title: "Checkout",
    body: " Visit Checkout and proceed to order your NFT. Payments can be made using your card or paying with cryptocurrency, make payment with the method you prefer.",
    imgSrc: checkoutImg,
  },
  {
    id: "7",
    title: "Display",
    body: " In a few days, get your high-quality print and display it at home, in your office, or your favorite physical space.",
    imgSrc: bg,
  },
];

const How = () => {
  return (
    <Layout>
      <Container maxW="container.lg" paddingBottom="100px" mt={10}>
        <Text textAlign="left" fontSize="3xl" fontWeight="700">
          How To Print Your First NFT?
        </Text>
        <Text fontSize="medium">
          You bought your first NFT, and now you want to have it physically at
          your home? Yes, it’s possible with NFT PRINT PRO™.
        </Text>

        <Image
          src={bg}
          alt={bg}
          h="30vh"
          w="100%"
          objectFit="cover"
          mt={5}
          mb={5}
        />

        <Text fontSize="medium" lineHeight="30px">
          It's nice to have an image in the form of pixels on your Opensea,
          LooksRare, Foundation, Rarible, or favorite NFT marketplace; it's
          still better to have it physically on the walls of your home. Like a
          real piece of art. The first question one might ask is: “How do I
          print my NFT?"
        </Text>

        <Box mt={5} mb={5}>
          <Text fontSize="3xl" fontWeight="700">
            Steps
          </Text>
          {steps.map((step, index) => (
            <Step
              key={index}
              imgSrc={step.imgSrc}
              title={step.title}
              id={step.id}
              body={step.body}
            />
          ))}
        </Box>
      </Container>
    </Layout>
  );
};

export default How;
