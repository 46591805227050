import React from "react";
import { Box, Center, Spinner, Text } from "@chakra-ui/react";
import { SideCartContainer } from "./style";

const Loader = ({ isOpen, ...props }) => {
  return (
    <SideCartContainer isOpen={isOpen} {...props}>
      <Box>
        <Center>
          <Spinner size="xl" color="blue.500" thickness="5px" />
        </Center>
        <Text color="#fff" textAlign="center" mt={5}>
          Your payment is proccessing. This may take up to 2 minutes. <br />
          Please do not refresh or leave the page
        </Text>
      </Box>
    </SideCartContainer>
  );
};

export default Loader;
