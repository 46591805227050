import { Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import slideI from "../../../design-system/Assets/print-1.png";
import slideII from "../../../design-system/Assets/print-2.png";
import slideIII from "../../../design-system/Assets/print-3.png";
import slideIV from "../../../design-system/Assets/print-4.png";
import { useFetchOrdersQuery } from "../../../redux/queries/orders";
import { Slider } from "../style";

const demoOrders = [
  {
    image: slideI,
    name: "NFT name",
    address: "   0xdferdsd.....er5yrth",
    type: "Magnet",
  },
  {
    image: slideII,
    name: "NFT name",
    address: "0xdferdsd.....er5yrth",
    type: "Vinyl",
  },
  {
    image: slideIII,
    name: "NFT name",
    address: "0xdferdsd.....er5yrth",
    type: "Cap",
  },
  {
    image: slideIV,
    name: "NFT name",
    address: "0xdferdsd.....er5yrth",
    type: "Magnet",
  },
  {
    image: slideIV,
    name: "NFT name",
    address: "0xdferdsd.....er5yrth",
    type: "Sticker",
  },
];

const Slide = () => {
  const [orders, setOrders] = useState(demoOrders);
  const { data, isSuccess } = useFetchOrdersQuery();

  useEffect(() => {
    const toBeShown = [];
    if (isSuccess && data?.length >= 5) {
      data.forEach((printed) => {
        if (!printed.prints[0].name.includes("NFTPRINTPRO NPC")) {
          toBeShown.push(printed.prints[0]);
        }
      });
      setOrders(toBeShown);
    }
  }, [data, isSuccess]);

  return (
    <Slider>
      <Marquee gradient="false" gradientColor={"none"} direction={"right"}>
        {orders.map((data, i) => (
          <div className="slide" key={i}>
            <img src={data.image} alt={data.image} />
            <Text
              align="left"
              size="sm"
              color="#0c0c0c"
              overflow={"hidden"}
              noOfLines={1}
            >
              {data.name}
            </Text>
            <Text size="sm" align="left" color="#767575">
              {data.type}
            </Text>
          </div>
        ))}
      </Marquee>
    </Slider>
  );
};

export default Slide;
