import { useMediaQuery } from "@chakra-ui/media-query";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../design-system/Layout/Layout";
// import toast, { Toaster } from "react-hot-toast";
import { getWithExpiry, setWithExpiry } from "../../utils/store";

export const MerchsCheckout = () => {
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");
  const chakraToast = useToast();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [cartItems, setCartItems] = useState([]);
  const [merch] = useState(state.data);
  const [size, setSize] = useState("");
  const [color, setColor] = useState("");
  if (!merch) {
    navigate("/merchs");
  }

  const addToCart = () => {
    if (!merch) return;

    if (!size && merch.sizes) {
      chakraToast({
        id: "A_T_C",
        position: "top",
        description: `Select size`,
        status: "warning",
        duration: "2000",
      });
      return;
    }
    if (!color && merch.colors) {
      chakraToast({
        id: "A_T_C",
        position: "top",
        description: `Select color`,
        status: "warning",
        duration: "2000",
      });
      return;
    }
    let data = {
      id: merch.id,
      image: merch.image,
      name: merch.text,
      contract: merch.contract,
      price: merch.price,
      size: size,
      color: color,
      amount: merch.amount,
      product_type: "print",
    };

    const exists = cartItems.some(
      (item) => data.id === item.id && data.size === item.size
    );
    if (exists) {
      const index = cartItems.findIndex(
        (item) => data.id === item.id && data.size === item.size
      );
      cartItems[index].cartQuantity += 1;
      setWithExpiry("cartItems", [...cartItems]);
    } else {
      data = { ...data, cartQuantity: 1 };
      setCartItems([...cartItems, data]);
      setWithExpiry("cartItems", [...cartItems, data]);
    }
    !chakraToast.isActive("A_T_C") &&
      chakraToast({
        id: "A_T_C",
        position: "top",
        description: `${data.name} added to cart`,
        status: "success",
        duration: "2000",
      });
  };

  useEffect(() => {
    setCartItems(getWithExpiry("cartItems") || []);
  }, []);
  return (
    <Layout>
      <Container maxW="container.xl" minH="100vh" h={"auto"}>
        <Flex flexDirection="column" alignItems="center" pt="30px">
          <Text
            fontSize="32px"
            lineHeight="48px"
            fontWeight="400"
            p="5px"
            borderBottom="2px solid #C2C2C2"
            mb="15px"
          >
            {merch.text ?? "Checkout"}
          </Text>
        </Flex>
        <Grid
          gridTemplateColumns={isNotSmallerScreen ? "1fr 1fr" : "1fr"}
          justifyContent="center"
          alignItems="flex-start"
          margin="15px"
        >
          <Box margin="0px 10px">
            <Image src={merch.image} alt="" w="100%" borderRadius="10px" />
          </Box>
          <Flex
            flexDirection="column"
            margin="0px auto"
            fontWeight="500"
            w={isNotSmallerScreen && "70%"}
            mt={"10vh"}
            mb={"10vh"}
          >
            <Flex
              justifyContent="space-between"
              fontSize={isNotSmallerScreen ? "20px" : "16px"}
              mb={"5vh"}
            >
              <Text>{merch.longText}</Text>
            </Flex>
            <Flex
              justifyContent="space-between"
              fontSize={isNotSmallerScreen ? "28px" : "24px"}
            >
              <Text>${merch.price}</Text>
            </Flex>
            {merch.sizes && (
              <>
                <Text
                  m={isNotSmallerScreen ? "20px 0px" : "10px 0px"}
                  fontSize="24px"
                >
                  Size
                </Text>
                <Flex justifyContent="flex-start" flexWrap="wrap">
                  {merch.sizes?.map((item, index) => (
                    <Grid
                      key={index}
                      placeItems="center"
                      width="40px"
                      height="40px"
                      m="5px"
                      border="2px solid #CACACA"
                      borderRadius="3px"
                      background={item === size && "#85858550"}
                      transition="0.2s"
                      _hover={{
                        background: "#85858550",
                        cursor: "pointer",
                      }}
                      onClick={() => setSize(item)}
                    >
                      {item}
                    </Grid>
                  ))}
                </Flex>
              </>
            )}
            {merch.colors && (
              <>
                <Text
                  m={isNotSmallerScreen ? "20px 0px" : "10px 0px"}
                  fontSize="24px"
                >
                  Color
                </Text>
                <Flex justifyContent="flex-start" flexWrap="wrap">
                  {merch.colors?.map((item, index) => (
                    <Box
                      key={index}
                      width="40px"
                      height="40px"
                      m="5px"
                      border={item === color && "4px solid #53bafc"}
                      borderRadius="full"
                      background={item}
                      transition="0.2s"
                      _hover={{
                        cursor: "pointer",
                        transform: "scale(0.90)",
                      }}
                      onClick={() => setColor(item)}
                    ></Box>
                  ))}
                </Flex>
              </>
            )}
            <Button colorScheme="linkedin" m="40px 0px" onClick={addToCart}>
              Add to cart
            </Button>
            <Text fontSize="24px">What's in the Box?</Text>
            <Text fontSize="16px" fontWeight="400">
              {merch.whatsInTheBox}
            </Text>
          </Flex>
        </Grid>
      </Container>
    </Layout>
  );
};
