const ethers = require("ethers");

const func = {};

func.reload = () => window.location.reload();

export const isValidEmail = (email) => {
  if (email.match(/^\S+@\S+\.\S+$/)) {
    return true;
  }
  return false;
};

export const listNFT = async (event) => {
  try {
    event.preventDefault();
    const contract = ethers.providers.Web3Provider(window.ethereum);
    const price = ethers.utils.parseUnits("", "ether");
    let listingPrice = await contract.getListingPrice();
    listingPrice = listingPrice.toString();

    let transaction = await contract.listToken(price, {
      value: listingPrice,
    });
    await transaction.wait();
    window.location.replace("/");
  } catch (error) {
    console.log("Error: ", error);
  }
};

export default func;
