import { getWithExpiry } from "../../utils/store";
import {
  CloseIcon,
  Icon,
  SidebarContainer,
  SidebarLink,
  SidebarMenu,
  SidebarRoute,
  SidebarWrapper,
  SideBtnWrap,
} from "./SidebarElements";

const SideBar = ({
  isOpen,
  toggle,
  connectWallet,
  currentAccount,
  disconnectWallet,
}) => {
  const cart = getWithExpiry("cartItems") ? getWithExpiry("cartItems") : [];
  let total = cart.reduce(
    (cartTotal, cartItem) => {
      const { cartQuantity } = cartItem;
      cartTotal.quantity += cartQuantity;
      return cartTotal;
    },
    {
      quantity: 0,
    }
  );
  return (
    <>
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
          <CloseIcon />
        </Icon>
        <SidebarWrapper>
          <SidebarMenu>
            {/* {currentAccount ? (
              <SidebarLink to="/shop" onClick={toggle}>
                Shop
              </SidebarLink>
            ) : (
              " "
            )} */}

            <SidebarLink to="/shop" onClick={toggle}>
              Shop
            </SidebarLink>
            {currentAccount && (
              <SidebarLink to="/profile" onClick={toggle}>
                Profile
              </SidebarLink>
            )}
            <SidebarLink to="/credits" onClick={toggle}>
              Credits
            </SidebarLink>
            <SidebarLink to="/how-to-print-nfts" onClick={toggle}>
              How To Print
            </SidebarLink>
            <SidebarLink to="/mint" onClick={toggle}>
              Mint
            </SidebarLink>
            <SidebarLink to="/cart" onClick={toggle}>
              {total.quantity <= 0 ? "Cart" : <>Cart ({total.quantity})</>}
            </SidebarLink>
          </SidebarMenu>
          {!currentAccount ? (
            <SideBtnWrap>
              <SidebarRoute onClick={connectWallet}>
                Connect wallet
              </SidebarRoute>
            </SideBtnWrap>
          ) : (
            <SideBtnWrap>
              <SidebarRoute onClick={disconnectWallet}>Disconnect</SidebarRoute>
            </SideBtnWrap>
          )}
        </SidebarWrapper>
      </SidebarContainer>
    </>
  );
};

export default SideBar;
