import { Box, Container, Flex, Image, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import Layout from "../../design-system/Layout/Layout";

const Learn = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout>
        <Container maxW="container.xl" marginTop="3%" paddingBottom="50px">
          <Box>
            <Text fontSize="40px" fontWeight="700" color="#0c0c0c">
              NFT RESOURCES
            </Text>
          </Box>
          <hr />
          <Box>
            <Flex justifyContent="start" gap={"24px"}>
              <Box
                mt={8}
                bg="#fff"
                borderRadius="md"
                boxShadow="lg"
                width="30%"
                as={"a"}
                target="_blank"
                rel="noreferrer nopener"
                href="https://tokentag.notion.site/Web3-Builder-s-Toolkit-d305cc8e331048588429d7ea2b8f032e"
              >
                <Flex
                  background={"black"}
                  justifyContent="center"
                  alignItems={"center"}
                  h="80%"
                >
                  <Image
                    src={"./assets/tokentaglogo.png"}
                    h="auto"
                    objectFit="center"
                    w="100%"
                  />
                </Flex>

                <Box p={3}>
                  <Text fontSize="24px" fontWeight="700" color="#0c0c0c">
                    Token Tag
                  </Text>
                </Box>
              </Box>
              <Box
                mt={8}
                bg="#fff"
                borderRadius="md"
                boxShadow="lg"
                width="30%"
                as={"a"}
                target="_blank"
                rel="noreferrer nopener"
                href="https://afengroup.com/courses"
              >
                <Flex
                  background={"black"}
                  justifyContent="center"
                  alignItems={"center"}
                  h="80%"
                >
                  <Image
                    src={"./assets/afenlogo.png"}
                    h="auto"
                    objectFit="center"
                    w="100%"
                  />
                </Flex>
                <Box p={3}>
                  <Text fontSize="24px" fontWeight="700" color="#0c0c0c">
                    Afen Group
                  </Text>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Container>
      </Layout>
    </>
  );
};

export default Learn;
