import { Box, Grid, Image, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import EmptyState from "../EmptyState";
import { Art } from "./style";

const Prints = ({ prints = [], isNotSmallerScreen }) => {
  const printsPerPage = 6;
  const initialPrints = prints?.slice(0, printsPerPage);
  const [printsToDisplay, setPrintsToDisplay] = useState(initialPrints);
  const [pageCount, setPageCount] = useState(
    Math.ceil(printsToDisplay.length / printsPerPage)
  );
  const [search, setSearch] = useState("");

  const handleSearch = ({ target: { value } }) => {
    const validPrints = [];
    setSearch(value);
    prints.forEach((print) => {
      if (print.name.toLowerCase().includes(value)) validPrints.push(print);
    });
    if (validPrints.length > 6) {
      setPageCount(Math.ceil(printsToDisplay.length / printsPerPage));
    }
    setPrintsToDisplay(validPrints);
  };

  const changePage = ({ selected }) => {
    const limit = printsPerPage + selected;
    const first = selected * printsPerPage;
    const second = limit < prints.length - 1 ? limit : prints.length - 1;
    // setDisplayPrints(prints.slice(first, second + selected));
    setPrintsToDisplay(prints.slice(first, second + selected));

    window.scrollTo(0, 0);
  };
  return (
    <Box w="100%" minW="60vw" minH="60vh" padding="5px">
      <Art>
        <header>
          <div className="input">
            <AiOutlineSearch color="#6E7191" size={20} />
            <Input
              placeholder="Search"
              variant="filled"
              padding="25px 10px"
              outline="none"
              background="white"
              onChange={handleSearch}
            />
          </div>
        </header>
        <Box paddingBottom="50px" flexGrow={1}>
          <Grid
            templateColumns={
              isNotSmallerScreen ? "repeat(3,1fr)" : "repeat(1,1fr)"
            }
            gap={6}
          >
            {printsToDisplay.length >= 1
              ? printsToDisplay?.map((data, index) => (
                  <Box
                    maxW="sm"
                    borderRadius="10px"
                    bg="#fff"
                    key={index}
                    boxShadow="lg"
                    _hover={{
                      transform: "scale(0.95)",
                      cursor: "pointer",
                      transition: "all 0.5s ease",
                    }}
                    style={{ transition: "all 0.5s ease-in-out" }}
                  >
                    <Box p={3}>
                      <Image
                        src={data.image}
                        alt={data.image}
                        style={{ borderRadius: "10px" }}
                        height={300}
                        width={"100%"}
                      />
                    </Box>

                    <Box p={3}>
                      <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        lineHeight="tight"
                        textTransform="capitalize"
                        color="#0c0c0c"
                      >
                        Name: {data.name}
                      </Text>
                      <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        lineHeight="tight"
                        textTransform="capitalize"
                        color="#0c0c0c"
                      >
                        Type: {data.type}
                      </Text>
                      <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        lineHeight="tight"
                        textTransform="capitalize"
                        color="#0c0c0c"
                      >
                        Copies: {data.quantity}
                      </Text>
                      <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        lineHeight="tight"
                        textTransform="capitalize"
                        color="#0c0c0c"
                      >
                        Size: {data.size}
                      </Text>
                    </Box>
                  </Box>
                ))
              : null}
          </Grid>
          {!printsToDisplay.length && (
            <EmptyState text={`No order found for "${search}"`} />
          )}
        </Box>

        {pageCount && (
          <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={
              isNotSmallerScreen ? "paginationButtons" : "paginationButtons-m"
            }
            previousLinkClassName={"previousButton"}
            nextLinkClassName={"nextButton"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        )}
      </Art>
    </Box>
  );
};

export default Prints;
