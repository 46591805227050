import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./app/About/About";
import Cart from "./app/Cart/Cart";
import Checkout from "./app/Checkout/Checkout";
import Confirm from "./app/Checkout/Confirm";
import Contact from "./app/Contact/Contact";
import Credits from "./app/Credits/Credits";
import { ErrorPage } from "./app/Error/Error";
import Home from "./app/Home/Home";
import Blog from "./app/Learn/Learn";
import Merchs from "./app/Merchs/Merchs";
import Mint from "./app/Mint/Mint";
import How from "./app/others/How";
import Privacy from "./app/others/Privacy";
import Terms from "./app/others/Terms";
import Post from "./app/Post/Post";
import PrintPage from "./app/PrintPage/PrintPage";
import Shop from "./app/Profile/Profile";
import Settings from "./app/Settings";
import User from "./auth/User";

import { MerchsCheckout } from "./app/Merchs/MerchsCheckout";
import Refund from "./app/others/Refund";
import ViewPost from "./app/Post/View";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="learn" element={<Blog />} />
        <Route path="shop" element={<Merchs />} />
        <Route path="merchs-checkout" element={<MerchsCheckout />} />
        <Route path="mint" element={<Mint />} />
        <Route path="credits" element={<Credits />} />
        <Route path="contact" element={<Contact />} />
        <Route path="cart" element={<Cart />} />
        <Route path="profile" element={<Shop />} />
        <Route path="settings" element={<Settings />} />
        <Route path="about" element={<About />} />
        <Route path="print/:id" element={<PrintPage />} />
        <Route path="print" element={<PrintPage />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="post" element={<Post />} />
        <Route path="view/:id" element={<ViewPost />} />
        <Route path="user" element={<User />} />
        <Route path="terms-of-service" element={<Terms />} />
        <Route path="privacy-policy" element={<Privacy />} />
        <Route path="refund-policy" element={<Refund />} />
        <Route path="how-to-print-nfts" element={<How />} />
        <Route path="confirm/:order" element={<Confirm />} />
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};
