export const merchItem = [
  {
    id: "8291BHS",
    image: "./merchs/shirt1.png",
    text: "T-Shirt",
    longText: "NFT Print Pro Shirt",
    price: "60",
    sizes: ["XS", "S", "L", "XL"],
    whatsInTheBox: "A large customized NFT Print Pro T-Shirt",
    available: true,
  },
  {
    id: "1208BHS",
    image: "./merchs/cases.png",
    text: "Case",
    longText: "Iphone 12 pro case",
    price: "250",
    colors: ["#000", "#C14F52"],
    whatsInTheBox: "Iphone 12 pro case",
  },
  {
    id: "9801BHS",
    image: "./merchs/cap1.png",
    text: "Caps",
    longText: "NFT Print Pro Cap",
    price: "45",
    whatsInTheBox: "A customized NFT Print Pro Cap",
    available: true,
  },
  {
    id: "1208GHS",
    image: "./merchs/shirt2.png",
    text: "Sweat Shirts",
    longText: "NFT Print Pro Sweat Shirt",
    price: "1200",
    sizes: ["XS", "S", "L", "XL", "XXL"],
    colors: ["#000", "#C14F52", "#0026EC", "#3DEC00", "#e7f9c0"],
    whatsInTheBox: "A large customized NFT Print Pro Sweat Shirt",
  },
  {
    id: "1208BKS",
    image: "./merchs/books.png",
    text: "Books",
    longText: "NFT Print Pro Book",
    price: "25",
    colors: ["#000", "#C14F52"],
    whatsInTheBox: "A medium sized NFT Print Pro customized book",
  },
  {
    id: "1258BHS",
    image: "./merchs/tote-bag.png",
    text: "Tote Bag",
    longText: "Tote Bag",
    price: "1000",
    colors: ["#000", "#3DEC00", "#e7f9c0"],
    whatsInTheBox: "A customized tote bag for NFT Print Pro",
  },
  {
    id: "1648BHS",
    image: "./merchs/water-can.png",
    text: "Water Can",
    longText: "water Can",
    price: "100",
    whatsInTheBox: "A customized Bottle for NFT Print Pro",
  },
  {
    id: "5258BHS",
    image: "./merchs/stickers.png",
    text: "Stickers",
    longText: "NFT Stcikers",
    price: "200",
    whatsInTheBox: "NFT Sticker",
  },
];
