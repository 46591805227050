import styled from "styled-components";

export const Print = styled.div`
  .link {
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    margin-left: 10px;
    color: #0c0c0c;
  }

  .size {
    padding: 16px;
    font-size: 16px;
    border: 2px solid #0c0c0c;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s ease;
    color: #6b6b6b;
  }

  .active {
    padding: 16px;
    font-size: 16px;
    border: 2px solid #0c0c0c;
    border-radius: 20px;
    color: #fff;
    background: #000;
    text-align: center;
    transition: 0.5s ease;
  }

  .btn {
    border: none;
    outline: none;
    padding: 20px;
    background-color: #6e4aff;
  }

  .animate {
    animation: moveArrow 1s alternate-reverse infinite;
    position: relative;
  }

  @keyframes moveArrow {
    from {
      transform: translateY(-30px);
    }

    to {
      transform: translateY(0);
    }
  }
`;
