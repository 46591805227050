import { Box, Button, Grid, Input, Select, Text } from "@chakra-ui/react";
import React from "react";

export const BitpayCard = ({
  handleInput,
  values,
  isNotSmallerScreen,
  handleBitPay,
  countries,
  st,
  handleCountry,
  totalAmount,
}) => {
  return (
    <Box w="100%">
      <Box mt={5}>
        <input
          type="image"
          src="https://bitpay.com/cdn/en_US/bp-btn-pay-currencies.svg"
          value="bitpay"
          style={{ width: "100%", height: "70px" }}
          alt="BitPay, the easy way to pay with bitcoins."
        />
      </Box>
      <form onSubmit={handleBitPay}>
        <Box>
          <Text
            fontSize="15px"
            fontWeight="500"
            textTransform="capitalize"
            color="#0c0c0c"
          >
            Details
          </Text>
          <Box>
            <Box marginTop="20px" marginBottom="20px">
              <Input
                variant="flushed"
                placeholder="Full name*"
                name="name"
                size="md"
                color="#0c0c0c"
                onChange={handleInput}
                value={values.name || ""}
                required
              />
            </Box>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <Box>
                <Input
                  variant="flushed"
                  placeholder="Email"
                  type="email"
                  name="email"
                  size="md"
                  color="#0c0c0c"
                  onChange={handleInput}
                  value={values.email || ""}
                  required
                />
              </Box>

              <Box>
                <Input
                  variant="flushed"
                  placeholder="Phone"
                  type="text"
                  name="phone"
                  size="md"
                  color="#0c0c0c"
                  onChange={handleInput}
                  value={values.phone || ""}
                  required
                />
              </Box>
            </Grid>
            <Text
              fontSize="1xl"
              fontWeight="500"
              color="#0c0c0c"
              textTransform="capitalize"
              marginTop={isNotSmallerScreen ? "30px" : "15px"}
            >
              Address
            </Text>
            <Grid templateColumns="repeat(2,1fr)" gap={6}>
              <Select
                placeholder="Country"
                onChange={(e) => handleCountry(e)}
                variant="flushed"
                marginTop="20px"
                marginBottom="20px"
                name="country"
                color="#0c0c0c"
                required
              >
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </Select>

              <Select
                placeholder="State"
                onChange={handleInput}
                marginTop="20px"
                marginBottom="20px"
                variant="flushed"
                color="#0c0c0c"
                name="state"
                required
              >
                {st.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </Select>
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <Box>
                <Input
                  placeholder="Address"
                  type="text"
                  name="address"
                  size="md"
                  color="#0c0c0c"
                  onChange={handleInput}
                  value={values.address}
                  variant="flushed"
                  required
                />
              </Box>
              <Box>
                <Input
                  variant="flushed"
                  placeholder="City"
                  type="text"
                  color="#0c0c0c"
                  name="city"
                  size="md"
                  onChange={handleInput}
                  value={values.city}
                  required
                />
              </Box>
            </Grid>
          </Box>
        </Box>
        <Button
          type="submit"
          color="#000"
          bg="#fecb4c"
          borderRadius="md"
          padding="20px"
          w="100%"
          mt={5}
          _hover={{
            opacity: 0.9,
            transition: "0.5s ease-in-out",
            cursor: "pointer",
          }}
          style={{ transition: "all 0.5s ease-in-out" }}
        >
          Pay ${totalAmount}
        </Button>
      </form>
    </Box>
  );
};
