import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { RiDeleteBin4Line } from "react-icons/ri";

const CartBox = ({
  cart,
  increaseCart,
  decreaseCart,
  removeFromCart,
  isNotSmallerScreen,
}) => {
  return (
    <Box w="100%" borderBottom="1px dashed #E9E7E7" mb={3} p={6}>
      <Flex alignItems="center">
        {cart?.name.includes("NFTPRINTPRO NPC") ? (
          <video
            width={isNotSmallerScreen ? "100px" : "50px"}
            autoPlay
            loop
            src={cart.image}
            style={{
              borderTop: "1px solid #53bafc",
              borderBottom: "1px solid #fb4383",
            }}
          >
            Internal
          </video>
        ) : (
          <Image
            src={cart.image}
            w={isNotSmallerScreen ? "100px" : "50px"}
            h={isNotSmallerScreen ? "100px" : "50px"}
            objectFit="contain"
            style={{
              borderTop: "1px solid #53bafc",
              borderBottom: "1px solid #fb4383",
            }}
          />
        )}
        <Box>
          <Text
            fontSize={isNotSmallerScreen ? "14px" : "12px"}
            mt={3}
            ml={5}
            color="#0c0c0c"
          >
            {cart.name}
          </Text>
          <Flex>
            <Text
              fontSize={isNotSmallerScreen ? "14px" : "12px"}
              mt={3}
              ml={5}
              color="#0c0c0c"
            >
              {cart.size}
            </Text>
            <Text
              fontSize={isNotSmallerScreen ? "14px" : "12px"}
              fontWeight="400"
              mt={3}
              ml={5}
              color="#0c0c0c"
            >
              <strong>${cart.price}</strong>
            </Text>
            <br />
          </Flex>
          {/* <Text
            fontSize={isNotSmallerScreen ? "14px" : "12px"}
            fontWeight="400"
            mt={3}
            ml={5}
            color="#0c0c0c"
          >
            <strong>{cart.type}</strong>
          </Text> */}
        </Box>
        {isNotSmallerScreen ? <Spacer /> : <Spacer />}

        <Box display="flex" alignContent="center" alignItems="center">
          <Button
            bg="transparent"
            border="none"
            color="#0c0c0c"
            onClick={() => decreaseCart(cart)}
            mr={isNotSmallerScreen ? 4 : 1}
          >
            -
          </Button>
          <Text color="#0c0c0c" fontSize={isNotSmallerScreen ? "md" : "sm"}>
            {" "}
            {cart.cartQuantity}
          </Text>
          <Button
            border="none"
            bg="transparent"
            color="#0c0c0c"
            ml={isNotSmallerScreen ? 4 : 1}
            onClick={() => increaseCart(cart)}
          >
            +
          </Button>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            bg="transparent"
            border="none"
            isRound
            onClick={() => removeFromCart(cart)}
            icon={
              <RiDeleteBin4Line
                size={isNotSmallerScreen ? "20" : "15"}
                color="#0c0c0c"
              />
            }
            _hover={{
              color: "red",
              cursor: "pointer",
            }}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default CartBox;
