import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "../utils/logger";
import APISlice from "./queries";
import authReducer from "./slices/auth.slice";

const rootReducer = combineReducers({
  auth: authReducer,
  [APISlice.reducerPath]: APISlice.reducer,
});
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [APISlice.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      // thunk: { extraArgument: { jwt: "getJWT(), getFirebase" } },
    }).concat([logger, APISlice.middleware]);
  },
});


export const persistor = persistStore(store);
