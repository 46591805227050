import { ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { default as React } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import { persistor, store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://af4d0aa3afa046e2aa6cf4c613fa71eb@o4505239819976704.ingest.sentry.io/4505239828824064",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <PersistGate persistor={persistor}>
        <Provider store={store}>
          <Elements stripe={stripePromise}>
            <App tab="home" />
          </Elements>
        </Provider>
      </PersistGate>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
