const useNumber = () => {
  const formatNormalNumbers = (number) => {
    if (number) {
      return Number(parseFloat(number).toFixed(2)).toLocaleString("en-UK");
    } else {
      return 0;
    }
  };
  return {
    formatNormalNumbers,
  };
};

export default useNumber;
