import APISlice from ".";

const OrderApiSlice = APISlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchOrders: builder.query({
        query(type = "print") {
          return `/order?type=${type}`;
        },
        transformResponse: (response) => {
          console.log({ response });
          return response;
        },
      }),
      fetchUserOrders: builder.query({
        query() {
          return "/order/user";
        },
        transformResponse: (response) => {
          const {
            data: { data },
          } = response;
          return data;
        },
      }),
    };
  },
});

export const { useFetchOrdersQuery,useFetchUserOrdersQuery } = OrderApiSlice;
