import { useMediaQuery } from "@chakra-ui/media-query";
import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Select,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import api from "../../api";
import { Spinner } from "../../design-system";
import box from "../../design-system/Assets/basket.gif";
import Layout from "../../design-system/Layout/Layout";
import { useAppSelector } from "../../redux/hooks";
import { selectAuthenticated, selectCurrentAccount } from "../../redux/slices/auth.slice";
import { getWithExpiry, setWithExpiry } from "../../utils/store";
import CartBox from "./CartBox";

const Cart = () => {
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [enableCoupon, setEnableCoupon] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState("");
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");
  const currentAccount = useAppSelector(selectCurrentAccount);
  const authenticated = useAppSelector(selectAuthenticated);

  const removeFromCart = (data) => {
    const nextCartItems = cart.filter(
      (cartItem) => cartItem?.size !== data?.size || cartItem?.id !== data?.id
    );
    setCart(nextCartItems);
    setWithExpiry("cartItems", nextCartItems);
    // toast(`removed from cart`);
    getItems();
  };

  const decreaseCart = (data) => {
    const newCart = cart.find(
      (cartItem) => cartItem.size === data.size && cartItem.id === data.id
    );
    const indexCart = cart.indexOf(newCart);

    if (cart[indexCart].cartQuantity > 1) {
      cart[indexCart].cartQuantity -= 1;
      setWithExpiry("cartItems", cart);
    } else if (cart[indexCart].cartQuantity === 1) {
      const nextCartItems = cart.filter((cartItem) => {
        let val1 = cartItem.size !== data.size;
        let val2 = cartItem.id !== data.id;
        return val1 !== val2;
      });
      setWithExpiry("cartItems", nextCartItems);
    }
    getItems();
  };

  const increaseCart = (data) => {
    const itemIndex = cart.findIndex(
      (item) => item.size === data.size && item.id === data.id
    );
    if (itemIndex >= 0) {
      cart[itemIndex].cartQuantity += 1;
    } else {
      const tempProduct = { ...data, cartQuantity: 1 };
      setCart(tempProduct);
      toast(`increased`);
    }
    setWithExpiry("cartItems", cart);
    getItems();
  };

  const getItems = async () => {
    const cart = await getWithExpiry("cartItems");
    if (cart) {
      setCart(cart);
      let total = cart.reduce(
        (total, item) => total + item.price * item.cartQuantity,
        0
      );

      setTotalAmount(total);
    }
    setLoading(false);
  };

  const updateCouponEffect = useCallback(() => {
    if (selectedCoupon.type === "percentage") {
      if (selectedCoupon.code === "NPC100" && selectedCoupon.value === 100) {
        if (totalAmount <= 50) {
          setDiscountedAmount(0);
          setDiscount(totalAmount);
        } else {
          setDiscountedAmount(totalAmount - 50);
          setDiscount(50);
        }
      } else {
        setDiscount(totalAmount * (selectedCoupon.value / 100));
        const discounted = totalAmount * (selectedCoupon.value / 100);
        setDiscountedAmount(totalAmount - discounted);
      }
    } else {
      setDiscount(selectedCoupon.value);
      setDiscountedAmount(totalAmount - selectedCoupon.value);
    }
  }, [
    selectedCoupon.code,
    selectedCoupon.type,
    selectedCoupon.value,
    totalAmount,
  ]);

  const fetchCoupons = async () => {
    try {
      const {
        data: { success, data },
      } = await api.get("/coupon/user");
      if (success) {
        setCoupons(data);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleCouponSelect = ({ target: { value } }) => {
    const couponDetails = JSON.parse(value);
    setSelectedCoupon(couponDetails);
    if (couponDetails.type === "percentage") {
      if (
        (couponDetails.code === "NPC100" &&
          couponDetails.value === "100" &&
          totalAmount <= 50) ||
        totalAmount >= 200
      ) {
        if (totalAmount <= 50) {
          setDiscountedAmount(0);
          setDiscount(totalAmount);
        } else if (totalAmount >= 200) {
          setDiscountedAmount(totalAmount - 50);
          setDiscount(50);
        }
      } else {
        setDiscount(totalAmount * (couponDetails.value / 100));
        setDiscountedAmount(
          totalAmount - totalAmount * (couponDetails.value / 100)
        );
      }
    }
  };

  const handleCheckout = () => {
    if (!authenticated) {
      toast(`Connect Wallet`);
      return;
    }
    if (selectedCoupon) {
      setWithExpiry("coup", selectedCoupon);
    }
    const checkAmount =
      discountedAmount || discount ? discountedAmount : totalAmount;
    setWithExpiry("checkAmount", checkAmount);
    window.location.href = "/checkout";
  };

  useEffect(() => {
    if (cart.length >= 1 && cart[0]?.type !== "print") {
      let tempCart = cart;
      tempCart[0].type = "print";
      setCart(tempCart);
    }
  }, [cart]);

  useEffect(() => {
    getItems();
    fetchCoupons();
  }, []);

  useEffect(() => {
    updateCouponEffect();
  }, [updateCouponEffect]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Layout>
          <Toaster />
          <Container
            maxW="container.lg"
            marginTop={isNotSmallerScreen ? "3%" : "15%"}
            paddingBottom={isNotSmallerScreen ? "200px" : "50px"}
          >
            <Box>
              <Text fontSize="20px" fontWeight="600" color="#0c0c0c">
                Your Shopping Cart
              </Text>
            </Box>
            <Box marginTop="20px" marginBottom="20px">
              <hr />
            </Box>

            {cart.length < 1 ? (
              <Box
                h="50vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  <Text textAlign="center" fontSize="16px" color="#6B6B6B">
                    No items in cart
                  </Text>
                  <Image
                    src={box}
                    alt={box}
                    objectFit="contain"
                    boxSize="300px"
                  />
                  {currentAccount ? (
                    <Text textAlign="center" fontSize="20px" color="#6B6B6B">
                      Go to{" "}
                      <Link
                        to="/shop"
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        Shop
                      </Link>
                    </Text>
                  ) : (
                    " "
                  )}
                </Box>
              </Box>
            ) : (
              <>
                <Flex direction={isNotSmallerScreen ? "row" : "column"}>
                  <Box w="100%">
                    {cart.map((cartItem, i) => (
                      <CartBox
                        cart={cartItem}
                        increaseCart={increaseCart}
                        decreaseCart={decreaseCart}
                        removeFromCart={removeFromCart}
                        key={`${i}${cartItem.size}`}
                        isNotSmallerScreen={isNotSmallerScreen}
                      />
                    ))}
                  </Box>

                  <Box
                    marginLeft={isNotSmallerScreen ? "30px" : "0px"}
                    marginTop={isNotSmallerScreen ? "0px" : "20px"}
                  >
                    <Box
                      w={isNotSmallerScreen ? "324px" : "100%"}
                      h="147px"
                      border="15px solid #EEEEEE"
                      bg="#fff"
                      p={4}
                    >
                      <Flex>
                        <Text
                          color="#0c0c0c"
                          marginLeft={isNotSmallerScreen ? "md" : "sm"}
                        >
                          Sub-total:
                        </Text>
                        <Spacer />
                        <Text
                          color="#0c0c0c"
                          marginLeft={isNotSmallerScreen ? "md" : "sm"}
                        >
                          ${totalAmount}
                        </Text>
                      </Flex>

                      <Box borderBottom="1px dashed #E9E7E7" mt={3} />

                      <Flex mt={5}>
                        <Text
                          color="#0c0c0c"
                          marginLeft={isNotSmallerScreen ? "md" : "sm"}
                          textTransform="uppercase"
                        >
                          Total:
                        </Text>
                        <Spacer />
                        {discountedAmount || discount ? (
                          <Text
                            color="#0c0c0c"
                            marginLeft={isNotSmallerScreen ? "md" : "sm"}
                            fontWeight="bold"
                          >
                            <span style={{ textDecoration: "line-through" }}>
                              ${totalAmount}
                            </span>{" "}
                            ${discountedAmount}
                          </Text>
                        ) : (
                          <Text
                            color="#0c0c0c"
                            marginLeft={isNotSmallerScreen ? "md" : "sm"}
                            fontWeight="bold"
                          >
                            ${totalAmount}
                          </Text>
                        )}
                      </Flex>
                    </Box>
                    <Button
                      mt={6}
                      w="100%"
                      textTransform="capitalize"
                      color="#000"
                      bg="#fecb4c"
                      padding="20px"
                      borderRadius="md"
                      marginLeft={isNotSmallerScreen ? "md" : "sm"}
                      fontWeight="500"
                      _hover={{
                        opacity: 0.6,
                        transition: "0.5s ease-in-out",
                        cursor: "pointer",
                      }}
                      style={{ transition: "all 0.5s ease-in-out" }}
                      onClick={handleCheckout}
                    >
                      Proceed to checkout{" "}
                    </Button>
                  </Box>
                </Flex>
                {coupons.length >= 1 && (
                  <Box
                    display={"flex"}
                    justifyContent="center"
                    alignItems={"center"}
                    mt={6}
                    gap={6}
                    width={"350px"}
                  >
                    {enableCoupon ? (
                      <Select
                        placeholder="Select Coupon"
                        onChange={handleCouponSelect}
                      >
                        {coupons.map((coupon, i) => (
                          <option
                            fontSize={isNotSmallerScreen ? "md" : "sm"}
                            value={JSON.stringify({
                              type: coupon.type,
                              value: coupon.value,
                              id: coupon._id,
                              code: coupon.code,
                            })}
                            key={i}
                          >
                            {coupon.campaign} | ${coupon.code}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      <Button
                        textTransform="capitalize"
                        color="#fff"
                        bg="#479DD4"
                        padding="20px"
                        borderRadius="md"
                        marginLeft={isNotSmallerScreen ? "md" : "sm"}
                        fontWeight="500"
                        _hover={{
                          opacity: 0.6,
                          transition: "0.5s ease-in-out",
                          cursor: "pointer",
                        }}
                        style={{ transition: "all 0.5s ease-in-out" }}
                        onClick={() => setEnableCoupon(true)}
                      >
                        Add Coupon
                      </Button>
                    )}
                  </Box>
                )}
              </>
            )}
          </Container>
        </Layout>
      )}
    </>
  );
};

export default Cart;
