import styled from "styled-components";

export const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;

  .accordion {
    background-color: #eee;
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    align-content: center;
    transition: background-color 0.6s ease;

    &:hover {
      background-color: #ccc;
    }
  }

  .active {
    background-color: #ccc;
  }

  .accordion_icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }

  .rotate {
    transform: rotate(90deg);
  }

  .accordion_content {
    overflow: hidden;
    transition: max-height 0.6s ease;
    padding-left: 18px;
  }
`;
