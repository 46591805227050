import axios from "axios";
import FormData from "form-data";

const {
  REACT_APP_PINATA_API_KEY: key,
  REACT_APP_PINATA_API_SECRET: secret_key,
  REACT_APP_PINATA_API_URL: api_url,
  REACT_APP_PINATA_GATEWAY_URL: gateway_url,
} = process.env;

export const uploadJSONToIPFS = async (JSONBody) => {
  try {
    const url = `${api_url}/pinJSONToIPFS`;
    const { data } = await axios.post(url, JSONBody, {
      headers: {
        pinata_api_key: key,
        pinata_secret_api_key: secret_key,
      },
    });

    return {
      success: true,
      pinataURL: `${gateway_url}/${data.IpfsHash}`,
    };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const uploadFileToIPFS = async (file) => {
  try {
    const url = `${api_url}/pinFileToIPFS`;

    let data = new FormData();

    data.append("file", file);

    const metadata = JSON.stringify({
      name: "NPPNPC",
      keyvalues: {
        version: 1,
        campaign: "initial",
      },
    });

    data.append("pinataMetadata", metadata);

    const pinataOptions = JSON.stringify({
      cidVersion: 0,
      customPinPolicy: {
        regions: [
          {
            id: "FRA1",
            desiredReplicationCount: 1,
          },
          {
            id: "NYC1",
            desiredReplicationCount: 2,
          },
        ],
      },
    });

    data.append("pinataOptins", pinataOptions);

    const response = await axios.post(url, data, {
      maxBodyLength: "Infinity",
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        pinata_api_key: key,
        pinata_secret_api_key: secret_key,
      },
    });

    return {
      success: true,
      pinataURL: `${gateway_url}/${response.data.IpfsHash}`,
    };
  } catch (error) {
    return { success: false, message: error.message };
  }
};
