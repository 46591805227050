import { useMediaQuery } from "@chakra-ui/media-query";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../design-system/Layout/Layout";
import MerchItem from "./MerchItem";
import { merchItem as items } from "./merchsData";

const Merchs = () => {
  const navigate = useNavigate();
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");

  return (
    <Layout>
      <Container maxW="container.xl" minH="100vh" h={"auto"}>
        <Flex flexDirection="column" alignItems="center" pt="30px">
          <Text
            fontSize="32px"
            lineHeight="48px"
            fontWeight="400"
            p="5px"
            borderBottom="2px solid #C2C2C2"
            mb="15px"
          >
            Shop
          </Text>
          <Grid
            width="90%"
            gridTemplateColumns={
              isNotSmallerScreen ? "1fr 1fr 1fr 1fr" : "1fr 1fr"
            }
            gap="20px"
            justifyContent="center"
            alignItems="stretch"
            mt="15px"
          >
            {items.map((item) => (
              <MerchItem key={item.id} data={item} />
            ))}
          </Grid>
          <Grid
            gridTemplateColumns={isNotSmallerScreen ? "1fr 1fr" : "1fr"}
            alignItems="center"
            columnGap={isNotSmallerScreen && "35px"}
            padding={isNotSmallerScreen ? "100px 15px" : "15px 0px 15px 0px"}
            width={isNotSmallerScreen ? "90%" : "100%"}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              justifyContent={isNotSmallerScreen && "stretch"}
            >
              <Text
                fontSize={isNotSmallerScreen ? "72px" : "40px"}
                fontWeight="500"
              >
                Want to Collaborate?
              </Text>
              <Text fontWeight="400" fontSize="16px">
                Want to Collaborate? High quality, affordable prints. NFT print
                pro is the easiest way to print your NFTs on demand. Your NFTs
                will look exactly like you want them to with rich colors and
                sharp contrast. Contact Us
              </Text>
              <Button
                colorScheme="linkedin"
                margin="15px 0px"
                width="100%"
                onClick={() => navigate("/contact")}
              >
                Contact Us
              </Button>
            </Flex>
            <Box>
              <Image src="./assets/collab-banner.png" />
            </Box>
          </Grid>
        </Flex>
      </Container>
    </Layout>
  );
};

export default Merchs;
