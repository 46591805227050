import {
  Box,
  Flex,
  Grid,
  Image,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import swal from "@sweetalert/with-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../../api";
import Blur from "../../design-system/Blur/Blur";
import Loader from "../../design-system/Blur/Loader";
import { useAppSelector } from "../../redux/hooks";
import { selectToken, selectUser } from "../../redux/slices/auth.slice";
import { removeWithExpiry } from "../../utils/store";
import { BitpayCard } from "./Card/BitpayCard";
import { StripeCard } from "./Card/StripeCard";

const AddressForm = ({
  countries,
  cart,
  totalAmount,
  handleCountry,
  st,
  coupon,
  isNotSmallerScreen,
  country,
}) => {
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    city: "",
    address: "",
    state: "",
  };

  const [values, setValues] = useState(initialValues);
  const stripe = useStripe();
  const elements = useElements();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const { none_usable } = user;
  const token = useAppSelector(selectToken);
  const [firstOrder, setFirstOrder] = useState(false);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleCreateOrder = async () => {
    const count = countries.find((c) => c.code === country);

    let totalPrice = cart.reduce(
      (total, item) => total + item.price * item.cartQuantity,
      0
    );
    totalPrice = Math.ceil(totalAmount).toFixed(2);
    let orderData = {
      type: cart[0].type === "credit" ? "credit" : "print",
      ...(cart[0].type === "credit"
        ? { credits: cart[0].credits }
        : { prints: cart }),
      amount: totalPrice,
      currency: "USD",
      delivery_details: {
        name: values.name,
        country: count.name,
        state: values.state,
        city: values.city,
        address: values.address,
      },
    };

    if (coupon) {
      orderData = { ...orderData, coupon: coupon.id };
    }

    try {
      const {
        data: { success, data },
      } = await API.post(
        "/order",
        { ...orderData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { _id: order, currency, amount } = data;

      if (success === true) {
        setValues(initialValues);
      }
      const response = { order, currency, amount };
      return response;
    } catch (error) {
      console.log(error);
      console.log(error.message);
    }
  };

  const handleStripe = async (e) => {
    e.preventDefault();
    setIsOpen(true);

    try {
      const { order, amount } = await handleCreateOrder();
      if (!stripe || !elements) {
        return;
      }

      /* Creating a payment intent. */
      const paymentIntent = await API.post(
        "/payment/stripe",
        {
          currency: "USD",
          amount: amount,
          paymentMethodType: "card",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { client_secret, error } = paymentIntent.data.data;

      if (error) {
        console.error(error.message);
      }

      /* The code below is confirming the payment. */
      const confirmPayment = await stripe.confirmCardPayment(client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      const { status } = confirmPayment.paymentIntent;

      /* Checking the status of the payment and if it is successful, it will close the modal and show a
      sweet alert. */
      switch (status) {
        case "succeeded":
          const response = await API.put(
            "/payment/stripe",
            {
              order,
              reference: client_secret,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const { success } = response.data;
          if (success === true) {
            setIsOpen(false);
            swal({
              title: "Paid",
              text: "Payment succeeded!",
              buttons: {
                confirm: {
                  text: "Done",
                  value: "Done",
                },
              },
              icon: "success",
            }).then((value) => {
              removeWithExpiry("cartItems");
              navigate("/");
            });
          } else {
            setIsOpen(false);
          }
          break;
        case "requires_payment_method":
          setIsOpen(false);
          swal("Please try again", "Your payment was not successful", "error");
          break;
        default:
          setIsOpen(false);
          swal("Something went wrong", "error");
      }

      if (confirmPayment.error) {
        console.error(confirmPayment.error.message);
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response.data.message);
      } else if (err.request) {
        console.log(err.request.message);
      } else if (err.message) {
        console.log(err.message);
      }
      setIsOpen(false);
    }
  };

  const handleBitPay = async (e) => {
    e.preventDefault();
    setIsOpen(true);
    /* The code below is creating an order and then creating a payment invoice for the order. */
    try {
      const { order, currency, amount } = await handleCreateOrder();
      const invoiceResponse = await API.post(
        "/payment/bitpay",
        {
          order,
          currency,
          amount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const url = invoiceResponse.data.data.url;

      window.location.href = url;
    } catch (err) {
      console.log(err);
    }
  };

  const handleFreeOrder = async (e) => {
    e.preventDefault();
    setIsOpen(true);
    try {
      const { order, amount } = await handleCreateOrder();

      if (amount !== 0 || amount !== 0.0) {
        return;
      }

      const payment = await API.post(
        "/payment/discount",
        {
          order,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (payment.data.success) {
        swal({
          title: "Paid",
          text: "Discount Processed!",
          buttons: {
            confirm: {
              text: "Done",
              value: "Done",
            },
          },
          icon: "success",
        }).then((value) => {
          removeWithExpiry("cartItems");
          navigate("/");
        });
      } else {
        swal("Please try again", "Discount Processing Failed.", "warning");
      }

      return;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!coupon && none_usable === false) {
      setFirstOrder(true);
    }
  }, [coupon, none_usable]);

  return (
    <>
      <Box>
        <Blur isOpen={isOpen} />
        <Loader isOpen={isOpen} />
        <Box>
          <Grid
            templateColumns={
              isNotSmallerScreen ? "repeat(2, 1fr)" : "repeat(1, 1fr)"
            }
            gap={10}
          >
            <Tabs variant="enclosed" colorScheme="#53bafc">
              <TabList>
                <Tab width={"50%"}>Card</Tab>
                <Tab width={"50%"}>Crypto</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <StripeCard
                    handleInput={handleInput}
                    values={values}
                    isNotSmallerScreen={isNotSmallerScreen}
                    countries={countries}
                    handleStripe={totalAmount ? handleStripe : handleFreeOrder}
                    st={st}
                    handleCountry={handleCountry}
                    totalAmount={firstOrder ? totalAmount * 0.9 : totalAmount}
                  />
                </TabPanel>
                <TabPanel>
                  <BitpayCard
                    handleInput={handleInput}
                    values={values}
                    isNotSmallerScreen={isNotSmallerScreen}
                    countries={countries}
                    st={st}
                    handleCountry={handleCountry}
                    handleBitPay={totalAmount ? handleBitPay : handleFreeOrder}
                    totalAmount={firstOrder ? totalAmount * 0.9 : totalAmount}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>

            <Box>
              <Box p={2}>
                <Text fontSize="20px" fontWeight="700" color="#0c0c0c">
                  Order Summary
                </Text>
                {cart.map((cartItem, i) => (
                  <Box mt={2} key={i} paddingBottom="5px">
                    <Flex>
                      {cartItem?.name.includes("NFTPRINTPRO NPC") ? (
                        <video
                          width={isNotSmallerScreen ? "100px" : "50px"}
                          autoPlay
                          loop
                          src={cartItem.image}
                        >
                          NFTPRINTPRO NPC
                        </video>
                      ) : (
                        <Image
                          src={cartItem.image}
                          w={isNotSmallerScreen ? "100px" : "50px"}
                          h={isNotSmallerScreen ? "100px" : "50px"}
                          objectFit="contain"
                        />
                      )}
                      <Box ml={5}>
                        <Text color="#0c0c0c">
                          <strong> {cartItem.name} </strong>
                        </Text>
                        {cartItem.size && (
                          <Text color="#0c0c0c">
                            Size: <strong>{cartItem.size}</strong>
                          </Text>
                        )}
                        <Text color="#0c0c0c">
                          QTY: <strong>{cartItem.cartQuantity}</strong>
                        </Text>
                        <Text color="#0c0c0c">
                          Sum :{" "}
                          <strong>
                            ${cartItem.price * cartItem.cartQuantity}
                          </strong>
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                ))}

                <Box borderBottom="1px dashed #E9E7E7" paddingBottom="10px">
                  <Flex>
                    <Text color="#0c0c0c">Subtotal:</Text>
                    <Spacer />
                    <Text color="#0c0c0c">${totalAmount}</Text>
                  </Flex>
                </Box>
                {firstOrder ? (
                  <Box
                    borderBottom="1px dashed #E9E7E7"
                    paddingTop="10px"
                    paddingBottom="10px"
                  >
                    <Flex color="#0c0c0c">
                      <Text>Total:</Text>
                      <Spacer />
                      <Text
                        color="#0c0c0c"
                        marginLeft={isNotSmallerScreen ? "md" : "sm"}
                        fontWeight="bold"
                      >
                        <Tooltip
                          label="10% discount on first order"
                          aria-label="10% discount tooltip"
                        >
                          <span style={{ textDecoration: "line-through" }}>
                            {totalAmount}
                          </span>
                        </Tooltip>{" "}
                        ${totalAmount * 0.9}
                      </Text>
                    </Flex>
                  </Box>
                ) : (
                  <Box
                    borderBottom="1px dashed #E9E7E7"
                    paddingTop="10px"
                    paddingBottom="10px"
                  >
                    <Flex color="#0c0c0c">
                      <Text>Total:</Text>
                      <Spacer />
                      <Text fontSize="20px" fontWeight="700">
                        ${totalAmount}
                      </Text>
                    </Flex>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AddressForm;
