import { Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.div`
  background: #0c0c0c;
  height: 10vh;
  //   margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;
export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 80px;
  z-indez: 1;
  width: 100%;
  //   max-width: 1100px;
`;

export const NavLogo = styled(Link)`
  background: linear-gradient(
    102.95deg,
    #53bafc 35%,
    #fb4383 60.48%,
    #fecb4c 80.6%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
`;

export const NavButton = styled.div`
  padding: 0.5rem 1rem;
  height: 100%;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  border: 1px solid #53bafc;
  // border-bottom:   1px solid #fb4383 ;
  margin-left: 20px;
  border-radius: 10px;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 90%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -15px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
  display: flex;

  .active {
    border-bottom: 3px solid #53bafc;
    color: white;
  }

  .nav_links {
    color: #e5e8eb;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;

    &:hover {
      color: #fff;
    }
  }
`;

export const NavLinks = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-size: 1.2rem;
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 50px;
  background: #01bf71;
  white-space: no-wrap;
  padding: 10px 22px;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;
