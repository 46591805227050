import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import DefaultInput from "../../design-system/Form/DefaultInput";
import useNumber from "../../hooks/useNumber";
import bg from "./assets/coinBg.png";
import coin from "./assets/coins.png";

const CreditItems = ({ details, setModals, setCustom }) => {
  const {
    credits,
    price,
    discount,
    iconUrls,
    buyCount,
    custom: unique,
  } = details;
  const { formatNormalNumbers } = useNumber();
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 480px)");
  // const [priceOfCredits, setPrice] = useState(4.6);
  // Will be fetched from an endpoint
  const [creditsError, setCreditsError] = useState(false);

  const [custom, setCustomSub] = useState({
    cost: "",
    credits: "",
    discount: "",
  });

  const handleCustomCredit = ({ target: { value } }) => {
    const credit = Number(value);
    if (credit < 50 || typeof credit !== "number") {
      setCreditsError(true);
    } else {
      setCreditsError(false);
    }

    if (credit >= 1000) {
      setCustomSub({
        credits: credit,
        cost: 4.5 * credit,
        discount: 10,
      });
    } else if (credit >= 250) {
      setCustomSub({ credits: credit, cost: 4.75 * credit, discount: 5 });
    } else {
      setCustomSub({ credits: credit, cost: 5 * credit, discount: 0 });
    }
  };

  return (
    <Box maxW={"400px"} w={"100%"} padding={"20px"}>
      <Flex
        width="100%"
        backgroundImage={bg}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        justifyContent="center"
        alignItems="center"
        borderRadius="7px"
        style={{ aspectRatio: "7/6" }}
      >
        <Image src={coin} alt="" />
      </Flex>
      <Flex
        justifyContent="space-between"
        fontWeight="700"
        fontSize={isNotSmallerScreen ? "24px" : "16px"}
        mt="5px"
      >
        {!details?.custom ? (
          <Text maxW="50%">{credits} Credits</Text>
        ) : (
          <DefaultInput
            value={custom.credits}
            isInvalid={creditsError}
            helperText={"Minimum of 50 Credits."}
            errorMessage={"Please enter a valid number above 50."}
            // maxW={isNotSmallerScreen ? "50%" : "70%"}
            // w={isNotSmallerScreen ? "50%" : "70%"}
            w={"200px"}
            border="2px solid red"
            fontSize={!isNotSmallerScreen ? "12px" : "16px"}
            onChange={handleCustomCredit}
            type="number"
            placeholder="Enter Credits"
            name="credits"
            max="5000"
          />
        )}
        {!unique ? (
          <Text>${formatNormalNumbers(price)}</Text>
        ) : (
          <Text
            overflow={"hidden"}
            whiteSpace="nowrap"
            textOverflow={"ellipsis"}
            width={"200px"}
            textAlign="right"
          >
            ${formatNormalNumbers(custom.cost)}
          </Text>
        )}
      </Flex>
      <Text
        fontSize={isNotSmallerScreen ? "16px" : "12px"}
        fontWeight="600"
        m="0px"
        minH={"24px"}
      >
        {!unique ? (
          discount ? (
            discount + "% discount"
          ) : (
            ""
          )
        ) : custom.discount ? (
          custom.discount + "% discount"
        ) : (
          <br />
        )}
      </Text>
      {!unique ? (
        <Flex justifyContent="space-between" alignItems="center">
          {isNotSmallerScreen && (
            <Flex position="relative" alignItems="center" margin="10px 0px">
              {iconUrls["1"] && (
                <Image
                  src={iconUrls["1"]}
                  alt=""
                  borderRadius="full"
                  boxSize="47px"
                />
              )}
              {iconUrls["2"] && (
                <Image
                  src={iconUrls["2"]}
                  alt=""
                  borderRadius="full"
                  boxSize="47px"
                  marginLeft="-12.5%"
                />
              )}
              {iconUrls["3"] && (
                <Image
                  src={iconUrls["3"]}
                  alt=""
                  borderRadius="full"
                  boxSize="47px"
                  marginLeft="-12.5%"
                />
              )}
              {buyCount > 3 && (
                <Text margin="0px 10px" fontWeight="500" fontSize="20px">
                  +{buyCount - Object.keys(iconUrls).length}
                </Text>
              )}
            </Flex>
          )}
          <Button
            variant="outline"
            colorScheme="#000"
            w={!isNotSmallerScreen && "100%"}
            onClick={() => {
              setCustom({ credits, discount, cost: price });
              setModals({ purchase: true });
            }}
          >
            Purchase
          </Button>
        </Flex>
      ) : (
        <Button
          variant="outline"
          colorScheme="#000"
          w="100%"
          m="10px 0px"
          disabled={!custom.credits || custom.credits < 50}
          onClick={() => {
            setCustom(custom);
            setModals({ purchase: true });
          }}
        >
          Purchase Custom
        </Button>
      )}
    </Box>
  );
};

export default CreditItems;
