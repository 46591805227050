import { Box, Button, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import useNumber from "../../hooks/useNumber";

const Overview = (props) => {
  const { modals, setModals, balance } = props;
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 480px)");
  const { formatNormalNumbers } = useNumber();

  return (
    <Box width="100%">
      <Text as="h2" fontWeight="600" fontSize="32px" color="#479DD4">
        Overview
      </Text>
      <Flex
        background="url('./assets/credits-bg-2.png') no-repeat"
        margin="10px 0px"
        flexDirection={!isNotSmallerScreen && "column"}
        justifyContent={isNotSmallerScreen ? "space-between" : "center"}
        alignItems="center"
        padding={isNotSmallerScreen && "0px 15px"}
        color=" #fff"
        style={{
          aspectRatio: "5/1",
        }}
      >
        <Box padding="10px" textAlign={!isNotSmallerScreen && "center"}>
          <Text fontSize="18px">Available credits</Text>
          <Text fontSize="48px" fontWeight="500">
            {balance ? formatNormalNumbers(balance) : ".."}
          </Text>
        </Box>
        <Flex flexDirection="column">
          <Button
            colorScheme="linkedin"
            m={isNotSmallerScreen ? "15px 0px" : "5px 0px"}
            width={isNotSmallerScreen && "150%"}
            ml={isNotSmallerScreen && "-50%"}
            onClick={() =>
              setModals({
                ...modals,
                gift: true,
              })
            }
          >
            Gift Credits
          </Button>
          <Button
            colorScheme="linkedin"
            m={isNotSmallerScreen ? "15px 0px" : "5px 0px"}
            width={isNotSmallerScreen && "150%"}
            ml={isNotSmallerScreen && "-50%"}
            onClick={() =>
              setModals({
                ...modals,
                redeem: true,
              })
            }
          >
            Redeem Credits
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Overview;
