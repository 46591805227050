import styled from "styled-components";

export const SideCartContainer = styled.div`
  position: fixed;
  z-index: 150;

  top: 50%;
  left: 50%;
  transition: 0.3s ease-in-out;
  transform: translate(-50%, -50%);
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  //   right: ${({ isOpen }) => (isOpen ? "0" : "0")};

  //   @media screen and (max-width: 768px) {
  //     width: 100%;
  //   }

  //   .box {
  //     padding: 20px;
  //   }
`;
export const Base = styled.div`
  color: #000;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: grid;
  top: 0;
  right: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  right: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;
