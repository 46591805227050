import { useMediaQuery } from "@chakra-ui/media-query";
import {
  Box,
  Button,
  Container,
  Grid,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../api";
import use from "../design-system/Assets/use.png";
import HandleError from "../design-system/utils/handleError";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  authLoginAsync,
  selectAddress,
  selectBlockchain,
  selectCurrentAccount,
} from "../redux/slices/auth.slice";
import AddWallet from "./AddWallet";

const User = () => {
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [discord, setDiscord] = useState("");
  const [twitter, setTwitter] = useState("");
  const [loading, setLoading] = useState(false);
  const [addWallet, setAddWallet] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const address = useAppSelector(selectAddress);
  const blockchain = useAppSelector(selectBlockchain);
  const currentAccount = useAppSelector(selectCurrentAccount);

  useEffect(() => {
    if (currentAccount) {
      navigate("/");
    }
  }, [currentAccount, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let data = {
      username: userName,
      email,
      discord,
      twitter,
      address: address.toLowerCase(),
      blockchain: blockchain.toLowerCase(),
    };
    try {
      const response = await API.post("/user", data);
      const { success } = response.data;

      if (success) {
        dispatch(
          authLoginAsync({
            blockchain: blockchain.toLowerCase(),
            address: address.toLowerCase(),
          })
        );
      }
      setLoading(false);
    } catch (err) {
      HandleError(err, navigate);
      setLoading(false);
    }
  };
  return (
    <Box h="100vh">
      <AddWallet addWallet={addWallet} setAddWallet={setAddWallet} />
      <Grid
        templateColumns={isNotSmallerScreen ? "500px auto" : "auto"}
        gap={6}
      >
        <Box>
          <Container maxW="container.xl">
            <Box p={isNotSmallerScreen ? 8 : 3} mt={5}>
              <Text fontSize="3xl" fontWeight="800" mt={5}>
                Welcome to NFTPrintPro
              </Text>
              <Text fontSize="md" fontWeight="400">
                Fill in the details
              </Text>

              <Box>
                <form onSubmit={handleSubmit}>
                  <Text fontSize="lg" fontWeight="500" mt={5} mb={1}>
                    Username
                  </Text>
                  <Input
                    type="text"
                    mb={5}
                    name="username"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    required
                  />
                  <Text fontSize="lg" fontWeight="500" mb={1}>
                    Email
                  </Text>
                  <Input
                    type="email"
                    mb={5}
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <Grid templateColumns="repeat(2, 1fr)" gap={6} mb={5}>
                    <Box>
                      <Text fontSize="lg" fontWeight="500" mb={1}>
                        Discord
                      </Text>
                      <Input
                        type="text"
                        name="discord"
                        value={discord}
                        onChange={(e) => setDiscord(e.target.value)}
                        required
                      />
                    </Box>
                    <Box>
                      <Text fontSize="lg" fontWeight="500" mb={1}>
                        Twitter
                      </Text>
                      <Input
                        type="text"
                        name="twitter"
                        value={twitter}
                        onChange={(e) => setTwitter(e.target.value)}
                        required
                      />
                    </Box>
                  </Grid>

                  <Text
                    fontSize="lg"
                    fontWeight="500"
                    mt={5}
                    mb={1}
                    textTransform="capitalize"
                  >
                    {blockchain} Address
                  </Text>
                  <Input
                    type="text"
                    name="address"
                    value={address}
                    disabled
                    required
                  />

                  <Button
                    w="100%"
                    bg="#000000"
                    color="#fff"
                    _hover={{ bg: "#123456" }}
                    borderRadius="none"
                    type="submit"
                    isLoading={loading}
                    mt={5}
                  >
                    Sign up
                  </Button>
                </form>
                <Box mt={5}>
                  <Text>
                    Already have an account? {/* <Link href="/add-wallet"> */}
                    <Button
                      bg="#000000"
                      borderRadius="20px"
                      color="#fff"
                      _hover={{ bg: "#123456" }}
                      // borderRadius="none"
                      type="submit"
                      w={"50%"}
                      onClick={() => setAddWallet(true)}
                    >
                      Add Wallet
                    </Button>
                    {/* </Link> */}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        <Image
          src={use}
          alt={use}
          h="100vh"
          w="100%"
          objectFit="fit"
          display={isNotSmallerScreen ? "block" : "none"}
        />
      </Grid>
    </Box>
  );
};

export default User;
