import { Box, Text } from "@chakra-ui/react";
import { BsPencil } from "react-icons/bs";
import { Link } from "react-router-dom";

const EmptyState = ({ url, text }) => {
  return (
    <Box
      w="100%"
      h="70vh"
      border="1px solid #0c0c0c"
      display="flex"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
    >
      <Box>
        <Text textAlign="center" color="#0c0c0c">
          {text}
        </Text>
        {url && (
          <Link
            to={`/${url}`}
            style={{
              textDecoration: "none",
            }}
          >
            <Text
              border="2px solid #fb4383"
              borderRadius={20}
              padding="10px"
              margin="10px"
              display={"flex"}
              gap={3}
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              New Post
              <BsPencil />
            </Text>
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default EmptyState;
