import {
  Button,
  Fade,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import swal from "@sweetalert/with-react";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

import API from "../../api.js";
import { Form } from "../../design-system/Form/style.js";
import { isValidEmail } from "../../helpers/functions.js";
import { useAppDispatch, useAppSelector } from "../../redux/hooks.js";
import {
  getCreditBalanceAsync,
  selectCreditBalance,
  selectToken,
} from "../../redux/slices/auth.slice.js";

const GiftCreditModal = ({ data }) => {
  const { modals, setModals } = data;
  const balance = useAppSelector(selectCreditBalance);
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const [details, setDetails] = useState({
    amount: "",
    email: "",
  });
  const [error, setError] = useState({
    email: "",
    amount: "",
  });

  const handleChange = ({ target: { name, value } }) => {
    setDetails({ ...details, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const giftCredits = async (event) => {
    event.preventDefault();
    const { amount, email } = details;

    if (!amount || Number(amount) <= 0) {
      return setError({ ...error, amount: "Please enter a valid amount" });
    }

    if (!email || !isValidEmail(email)) {
      return setError({ ...error, email: "Please enter a valid email" });
    }

    if (amount > balance) {
      return setError({ ...error, amount: "Insufficient balance" });
    }
    setModals({ ...modals, loadingGift: true });

    try {
      const res = await API.post(
        "/credit/transfer",
        {
          email,
          amount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data.success) {
        setModals({ ...modals, loadingGift: false, gift: false });
        swal("Sent", `Credit sent to ${email}`, "success");
      }
      dispatch(getCreditBalanceAsync());
    } catch (err) {
      console.log(err);
      setModals({ ...modals, loadingGift: false });
      if (err.response) {
        // Response sent from the server with the error code
        swal(err.response.data.message, "", "warning");
      } else if (err.request) {
        // Error with the request, usually just a statusText and no message
        swal("Please try again", "An error occurred", "error");
      } else if (err.message) {
        // Default error message assigned to the status code
        swal("Please try again", err.message, "error");
      }
    }
  };
  return (
    <Fade in={modals.gift}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100vh"
        zIndex={10}
        gap={5}
        bg="rgba(0,0,0,0.2)"
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={isNotSmallerScreen ? "45%" : "80%"}
          bg="#fff"
          p="10px"
          borderRadius="3px"
          _hover={{
            border: "1px solid #ddd",
          }}
        >
          <Flex width="100%" justifyContent="flex-end">
            <Flex
              bg="#424242"
              color="#fff"
              width="25px"
              height="25px"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              cursor="pointer"
              transition="0.3s"
              _hover={{
                transform: "scale(0.99)",
              }}
              onClick={() => setModals({ ...modals, gift: false })}
            >
              <AiOutlineClose size={15} />
            </Flex>
          </Flex>
          <Flex
            width="80%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize="24px" fontWeight="500">
              Gift Credits
            </Text>
            <Text fontSize="16px" textAlign="center">
              Send credits to friends and family
            </Text>
            <Form>
              <FormControl isInvalid={error.amount}>
                <Input
                  type="number"
                  placeholder="Enter an Amount"
                  variant="outline"
                  padding="25px 10px"
                  margin="10px 0px"
                  name="amount"
                  value={details.amount}
                  onChange={handleChange}
                />
                {error.amount && (
                  <FormErrorMessage>{error.amount}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={error.email}>
                <Input
                  type="email"
                  placeholder="Enter an Email Address"
                  variant="outline"
                  padding="25px 10px"
                  margin="10px 0px"
                  name="email"
                  value={details.email}
                  onChange={handleChange}
                />
                {error.email && (
                  <FormErrorMessage>{error.email}</FormErrorMessage>
                )}
              </FormControl>

              <Button
                colorScheme="linkedin"
                m="10px 0px"
                p="10px 20px"
                type="submit"
                isLoading={modals.loadingGift}
                onClick={giftCredits}
              >
                Send Credits
              </Button>
            </Form>
          </Flex>
        </Flex>
      </Flex>
    </Fade>
  );
};

export default GiftCreditModal;
