import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Text,
} from "@chakra-ui/react";
import React from "react";

const Account = ({ user, isNotSmallerScreen, currentAccount }) => {
  return (
    <Box
      m="10px 0px"
      w="100%"
      border="1px solid #E9E9E9"
      filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.13))"
      p="20px 15px"
    >
      <Text
        w="100%"
        fontSize="24px"
        fontWeight="400"
        borderBottom="2px solid #CACACA"
      >
        Your Profile
      </Text>
      <Grid
        gridTemplateColumns={isNotSmallerScreen ? "1fr 1fr" : "1fr"}
        mt="20px"
        columnGap="10px"
        rowGap={isNotSmallerScreen ? "20px" : "10px"}
      >
        <FormControl>
          <FormLabel>Last Name</FormLabel>
          <Input type="text" placeholder="Enter Your Last name. *" />
        </FormControl>
        <FormControl>
          <FormLabel>First Name</FormLabel>
          <Input type="text" placeholder="Enter Your First Name. *" />
        </FormControl>
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={user?.email}
            border="none"
            p="0px"
            w="100%"
            fontWeight="700"
            readOnly
          />
        </FormControl>
        <FormControl>
          <FormLabel>Wallet ID</FormLabel>
          <Input
            border="none"
            type="text"
            value={currentAccount}
            p="0px"
            w="100%"
            fontWeight="700"
            readOnly
          />
        </FormControl>
      </Grid>

      <Checkbox m="25px 10px">
        I want to receive email for Order notifications
      </Checkbox>
      <br />
      <Button
        variant="solid"
        colorScheme="linkedin"
        w={!isNotSmallerScreen && "100%"}
      >
        Update Information
      </Button>
    </Box>
  );
};

export default Account;
