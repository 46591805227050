import swal from "@sweetalert/with-react";
import { useState } from "react";
import api from "../api";

const useRequest = () => {
  const [message, setMessage] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);

  const makeRequest = async (url, body, method = "post") => {
    try {
      setLoading(true);
      const response = await api({ method, url: `/${url}`, data: body });

      const res = response.data;
      if (res.success) {
        setMessage(res.message);
        setData(res.data);
        setError(null);
        setLoading(false);
        swal("Done", res.message, "success");
      }
    } catch (error) {
      setError(true);
      setLoading(false);
      if (error.response) {
        // Response sent from the server with the error code
        setMessage(error.response.data.message);
        swal("Please try again", error.response.data.message, "warning");
      } else if (error.request) {
        // Error with the request
        setMessage(error.request.message);
        swal("Please try again", error.request.message, "error");
      } else if (error.message) {
        // Default error message assigned to the status code
        setMessage(error.message);
        swal("Please try again", error.message, "error");
      }
    } finally {
      setDone(true);
    }
  };

  return { done, message, data, error, loading, makeRequest };
};

export default useRequest;
