import { Box, Button, Center, Container, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../api";
import { Spinner } from "../../design-system";
import confetti from "../../design-system/Assets/confirmed.svg";
import { removeWithExpiry } from "../../utils/store";
import { useAppSelector } from "../../redux/hooks";
import { selectToken } from "../../redux/slices/auth.slice";

const Confirm = () => {
  const params = useParams();
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const token = useAppSelector(selectToken)

  const back = async () => {
    const removed = await removeWithExpiry("cartItems");
    if (removed) navigate("/");
  };

  const confirmInvoiceSettlement = async () => {
    const order = params.order;

    try {
      const invoiceResponse = await API.put(
        "/payment/bitpay",
        {
          order,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const status = invoiceResponse.data.data.status;
      setStatus(status);
      setLoading(false);
      setTimeout(() => {
        window.location.href = "/shop";
      }, 1500);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    confirmInvoiceSettlement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxW="container.lg">
      {loading ? (
        <Spinner />
      ) : (
        <Box
          h="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <Image
              src={confetti}
              alt={confetti}
              boxSize="300px"
              objectFit="contain"
            />
            <Text textAlign="center" mt={9} fontSize="3xl" fontWeight="600">
              {status ? status : ""}
            </Text>
            <Center>
              <Button mt={9} onClick={back} bg="none">
                Back to Home
              </Button>
            </Center>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default Confirm;
