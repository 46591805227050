import {
  Avatar,
  Badge,
  CircularProgress,
  Container,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import { AiFillSetting, AiOutlineBars } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { IoMdCart, IoMdLogOut } from "react-icons/io";
import { Link, NavLink } from "react-router-dom";
import truncateEthAddress from "truncate-eth-address";
import logo from "../../design-system/Assets/logo.jpeg";
import { getWithExpiry } from "../../utils/store";
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavBtn,
  NavButton,
  NavItem,
  NavLogo,
  NavMenu,
} from "./NavbarElements";

const NavBar = ({
  toggle,
  loading,
  connectWallet,
  currentAccount,
  disconnectWallet,
}) => {
  const cart = getWithExpiry("cartItems") ? getWithExpiry("cartItems") : [];
  let total = cart.reduce(
    (cartTotal, cartItem) => {
      const { cartQuantity } = cartItem;
      cartTotal.quantity += cartQuantity;
      return cartTotal;
    },
    {
      quantity: 0,
    }
  );

  return (
    <Nav>
      <Container maxW="container.xl">
        <NavbarContainer>
          <NavLogo to="/">
            <Image src={logo} alt="" h="50px" objectFit="contain" />
            NFT PRINT PRO
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <AiOutlineBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLink
                activeClassName="active"
                to="/shop"
                className="nav_links"
              >
                Shop
              </NavLink>
              {currentAccount && (
                <NavLink
                  activeClassName="active"
                  to="/credits"
                  className="nav_links"
                >
                  Credits
                </NavLink>
              )}
              <NavLink
                activeClassName="active"
                to="/how-to-print-nfts"
                className="nav_links"
              >
                How To Print
              </NavLink>
              <NavLink
                activeClassName="active"
                to="/mint"
                className="nav_links"
              >
                Mint
              </NavLink>
            </NavItem>
          </NavMenu>

          <NavBtn>
            {total.quantity >= 1 ? (
              <Link to="/cart" style={{ textDecoration: "none" }}>
                <Stack direction="row" mr={3}>
                  <IoMdCart color="#fff" size={30}></IoMdCart>
                  <Badge
                    colorScheme="blue"
                    variant={"outline"}
                    style={{ height: "16px" }}
                  >
                    {total.quantity}
                  </Badge>
                </Stack>
              </Link>
            ) : (
              <Link to="/cart">
                <Flex>
                  <IoMdCart color="#fff" size={30} />
                </Flex>
              </Link>
            )}
            {currentAccount ? (
              <Menu>
                <MenuButton>
                  <Avatar
                    ml={5}
                    name={truncateEthAddress(currentAccount)}
                    src={`https://robohash.org/${currentAccount}`}
                  />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    icon={<BsFillPersonFill size={20} />}
                    as={Link}
                    to="/profile"
                  >
                    <Flex>Profile</Flex>
                  </MenuItem>
                  <MenuItem
                    icon={<AiFillSetting size={20} />}
                    as={Link}
                    to="/settings"
                  >
                    <Flex>Settings</Flex>
                  </MenuItem>
                  <MenuItem
                    onClick={disconnectWallet}
                    icon={<IoMdLogOut size={20} />}
                  >
                    <Flex>Disconnect</Flex>
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <NavButton onClick={!loading ? connectWallet : () => {}}>
                {loading ? (
                  <CircularProgress
                    isIndeterminate
                    color="#53bafc"
                    size="40px"
                    thickness="12px"
                  />
                ) : (
                  "Connect wallet"
                )}
              </NavButton>
            )}
          </NavBtn>
        </NavbarContainer>
      </Container>
    </Nav>
  );
};

export default NavBar;
