import { useMediaQuery } from "@chakra-ui/media-query";
import { Box, Button, Flex, Grid, Text } from "@chakra-ui/react";
import axios from "axios";
import { useEffect } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import homeBg from "../../../design-system/Assets/hero-home.png";

const Banner = ({ handlePrintButton }) => {
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");
  const getContract = async () => {
    const response = await axios.get("https://igmrrf.github.io/contract.json");
    console.log({ response });
  };
  useEffect(() => {
    getContract();
    console.log({ response: "" });
  }, []);

  return (
    <Flex
      height="100vh"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bg={`url(${homeBg})`}
      bgSize="cover"
      color="#fff"
      textAlign="center"
    >
      <Text
        fontWeight="700"
        fontSize={isNotSmallerScreen ? "72px" : "60px"}
        m="15px 0px"
        w={isNotSmallerScreen ? "45%" : "90%"}
      >
        Print Your Own
      </Text>
      <Grid placeItems="center" width="300px" position="relative" m="20px 0px">
        <Box
          w="200px"
          h="70px"
          position="absolute"
          background="linear-gradient(to right, #479DD4 50%,#FC53D7)"
          transform="skew(150deg)"
          _hover={{
            background: "linear-gradient(to right, #479DD4 50%,#FC53D7)",
          }}
        ></Box>
        <Text
          position="absolute"
          zIndex="3"
          fontWeight="700"
          fontSize={isNotSmallerScreen ? "72px" : "60px"}
        >
          NFT
        </Text>
      </Grid>
      <Text
        position="relative"
        fontSize="16px"
        fontWeight="500"
        mt="20px"
        w={isNotSmallerScreen ? "35%" : "90%"}
      >
        Instantly print your NFT and receive it at the comfort of your home.
      </Text>
      <Button
        bg="linear-gradient(#479DD4, #479DD4)"
        mt="20px"
        padding="10px"
        w={isNotSmallerScreen ? "30%" : "80%"}
        minH="50px"
        rightIcon={<AiOutlineArrowRight size={20} />}
        transition="0.5s"
        onClick={handlePrintButton}
        _hover={{
          background: "linear-gradient(to right, #479DD4 70%,#FC53D7)",
        }}
      >
        Print NFT
      </Button>
    </Flex>
  );
};

export default Banner;
