import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Input,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import Layout from "../../design-system/Layout/Layout";
// import Editorral from "./QuillEditor/Editor";
import swal from "@sweetalert/with-react";
import { Form } from "../../design-system/Form/style.js";
import { uploadFileToIPFS } from "../../helpers/pinata";
import useRequest from "../../hooks/useRequest";
import TextEditor from "./QuillEditor/Editor";
import { Line, TagsInput } from "./style";

const Post = ({ currentAccount }) => {
  const file = useRef();
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [preview, setPreview] = useState("");
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tagPlaceholder, setTagPlaceholder] = useState(
    "Press enter to save tag"
  );
  const { loading: apiLoading, makeRequest } = useRequest();
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");

  const handleQuill = (value) => {
    setText(value);
  };

  const handleClear = () => {
    setTitle("");
    setSubTitle("");
    setText("");
    setSelectedImage(null);
    setTags([]);
    setTagPlaceholder("Press enter to save tag");
  };

  const uploadImage = async (file) => {
    setSelectedImage(file);
    setLoading(true);

    const response = await uploadFileToIPFS(file);
    if (response.success) {
      setSelectedImage(response.pinataURL);
    } else {
      swal("Please try again", response.message, "error");
    }

    setLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      title: title,
      subtitle: subTitle,
      body: text,
      image: selectedImage,
      categories: tags,
    };
    await makeRequest("article", { ...data });
    handleClear();
  };

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = (event) => {
    if (event.target.value !== "") {
      if (tags.length < 5) {
        setTags([...tags, event.target.value]);
        event.target.value = "";
      } else {
        setTagPlaceholder("Max tag reached");
        event.target.value = "";
      }
    }
  };

  useEffect(() => {
    if (selectedImage && typeof selectedImage === "object") {
      setPreview(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Container maxW="container.xl">
        <Form></Form>
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          pb="6"
          pt="6"
        >
          <Text textTransform="uppercase" fontSize="2xl" fontWeight="600">
            {title || "untitled"}
          </Text>

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minH={"320px"}
            background="#EFF1F8"
            border="1px solid #000"
            cursor="pointer"
            width={isNotSmallerScreen ? "400px" : "100%"}
            height={isNotSmallerScreen ? "400px" : "100%"}
            transition="0.2s"
            margin="15px 0px"
            style={{
              aspectRatio: "2/1",
            }}
            className="upload-container"
            onClick={() => file.current.click()}
          >
            {!preview ? (
              <>
                <Image
                  src="./assets/upload-vector.png"
                  transition="0.2s"
                  className="upload-vector"
                />
                <Text
                  fontSize={isNotSmallerScreen ? "18px" : "14px"}
                  color="#969696"
                  transition="0.2s"
                  className="upload-text"
                >
                  Click to Upload Your Image
                </Text>
              </>
            ) : (
              <>
                <Image
                  src={preview}
                  height="100%"
                  width="auto"
                  transition="0.2s"
                />
              </>
            )}
          </Flex>
          <Input
            type="file"
            ref={file}
            accept="image/*"
            display="none"
            onChange={({ target: { files } }) => uploadImage(files[0])}
          />
        </Box>

        <Line />

        <Box gap={5}>
          <Text
            align="left"
            size="sm"
            color="#203683"
            fontWeight={600}
            style={{ marginTop: "2%", marginBottom: "2%" }}
          >
            Note: Articles will be made public after approval.
          </Text>

          <Input
            mt={4}
            placeholder="Title"
            type="text"
            name="title"
            required
            value={title}
            onChange={({ target: { value } }) => setTitle(value)}
          />
          <Input
            mt={4}
            placeholder="Sub title"
            type="text"
            name="sub_title"
            required
            value={subTitle}
            w="100%"
            onChange={({ target: { value } }) => setSubTitle(value)}
          />
          <TagsInput>
            <ul className="tags">
              {tags.map((tag, index) => (
                <li className="tag" key={index}>
                  <span className="tag-title">{tag}</span>
                  <span
                    className="tag-close-icon"
                    onClick={() => removeTags(index)}
                  >
                    x
                  </span>
                </li>
              ))}
            </ul>
            <Input
              placeholder={tagPlaceholder}
              type="text"
              _focus={{
                border: "none",
              }}
              onKeyUp={(event) =>
                event.key === "Enter" ? addTags(event) : null
              }
            />
          </TagsInput>
          {/* <Editorial /> */}

          <Box paddingBottom="100px">
            <TextEditor text={text} setText={handleQuill} />

            <Box display="flex" mt={5}>
              <Button
                disabled={loading || apiLoading}
                colorScheme="pink"
                mr="6"
                onClick={handleClear}
              >
                Clear
              </Button>

              <Button
                colorScheme="blue"
                type="submit"
                disabled={loading || apiLoading}
                onClick={handleSubmit}
              >
                {apiLoading ? "loading..." : "Send"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default Post;
