import styled from "styled-components";

export const Tab = styled.div`
  .tabs {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;

    &:hover {
      cursor: pointer;
    }
  }

  li {
    margin: 0 1em;
    padding: 0.5em 1em;
    color: #767575;
    font-size: 16px;
  }

  .current {
    background: #203683;
    border-radius: 8px;
    color: #fff;
  }

  .content {
    padding: 0 1em;
  }

  @media screen and (max-width: 960px) {
    .tabs {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;

      &:hover {
        cursor: pointer;
      }
    }

    li {
      margin: 0 1em;
      padding: 0.5em 1em;
      color: #767575;
      font-size: 12px;
    }

    .current {
      background: #203683;
      border-radius: 8px;
      color: #fff;
    }

    .content {
      padding: 0 2em;
    }
  }
`;
