import swal from "@sweetalert/with-react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Web3 from "web3";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  authLoginAsync,
  selectCurrentAccount,
} from "../../redux/slices/auth.slice";
import Footer from "../Footer/Footer";
import ConnectPopup from "../NavBar/ConnectPopup";
import NavBar from "../NavBar/NavBar";
import SideBar from "../SideBar";
import data from "../../data";
import { logout } from "../../redux/slices/auth.slice";
import HandleError from "../utils/handleError";
const chainIds = data.chainIds;

const { REACT_APP_CHAIN_ID, REACT_APP_INFURA_PROJECT_ID } = process.env;
const provider = new WalletConnectProvider({
  infuraId: REACT_APP_INFURA_PROJECT_ID,
});

const { REACT_APP_CHAIN_CODE } = process.env;

const Container = styled.div`
margin: 0,
padding: 0,
box-sizing: border-box;
`;

const Layout = ({ children, bg }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [connectPopup, setConnectPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentAccount = useAppSelector(selectCurrentAccount);

  const connectUsingMetaMask = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      return swal("Please install metamask wallet!");
    }

    const chainId = await ethereum.request({ method: "eth_chainId" });
    if (String(chainId) !== REACT_APP_CHAIN_CODE) {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: REACT_APP_CHAIN_CODE }],
      });
    }

    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });

    const blockchain = chainIds[chainId.split("")[2]];

    try {
      const address = accounts[0];

      dispatch(authLoginAsync({ address: address.toLowerCase(), blockchain }));
    } catch (err) {
      HandleError(err, navigate);
    }
  };

  const getProvider = () => {
    if ("phantom" in window) {
      const provider = window.phantom?.solana;
      if (provider?.isPhantom) {
        provider.on("disconnect", () => {
          dispatch(logout());
        });
        return provider;
      }
    }
    return swal("Please install Phantom or Metamask wallet!");
  };

  const connectUsingPhantom = async () => {
    const provider = getProvider();
    let address;
    try {
      const resp = await provider.connect();
      address = resp.publicKey.toString();
      const blockchain = "solana";
      dispatch(authLoginAsync({ address: address.toLowerCase(), blockchain }));
    } catch (err) {
      HandleError(err, navigate);
    }
  };

  const connectUsingWalletConnect = async () => {
    try {
      await provider.enable();
    } catch {
      dispatch(logout());
      return swal("Could not connect Wallet!");
    }
    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    const chainId = await web3.eth.getChainId();
    try {
      if (String(chainId) !== REACT_APP_CHAIN_ID) {
        dispatch(logout());
        return swal("Please connect to the Ethereum Mainnet!");
      }
      const blockchain = chainIds[chainId];
      const address = accounts[0];

      dispatch(authLoginAsync({ address: address.toLowerCase(), blockchain }));
    } catch (err) {
      HandleError(err, navigate);
    }

    provider.on("disconnect", () => {
      swal("Disconnected");
      dispatch(logout());
    });
  };

  const disconnectWalletConnect = async () => {
    dispatch(logout());
    try {
      if (provider) await provider.connector.killSession();
    } catch {
      navigate("/");
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container style={{ backgroundColor: bg }}>
      <SideBar
        isOpen={isOpen}
        toggle={toggle}
        connectWallet={connectUsingWalletConnect}
        currentAccount={currentAccount}
        disconnectWallet={disconnectWalletConnect}
      />
      <NavBar
        toggle={toggle}
        loading={loading}
        connectWallet={() => setConnectPopup(true)}
        currentAccount={currentAccount}
        disconnectWallet={disconnectWalletConnect}
      />
      <ConnectPopup
        connectPopup={connectPopup}
        loading={loading}
        setLoading={setLoading}
        setConnectPopup={setConnectPopup}
        connectUsingPhantom={connectUsingPhantom}
        connectUsingMetaMask={connectUsingMetaMask}
        connectUsingWalletConnect={connectUsingWalletConnect}
      />
      {children}
      <Footer />
    </Container>
  );
};

export default Layout;

