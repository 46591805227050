import styled from "styled-components";

export const Art = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

  .input {
    background: #eff0f7;
    padding: 10px;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
  }

  input {
    background: none;
    border: none;
    padding: 5px;

    ::placeholder {
      color: #6e7191;
      font-size: 16px;
    }

    &:focus {
      outline: none;
      border: 0.5px solid #6e7191;
    }
  }

  .select {
    background: #eff0f7;
    padding: 10px;
    border-radius: 16px;
  }

  select {
    background: none;
    border: none;
    padding: 5px;
    color: #6e7191;
  }

  option {
    background: none;
    border: none;
    padding: 5px;
    color: #6e7191;
  }

  @media screen and (max-width: 960px) {
    header {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
    }

    .input {
      background: #eff0f7;
      padding: 10px;
      border-radius: 16px;
      display: flex;
      align-content: center;
      align-items: center;
    }

    input {
      background: none;
      border: none;
      padding: 5px;

      ::placeholder {
        color: #6e7191;
        font-size: 16px;
      }

      &:focus {
        outline: none;
        border: 0.5px solid #6e7191;
      }
    }

    .select {
      background: #eff0f7;
      padding: 10px;
      border-radius: 16px;
    }

    select {
      background: none;
      border: none;
      padding: 5px;
      color: #6e7191;
    }

    option {
      background: none;
      border: none;
      padding: 5px;
      color: #6e7191;
    }
  }
  .paginationButtons-m {
    width: 100%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: space-around;
  }

  .paginationButtons-m a {
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      color: white;
      background-color: #2b2eff;
    }
  }

  .paginationButtons {
    width: 100%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }

  .paginationButtons a {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      color: white;
      background-color: #53bafc;
    }
  }

  .paginationActive a {
    color: white;
    background-color: #53bafc;
  }
`;

// export const CardContainer = styled.div`
//   margin-top: 5%;
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   grid-gap: 10px;

//   .add {
//     border: 1px dashed #000000;
//     display: flex;
//     justify-content: center;
//     align-content: center;
//     align-items: center;
//     padding: 16px;
//   }

//   img {
//     width: 100%;
//   }

//   .card {
//     background: #f5f5f5;
//     padding-bottom: 30px;
//     border-radius: 8px;
//   }

//   .card-body {
//       padding: 10px;
//   }

//   @media screen and (max-width: 960px) {
//     margin-top: 5%;
//     display: grid;
//     grid-template-columns: repeat(1, 1fr);
//     grid-gap: 20px;

//     .card {
//       position: relative;
//       background: #f5f5f5;
//       padding-bottom: 30px;
//       border-radius: 8px;
//     }
//   }
// `;
