import { useMediaQuery } from "@chakra-ui/media-query";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";
import "animate.css";
import { useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import scrollreveal from "scrollreveal";
import baloonHome from "../../design-system/Assets/baloon-home.png";
import hero3 from "../../design-system/Assets/hero-home-3.png";
import mintPrintBg from "../../design-system/Assets/mint-then-print.png";
import reviewBg from "../../design-system/Assets/reviews-bg.png";
import stepsDelivery from "../../design-system/Assets/steps-delivery.png";
import stepsProduct from "../../design-system/Assets/steps-product.png";
import stepsQuality from "../../design-system/Assets/steps-quality.png";
import stepsShipping from "../../design-system/Assets/steps-shipping.png";
import stepsSustainable from "../../design-system/Assets/steps-sustainable.png";
import stepsWallet from "../../design-system/Assets/steps-wallet.png";
import Layout from "../../design-system/Layout/Layout";
import ConnectPopup from "../../design-system/NavBar/ConnectPopup";
import HandleError from "../../design-system/utils/handleError";
import useMetaMask from "../../hooks/useMetaMask";
import usePhantomWallet from "../../hooks/usePhantom";
import useWalletConnect from "../../hooks/useWalletConnect";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  clearAuthMessage,
  selectAuthErrorMessage,
  selectCurrentAccount,
} from "../../redux/slices/auth.slice";
import { getWithExpiry, setWithExpiry } from "../../utils/store";
import Banner from "./components/Banner";
import Discount from "./components/Discount";
import Refer from "./components/Refer";
import Reviews from "./components/Review";
import Slide from "./components/Slide";

const Home = () => {
  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");
  const [connectPopup, setConnectPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { connectUsingPhantom } = usePhantomWallet(HandleError);
  const { connectUsingMetaMask } = useMetaMask(HandleError);
  const { connectUsingWalletConnect } = useWalletConnect(HandleError);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const errorMessage = useAppSelector(selectAuthErrorMessage);
  console.log({ currentAccount });

  useEffect(() => {
    if (errorMessage && errorMessage === "No existing User, Join.") {
      console.log({ errorMessage1: errorMessage });
      dispatch(clearAuthMessage());
      navigate("/user");
    }
  }, [errorMessage, dispatch, navigate]);

  const [discountPopup, setDiscountPopup] = useState(false);
  const [reviews] = useState([
    {
      review:
        "NFT Print Pro, will not just revolutionize the view on NFTs but also the use cases of such collectibles.",
      author: "Igbiriki Francis, ",
      source: "Ajian Labs",
    },
    {
      review:
        "“NFT Print Pro is so much better than doing your own merch store. Having to create your own token-gated merchants store from scratch is too costly and time-intensive. NFT Print Pro takes over this responsibility so Web3 founders can focus on their ‘big picture’.”",
      author: "Daniel Vataj, ",
      source: "Founder of TokenTag",
    },
  ]);
  const [reviewCounter, setReviewCounter] = useState(0);

  const handlePrintButton = () => {
    if (currentAccount) {
      window.location.href = "/profile";
    } else {
      if (isNotSmallerScreen) {
        setConnectPopup(true);
      } else {
        connectUsingWalletConnect();
      }
    }
  };

  useEffect(() => {
    const sr = scrollreveal({
      origin: "bottom",
      distance: "80px",
      duration: 2000,
      reset: true,
    });
    sr.reveal(`#role, #ab`, {
      opacity: 0,
      interval: 200,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!currentAccount && !getWithExpiry("User_Visited")) {
        setWithExpiry("User_Visited", true, 24);
        setDiscountPopup(true);
      }
    }, 2000);
  }, [currentAccount]);
  return (
    <Layout>
      <ConnectPopup
        connectPopup={connectPopup}
        loading={loading}
        setLoading={setLoading}
        setConnectPopup={setConnectPopup}
        connectUsingPhantom={connectUsingPhantom}
        connectUsingMetaMask={connectUsingMetaMask}
        connectUsingWalletConnect={connectUsingWalletConnect}
      />
      <Discount
        isNotSmallerScreen={isNotSmallerScreen}
        connectPopup={connectPopup}
        setConnectPopup={setConnectPopup}
        discountPopup={discountPopup}
        setDiscountPopup={setDiscountPopup}
        currentAccount={currentAccount}
        connectUsingMetaMask={connectUsingMetaMask}
        connectUsingWalletConnect={connectUsingWalletConnect}
      />

      <Banner handlePrintButton={handlePrintButton} />

      <Container maxW="container.xl" mt={5} bg="none">
        <Box>
          <Slide />
        </Box>
      </Container>
      <Grid placeItems="center">
        <Grid
          gridTemplateColumns={isNotSmallerScreen ? "repeat(3,1fr)" : "1fr"}
          placeItems="center"
          alignItems="stretch"
          w="70%"
          rowGap={!isNotSmallerScreen && "10px"}
          columnGap="25px"
          marginBottom={"50px"}
        >
          <Grid
            placeItems="center"
            textAlign="center"
            border="1px solid #B7B7B7"
            borderRadius="8px"
            padding="16px"
          >
            <Image src={stepsQuality} alt="" />
            <Text fontWeight="700" fontSize="16px">
              Quality Prints
            </Text>
            <Text fontWeight="500" fontSize="14px">
              Thick, durable paper with a smooth gloss-free finish and
              top-quality inks.
            </Text>
          </Grid>
          <Grid
            placeItems="center"
            textAlign="center"
            border="1px solid #B7B7B7"
            borderRadius="8px"
            padding="16px"
          >
            <Image src={stepsSustainable} alt="" />
            <Text fontWeight="700" fontSize="16px">
              Sustainable Materials
            </Text>
            <Text fontWeight="500" fontSize="14px">
              Prioritizing 100% recyclable products and packaging.
            </Text>
          </Grid>
          <Grid
            placeItems="center"
            textAlign="center"
            border="1px solid #B7B7B7"
            borderRadius="8px"
            padding="25px 16px"
          >
            <Image src={stepsShipping} alt="" />
            <Text fontWeight="700" fontSize="16px">
              Pay With Fiat or Crypto
            </Text>
            <Text fontWeight="500" fontSize="14px">
              Fulfill your payment in your currency of choice, using platforms
              you trust.
            </Text>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        mt="30px"
        h="90vh"
        background={`url(${mintPrintBg})`}
        backgroundPosition={isNotSmallerScreen ? "center" : "right"}
        backgroundSize="cover"
        gridTemplateColumns={isNotSmallerScreen ? "repeat(6,1fr)" : "1fr"}
        gridTemplateRows={isNotSmallerScreen ? "repeat(10,1fr)" : "1fr"}
        placeItems="center"
        color="#fff"
      >
        <GridItem
          gridColumn={isNotSmallerScreen && "4/7"}
          gridRow={isNotSmallerScreen && "1/8"}
        >
          <Text fontSize="72px" fontWeight="700" textAlign="center">
            Mint, <br />
            Then Print
          </Text>
          <Button
            bg="linear-gradient(#479DD4, #479DD4)"
            mt="15px"
            padding="10px"
            w="100%"
            minH="80px"
            rightIcon={<AiOutlineArrowRight size={20} />}
            transition="0.5s"
            onClick={handlePrintButton}
            _hover={{
              background: "linear-gradient(to right, #479DD4 70%,#FC53D7)",
            }}
          >
            Print NFT
          </Button>
        </GridItem>
      </Grid>

      <Container maxW="container.xl" py="30px" bg="none">
        <Grid
          gridTemplateColumns={isNotSmallerScreen ? "1fr 1fr" : "1fr"}
          placeItems="center"
          textAlign={!isNotSmallerScreen && "center"}
        >
          <Image src={hero3} alt="" />
          <Box fontWeight="500" w="70%">
            <Text fontSize="34px">From the digital space to you.</Text>
            <Text fontSize="18px">
              NFT print pro is the easiest way to print your NFTs on demand.
              Your NFTs will look exactly like you want them to with rich colors
              and sharp contrast.
            </Text>
          </Box>
        </Grid>
      </Container>

      <Grid gridTemplateColumns="repeat(10, 1fr)">
        <GridItem gridColumn="5/8">
          <Image src={baloonHome} alt="" />
        </GridItem>
      </Grid>

      <Grid placeItems="center" rowGap="20px" py="40px">
        <Text fontSize="32px">Three Easy Steps</Text>
        <Grid
          gridTemplateColumns={isNotSmallerScreen ? "repeat(3,1fr)" : "1fr"}
          rowGap={!isNotSmallerScreen && "10px"}
          placeItems="center"
          alignItems="stretch"
          w="70%"
          columnGap="25px"
        >
          <Grid
            placeItems="center"
            textAlign="center"
            border="1px solid #B7B7B7"
            borderRadius="8px"
            padding="25px 16px"
          >
            <Image src={stepsWallet} alt="" />
            <Text fontWeight="700" fontSize="16px">
              Connect Your Wallet
            </Text>
            <Text fontWeight="500" fontSize="14px">
              Connect your Web3 wallet by clicking the wallet icon in the top
              right corner.
            </Text>
          </Grid>
          <Grid
            placeItems="center"
            textAlign="center"
            border="1px solid #B7B7B7"
            borderRadius="8px"
            padding="16px"
          >
            <Image src={stepsProduct} alt="" />
            <Text fontWeight="700" fontSize="16px">
              Pick a Physical Product
            </Text>
            <Text fontWeight="500" fontSize="14px">
              Choose a product to print your NFT art on and select a size you
              want.
            </Text>
          </Grid>
          <Grid
            placeItems="center"
            textAlign="center"
            border="1px solid #B7B7B7"
            borderRadius="8px"
            padding="16px"
          >
            <Image src={stepsDelivery} alt="" />
            <Text fontWeight="700" fontSize="16px">
              Worldwide, Doorstep Delivery
            </Text>
            <Text fontWeight="500" fontSize="14px">
              Offering reasonable shipping to every country, delivered to your
              home or office.
            </Text>
          </Grid>
        </Grid>
      </Grid>

      <Flex
        h="80vh"
        mt={"10vh"}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        rowGap="10px"
        bg={`url(${reviewBg})`}
        backgroundPosition="center"
        backgroundSize="cover"
        color="#fff"
      >
        <Text color="#53BAFC" fontSize="14px" fontWeight="500">
          TESTIMONIALS
        </Text>
        <Text fontSize="32px">What People say</Text>
        <Grid
          placeItems="center"
          gridTemplateColumns="1fr 6fr 1fr"
          w={isNotSmallerScreen ? "50%" : "90%"}
          background="rgba(0, 0, 0, 0.4)"
        >
          <AiOutlineArrowLeft
            cursor="pointer"
            onClick={() => {
              if (reviews[reviewCounter - 1])
                setReviewCounter(reviewCounter - 1);
              else {
                setReviewCounter(reviews.length - 1);
              }
            }}
          />
          <Box textAlign="center" p="20px 0px">
            <Text fontWeight="300" fontSize="16px">
              {reviews[reviewCounter].review}
            </Text>
            <Text fontWeight="500" fontSize="14px" m="10px 0px" mt="15px">
              {reviews[reviewCounter].author}
            </Text>
            <Text fontWeight="300" fontSize="12px">
              {reviews[reviewCounter].source}
            </Text>
          </Box>
          <AiOutlineArrowRight
            cursor="pointer"
            onClick={() => {
              if (reviews[reviewCounter + 1])
                setReviewCounter(reviewCounter + 1);
              else {
                setReviewCounter(0);
              }
            }}
          />
        </Grid>
      </Flex>

      <Container
        maxW="container.xl"
        h={!currentAccount && "5vh"}
        className="center"
      >
        {currentAccount ? (
          <Box marginBottom={"50px"}>
            <Reviews account={currentAccount} />
          </Box>
        ) : null}
      </Container>

      <Box py="50px" mb={"5vh"}>
        <Refer
          account={currentAccount}
          isNotSmallerScreen={isNotSmallerScreen}
        />
      </Box>
    </Layout>
  );
};

export default Home;
