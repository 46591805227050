import swal from "@sweetalert/with-react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import data from "../data";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  authLoginAsync,
  logout,
  selectBlockchain,
  selectCurrentAccount,
} from "../redux/slices/auth.slice";
const chainIds = data.chainIds;

const { REACT_APP_CHAIN_ID, REACT_APP_INFURA_PROJECT_ID } = process.env;
const provider = new WalletConnectProvider({
  infuraId: REACT_APP_INFURA_PROJECT_ID,
});

const useWalletConnect = (HandleError) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const blockchain = useAppSelector(selectBlockchain);

  const connectUsingWalletConnect = async () => {
    try {
      await provider.enable();
    } catch {
      dispatch(logout());
      return swal("Could not connect Wallet!");
    }
    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    const chainId = await web3.eth.getChainId();
    try {
      if (String(chainId) !== REACT_APP_CHAIN_ID) {
        dispatch(logout());
        return swal("Please connect to the Ethereum Mainnet!");
      }
      const blockchain = chainIds[chainId];
      const address = accounts[0];

      dispatch(authLoginAsync({ address: address.toLowerCase(), blockchain }));
    } catch (err) {
      HandleError(err, navigate);
    }

    provider.on("disconnect", () => {
      swal("Disconnected");
      dispatch(logout());
    });
  };

  const disconnectWalletConnect = async () => {
    dispatch(logout());
    try {
      if (provider) await provider.connector.killSession();
    } catch {
      navigate("/");
    }
  };

  return {
    blockchain,
    currentAccount,
    connectUsingWalletConnect,
    disconnectWalletConnect,
  };
};

export default useWalletConnect;
