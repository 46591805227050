const useForm = () => {
  const isValidEmail = (email) => {
    if (email.match(/^\S+@\S+\.\S+$/)) return true;
    return false;
  };
  return {
    isValidEmail,
  };
};

export default useForm;
