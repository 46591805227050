import { Box, Container } from "@chakra-ui/react";
import React from "react";
import Layout from "../../design-system/Layout/Layout";
import { default as Text } from "../../design-system/Text/Text";

const Refund = () => {
  return (
    <Layout>
      <Box minH={"70vh"} className="policy" mb={'24'}>
        <Container maxW={"container.xl"}>
          <Box>
            <Text size="lg">Refund Policy</Text>
            <Text size="md">No Refunds</Text>
            <Text >
              All sales membership subscription payments made before
              cancellation are final, and the company does not offer any
              money-back guarantees. You recognize and agree that you shall not
              be entitled to a refund for any purchase under any circumstances.
              No warranties the company makes no warranties regarding the
              performance or operation of this website. The company further
              makes no representations or warranties of any kind, express or
              implied, as to the information, contents, materials, documents,
              programs, products, books, or services included on or through this
              website. To the fullest extent permissible under the law, the
              company disclaims all warranties, express or implied, including
              implied warranties of merchantability and fitness for a particular
              purpose. Limitation of liability you agree to absolve the company
              of any and all liability or loss that you or any person or entity
              associated with you may suffer or incur as a result of use of the
              information contained on this website and/or the resources you may
              download from this website. You agree that the company shall not
              be liable to you for any type of damages, including direct,
              indirect, special, incidental, equitable, or consequential loss or
              damages for use of this website. The information, software,
              products, and services included in or available through the
              website may include inaccuracies or typographical errors. Changes
              are periodically added to the information herein. The company
              and/or its suppliers may make improvements and/or changes in the
              website at any time. The company and/or its suppliers make no
              representations about the suitability, reliability, availability,
              timeliness, and accuracy of the information, software, products,
              services and related graphics contained on the website for any
              purpose. To the maximum extent permitted by applicable law, all
              such information, software, products, services and related
              graphics are provided "As is" without warranty or condition of any
              kind. The company and/or its suppliers hereby disclaim all
              warranties and conditions with regard to this information,
              software, products, services and related graphics, including all
              implied warranties or conditions of merchantability, fitness for a
              particular purpose, title, and non-infringement. To the maximum
              extent permitted by applicable law, in no event shall the company
              and/or its suppliers be liable for any direct, indirect, punitive,
              incidental, special, consequential damages or any damages
              whatsoever including, without limitation, damages for loss of use,
              data or profits, arising out of or in any way connected with the
              use or performance of the website, with the delay or inability to
              use the website or related services, the provision of or failure
              to provide services, or for any information, software, products,
              services and related graphics obtained through the website, or
              otherwise arising out of the use of the website, whether based on
              contract, tort, negligence, strict liability or otherwise, even if
              the company or any of its suppliers has been advised of the
              possibility of damages. Because some states/jurisdictions do not
              allow the exclusion or limitation of liability for consequential
              or incidental damages, the above limitation may not apply to you.
              If you are dissatisfied with any portion of the website, or with
              any of these terms of use, your sole and exclusive remedy is to
              discontinue using the website.
            </Text>
          </Box>
        </Container>
      </Box>
    </Layout>
  );
};

export default Refund;
