import { Box, Button, Flex, Grid, Image, Text } from "@chakra-ui/react";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useAppSelector } from "../../../redux/hooks";
import { selectCurrentAccount } from "../../../redux/slices/auth.slice";

const Discount = ({
  isNotSmallerScreen,
  discountPopup,
  setDiscountPopup,
  connectUsingWalletConnect,
  setConnectPopup,
}) => {
  const currentAccount = useAppSelector(selectCurrentAccount);

  const handleConnectButton = () => {
    if (currentAccount) {
      window.location.href = "/profile";
    } else {
      if (isNotSmallerScreen) {
        setConnectPopup(true);
      } else {
        connectUsingWalletConnect();
      }
    }
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100vh"
      zIndex={10}
      gap={5}
      bg="rgba(255,255,255,0.2)"
      transition="0.2s"
      transform={discountPopup ? "scale(1)" : "scale(0)"}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width={isNotSmallerScreen ? "45%" : "80%"}
        bg="linear-gradient(to bottom,  #fff 80%, #479DD4)"
        p="10px"
        borderRadius="10px"
        _hover={{
          border: "1px solid #ddd",
        }}
      >
        <Flex width="100%" justifyContent="flex-end">
          <Flex
            bg="#424242"
            color="#fff"
            width="25px"
            height="25px"
            justifyContent="center"
            alignItems="center"
            borderRadius="50%"
            cursor="pointer"
            transition="0.3s"
            _hover={{
              transform: "scale(0.99)",
            }}
            onClick={() => setDiscountPopup(false)}
          >
            <AiOutlineClose size={15} />
          </Flex>
        </Flex>
        <Grid
          gridTemplateColumns={isNotSmallerScreen ? "1fr 1fr" : "1fr"}
          placeItems="center"
        >
          <Grid
            height={isNotSmallerScreen ? "70%" : "100%"}
            gridTemplateRows="repeat(3, 1fr)"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            color="#0c0c0c"
            textAlign="center"
            m="0px 30px"
            paddingBottom={isNotSmallerScreen ? "0px" : "20px"}
          >
            <Text
              fontSize={isNotSmallerScreen ? "24px" : "25px"}
              fontWeight="600"
            >
              Get{" "}
              <Text as="b" color="#53bafc ">
                10%
              </Text>{" "}
              Discount
            </Text>
            <Text
              fontSize={isNotSmallerScreen ? "14px" : "1xl"}
              fontWeight="500"
              margin={isNotSmallerScreen ? "0px" : "0px"}
            >
              Connect to NFT Print Pro to receive a 10% discount on your first
              print
            </Text>
            <Button
              onClick={handleConnectButton}
              size="lg"
              color="#fff"
              bg="#479DD4"
              _hover={{
                color: "#fff",
              }}
            >
              Connect Wallet
            </Button>
          </Grid>
          <Box
            display={isNotSmallerScreen ? "flex" : "none"}
            backgroundColor="#fefefe"
            padding={"20px"}
            mb={10}
          >
            <Image src="./assets/pop.jpg" alt="" />
          </Box>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default Discount;
