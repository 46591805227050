import styled from "styled-components";
import base from "../../design-system/Assets/basei.jpeg";
import testbg from "../../design-system/Assets/testbg.png";
import { device } from "../../design-system/utils/media";

export const Header = styled.div`
  width: 100%;
  height: auto;
  background-color: #0c0c0c;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  img {
    width: 100%;
    height: auto;
  }

  .basic {
    background: linear-gradient(90deg, #0c0c0c -8.15%, rgba(0, 0, 0, 0) 57.54%),
      url(${base});
    background-repeat: no-repeat;
    height: 90vh;
    background-position: center;
    background-size: cover;
  }

  .box {
    padding: 10px 72px;
    margin-top: 20%;
  }

  .elli {
    position: absolute;
    right: 60%;
    top: -50%;
  }

  .text-box {
    background: linear-gradient(
      102.95deg,
      #53bafc 6.49%,
      #fecb4c 37.6%,
      #fb4383 71.48%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  @media screen and (max-width: 960px) {
    width: 100%;

    background: linear-gradient(90deg, #0c0c0c -8.15%, rgba(0, 0, 0, 0) 57.54%),
      url(${base});
    background-repeat: no-repeat;
    height: 100vh;
    background-position: center;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;

    .box {
      padding: 10px 72px;
      margin-top: 20%;
    }

    .elli {
      position: absolute;
      right: 60%;
      top: -50%;
    }

    .text-box {
      background: linear-gradient(
        102.95deg,
        #53bafc 6.49%,
        #fecb4c 37.6%,
        #fb4383 71.48%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
`;

export const Digital = styled.div`
  @media ${device.sm} {
    padding: 5px 7px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin-top: 15%;

    img {
      width: 100%;
      height: auto;
    }
  }

  @media ${device.xs} {
    padding: 5px 7px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    margin-top: 15%;

    img {
      width: 100%;
      height: auto;
    }
  }

  @media ${device.lg} {
    padding: 10px 72px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin-top: 15%;
    height: 100vh;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

export const Testimonial = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 50px;
  background-image: url(${testbg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .test-box {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding-top: 10%;
  }

  .box {
    width: auto;
    padding: 16px;
  }

  .test-container {
    background: rgba(20, 20, 20, 0.51);
    backdrop-filter: blur(25px);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 16px;
  }

  @media ${device.lg} {
    padding-bottom: 30%;
    .box {
      width: 50%;
    }
  }
`;

export const Slider = styled.div`
  .slide {
    margin-top: 10vh;
    min-height: 50vh;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    perspective: 100px;
  }

  img {
    width: 100%;
    transition: transform 1s;

    &:hover {
      transform: translateZ(20px);
    }
  }
`;
