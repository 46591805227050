import { ethers } from "ethers";
import NFTPrintpro from "../data/NFTPrintPro.json";

const getContract = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const contract = new ethers.Contract(
    NFTPrintpro.address,
    NFTPrintpro.abi,
    signer
  );

  return contract;
};

export default getContract;
