import { Box, Grid, Image, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import { Link } from "react-router-dom";
import EmptyState from "../EmptyState";

import { Art } from "./style";

const Posts = ({ isNotSmallerScreen, posts }) => {
  const [postsToDisplay, setPostsToDisplay] = useState(posts);
  const [search, setSearch] = useState("");

  const handleInputChange = ({ target: { value } }) => {
    setSearch(value);
    const validPosts = [];
    posts.forEach((post) => {
      if (post.title.toLowerCase().includes(value)) {
        validPosts.push(post);
      }
    });
    setPostsToDisplay(validPosts);
  };

  return (
    <Art>
      <header>
        <div className="input">
          <AiOutlineSearch color="#6E7191" size={20} />
          <Input
            placeholder="Search"
            variant="filled"
            padding="25px 10px"
            outline="none"
            background="white"
            onChange={handleInputChange}
            _focus={{
              border: "2px solid red",
            }}
          />
        </div>
        <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
          <Link to="/post" style={{ textDecoration: "none" }}>
            <Text
              borderRadius={20}
              padding="10px"
              display={"flex"}
              gap={3}
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              New Post
              <BsPencil />
            </Text>
          </Link>
        </Box>
      </header>

      {postsToDisplay.length ? (
        <Grid
          templateColumns={
            isNotSmallerScreen ? "repeat(3, 1fr)" : "repeat(1, 1fr)"
          }
          gap={6}
        >
          {postsToDisplay.map((data, i) => (
            <Box
              borderRadius="10px"
              boxShadow="lg"
              bg="#fff"
              key={i}
              mt={5}
              _hover={{
                transform: "scale(0.95)",
                cursor: "pointer",
                transition: "0.5s ease-in-out",
              }}
              as={Link}
              to={`/view/${data._id}`}
              style={{ transition: "all 0.5s ease-in-out" }}
            >
              <Image src={data.image} alt={data.image} w="100%" h="300px" />
              <Box p={3}>
                <Text fontSize="18px" fontWeight="bold" color="#0c0c0c">
                  {data.title}
                </Text>
                <Text fontSize="14px" color="#0c0c0c">
                  {data.subtitle}
                </Text>
              </Box>
            </Box>
          ))}
        </Grid>
      ) : (
        <EmptyState text={`No post found for "${search}"`} />
      )}
    </Art>
  );
};

export default Posts;
