import swal from "sweetalert";

const HandleError = (err, navigate) => {
  if (err.response) {
    if (err.response.status_code === 422) {
      alert(err.response.data?.errors[0].toString());
    } else if (err.response.message === "No existing User, Join.")
      navigate("/user");
  } else if (err.request) {
    swal("Error", err.request.message, "error");
  } else if (err.message) {
    swal("Error", err.message, "error");
  }
};

export default HandleError;
