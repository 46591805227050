import styled from "styled-components";
import bg from "../../design-system/Assets/shop-bg-1.png";

export const Header = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 30vh;

  @media screen and (max-width: 960px) {
    background-image: url(${bg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 20vh;
  }
`;
