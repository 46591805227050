import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import swal from "@sweetalert/with-react";
import { default as React, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../../../api";
import { Form } from "../../../design-system/Form/style";
import getContract from "../../../helpers/contract";
import { uploadFileToIPFS, uploadJSONToIPFS } from "../../../helpers/pinata";
import { useAppSelector } from "../../../redux/hooks";
import { selectToken } from "../../../redux/slices/auth.slice";

const info = {
  "Mint Print": [
    {
      value: "Demand Determined",
      name: "Gas Fee",
    },
    {
      value: "0.001 ETH",
      name: "Service Fee",
    },
  ],
  NPC: [
    {
      value: "10 Credits",
      name: "Cost Per NPC",
    },
    {
      value: "Credit",
      name: "Available Credit",
    },
  ],
};

const MintImage = ({
  isNotSmallerScreen,
  currentAccount,
  user,
  chakraToast,
  creditBalance,
}) => {
  const file = useRef();
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  // const [metaData, setMetaData] = useState({});
  const [description, setDescription] = useState("");
  const [mintMessage, setMintMessage] = useState("");
  const [printType, setPrintType] = useState("NPC");
  const [fileURL, setFileURL] = useState("");
  const [npcCount, setNPCCount] = useState(0);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const token = useAppSelector(selectToken);

  const mintArt = async (event) => {
    event.preventDefault();
    if (creditBalance < 10) {
      return chakraToast({
        id: "01",
        position: "top",
        description: "Insufficient Credit balance",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
    if (!currentAccount)
      return chakraToast({
        id: "01",
        position: "top",
        description: "Connect Your Wallet",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    if (!name || (!preview && printType !== "NPC") || !description)
      return (
        !chakraToast.isActive("02") &&
        chakraToast({
          id: "02",
          position: "top",
          description: "Fill in the required details",
          status: "error",
          duration: 2000,
          isClosable: true,
        })
      );

    setLoading(true);
    let metaUrl;
    if (printType === "NPC") {
      metaUrl =
        "https://gateway.pinata.cloud/ipfs/QmexxKsBMDiBVWc4M5EzRK28HPScFQREMeSuXhMSst8add";
    } else {
      const response = await uploadFileToIPFS(file);
      if (response.success) {
        metaUrl = response.pinataURL;
      } else {
        swal("Please try again", response.message, "error");
      }
    }

    let payload = {
      name: name,
      description: description,
      meta: {
        tokenUri: {
          gateway: metaUrl,
        },
      },
      internal: printType === "NPC",
    };

    try {
      await API.post(
        "/mint",
        {
          ...payload,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      !chakraToast.isActive("03") &&
        chakraToast({
          id: "03",
          position: "top",
          description: `Minted ${name}`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      setLoading(false);
      setPreview(null);
      navigate("/profile");
    } catch (error) {
      setLoading(false);
      if (error.response) {
        // Response sent from the server with the error code
        //  setMessage(error.response.data.message);
        swal("Please try again", error.response.data.message, "warning");
      } else if (error.request) {
        // Error with the request
        //  setMessage(error.request.message);
        swal("Please try again", error.request.message, "error");
      } else if (error.message) {
        // Default error message assigned to the status code
        //  setMessage(error.message);
        swal("Please try again", error.message, "error");
      }
      // swal("Please try again", "Could not mint", "error");
    }
  };

  const handleImageIPFSUpload = async ({ target: { files } }) => {
    setSelectedFile(files[0]);
    const file = files[0];

    try {
      const { success, pinataURL } = await uploadFileToIPFS(file);
      if (success === true) {
        setFileURL(pinataURL);
      }
    } catch (error) {
      console.log("Error during file Upload: ", error);
    }
  };

  const uploadMetaDataToIPFS = async () => {
    const data = { name, description, image: fileURL };

    try {
      const { success, pinataURL } = await uploadJSONToIPFS(data);

      if (success) {
        return pinataURL;
      }
    } catch (error) {
      console.log("Error uploading metadata to IPFS: ", error);
    }
  };

  const mintNFT = async (event) => {
    event.preventDefault();
    try {
      const metadataURL = await uploadMetaDataToIPFS();
      const contract = getContract();
      const mintingPrice = await contract.getMintingPrice();
      setMintMessage("Please wait, uploading...");

      let mint = await contract.mintToken(metadataURL, {
        value: mintingPrice,
      });
      await mint.wait();

      setMintMessage("");
      window.location.replace("/profile");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      setPreview(URL.createObjectURL(selectedFile));
    }
  }, [selectedFile]);

  useEffect(() => {
    if (printType !== "NPC") {
      setName("");
    } else if (printType === "NPC") {
      setName(`NFTPRINTPRO NPC ${npcCount + 1}`);
    }
  }, [printType, npcCount]);

  useEffect(() => {
    async function getNPCCount() {
      const { data } = await API.get("/mint/count?internal=true");
      setNPCCount(data.data);
    }
    getNPCCount();
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: isNotSmallerScreen ? "row" : "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "40px",
        background: "#EFF1F8",
        padding: isNotSmallerScreen ? "50px 0" : "30px",
        marginBottom: "10vh",
      }}
    >
      {printType === "NPC" ? (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={isNotSmallerScreen ? "400px" : "100%"}
          height={isNotSmallerScreen ? "400px" : "100%"}
          background="#EFF1F8"
          border="1px solid #000000"
          transition="0.2s"
          margin="15px 0px"
          style={{
            aspectRatio: "2/1",
          }}
          className="upload-container"
        >
          <>
            <Box
              as="video"
              autoPlay={true}
              loop={true}
              src={"./assets/updatedNPC.mp4"}
              alt="NPC"
              objectFit="contain"
            />
          </>
        </Flex>
      ) : (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minH={"320px"}
          background="#EFF1F8"
          border="1px solid #000"
          cursor="pointer"
          width={isNotSmallerScreen ? "400px" : "100%"}
          height={isNotSmallerScreen ? "400px" : "100%"}
          transition="0.2s"
          margin="15px 0px"
          style={{
            aspectRatio: "2/1",
          }}
          className="upload-container"
          onClick={() => {
            currentAccount
              ? file.current.click()
              : !chakraToast.isActive("01") &&
                chakraToast({
                  id: "01",
                  position: "top",
                  description: "Connect Your Wallet",
                  status: "error",
                  duration: 2000,
                  isClosable: true,
                });
          }}
        >
          {!preview ? (
            <>
              <Image
                src="./assets/upload-vector.png"
                transition="0.2s"
                className="upload-vector"
              />
              <Text
                fontSize={isNotSmallerScreen ? "18px" : "14px"}
                color="#969696"
                transition="0.2s"
                className="upload-text"
              >
                Click to Upload Your Image
              </Text>
            </>
          ) : (
            <>
              <Image
                src={preview}
                height="100%"
                width="auto"
                transition="0.2s"
              />
            </>
          )}
        </Flex>
      )}
      <Form size={"auto"} onSubmit={printType === "NPC" ? mintArt : mintNFT}>
        <Input
          type="file"
          ref={file}
          accept="image/*"
          display="none"
          onChange={handleImageIPFSUpload}
        />
        <Select
          // onChange={(e) => handleCountry(e)}
          variant="flushed"
          marginTop="20px"
          marginBottom="20px"
          defaultValue={"NPC"}
          color="#0c0c0c"
          required
          onChange={({ target: { value: selectedPrint } }) =>
            setPrintType(selectedPrint)
          }
        >
          <option value={"NPC"}>NPC</option>
          <option value={"Mint Print"}>Mint Print</option>
        </Select>
        <Input
          placeholder={"Enter a name for your NFT *"}
          variant="filled"
          background="white"
          value={name}
          padding="25px 10px"
          outline="none"
          name="name"
          required
          disabled={printType === "NPC"}
          margin="10px 0px"
          onChange={({ target: { value } }) => setName(value)}
        />
        <Input
          placeholder={
            printType === "NPC" ? "Enter custom tag" : "Enter a Description"
          }
          variant="filled"
          padding="25px 10px"
          outline="none"
          background="white"
          margin="10px 0px"
          name="description"
          value={description}
          required
          type={"textarea"}
          onChange={(e) => setDescription(e.target.value)}
        />
        {info[printType].map((detail, index) => (
          <Text key={index}>
            {detail.name}: {detail.name === "Available Credit" && creditBalance}{" "}
            {detail.value}
          </Text>
        ))}

        <Grid placeItems="center">
          {mintMessage && <Text>{mintMessage}</Text>}
          <Button
            colorScheme="linkedin"
            margin="25px 0px"
            padding="25px 40px"
            isLoading={loading}
            disabled={printType !== "NPC" && !fileURL}
            width="100%"
            type="submit"
          >
            Mint
          </Button>
        </Grid>
      </Form>
    </Box>
  );
};

export default MintImage;
